import React from "react";
import { connect } from 'react-redux';
import { Line } from "react-chartjs-2";
import moment from 'moment';
import _ from 'lodash';
import { colours } from '../../options.js';
import tinycolor from 'tinycolor2';
import { round } from '../../../config/sparkLineChartOptions.js';

import {
  Row,
  Col,
  Badge
} from "reactstrap";

export const sparklineChartOpts = {
  responsive: true,
  maintainAspectRatio: true,

  scales: {
    xAxes: [{
      type: 'linear',
      position: 'bottom',
      scaleLabel: {
        display: true,
        labelString: 'Days'
      }
    }],
    yAxes: [{
      display: true,
      scaleLabel: {
        display: true,
        labelString: 'Speed [kn]'
      }
    }]
  },
  tooltips: {
    intersect: false,
    callbacks: {

      label: ({ yLabel }) => {
        return round(yLabel, 1) + " kn";
      },

      title: (tooltipItems, { datasets }) => {
        const { datasetIndex, index } = _.first(tooltipItems);
        const data = datasets[datasetIndex].data[index].data;
        return moment(data.timestamp).format('DD MMM YYYY HH:mm');
      }
    }
  },

  elements: {
    line: {
      borderWidth: 2
    },
    point: {
      radius: 0,
      hitRadius: 10,
      hoverRadius: 4,
      hoverBorderWidth: 3
    }
  },
  legend: {
    display: false
  }
};

const TotalDistanceLine = ({ data, trend }) => (
  <div>

    <Row className="trendKey">
      {trend.map((t, i) => (
        <Col className="consumption" xs="12" lg="6" xl="4" key={i}>
          <span className="ref" style={{ backgroundColor: t.colour }}></span>
          <span>{t.name}</span> <br />
          <small>Total distance: {round(t.data.reduce((s, v) => s + v.distanceSailed, 0), 1) || "-"} [nmi]<br />
            Average: {round(_.mean(t.data.map(d => d.speed), 1)) || "-"} [kn]</small>
        </Col>
      ))}
    </Row>
    <Line data={data} options={sparklineChartOpts} width={100} height={30} />
  </div>
);

export default connect(() => ({ vessels }) => {
  return {
    trend: vessels.trend,
    data: () => ({
      datasets: vessels.trend.map(t => ({
        label: t.name,
        fill: false,
        data: t.data.map(d => ({ x: moment.duration(d.x).asDays(), y: d.speed, data: d })),
        borderColor: tinycolor(t.colour).lighten(10).toString(),
        backgroundColor: t.colour,
      }))
    })
  };
})(TotalDistanceLine);
