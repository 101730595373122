import React, {Component} from "react";
import Parse from "parse";
import { connect } from 'react-redux';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button, Form, FormGroup, Label, Input, FormText, Col, Row } from 'reactstrap';

import { toggleForm, editPOI, update, startRemove, startSave } from '../../../actions/POI.js';

const POIAdmin = ({showForm, point, position,  dispatch}) => (
  <div>
    <Modal isOpen={showForm} toggle={() => dispatch(toggleForm(false))}>
      <Form className='POI' onSubmit={e => { e.preventDefault(); dispatch(startSave(point)) }}>
        <ModalHeader toggle={() => dispatch(toggleForm(false))}>Add a point of interest to the map.</ModalHeader>
        <ModalBody >
          <Row>
            <Col xs='12' sm='6'>
              <FormGroup>
                <Label for='Latitude'>Latitude</Label>
                <Input
                  type='number'
                  name='latitude'
                  id='Latitude'
                  placeholder='Latitude (eg. 10.355)'
                  min={-90}
                  max={90}
                  onChange={e => dispatch(update('position', new Parse.GeoPoint([document.getElementById('Latitude').value, document.getElementById('Longitude').value])))}
                  value={ position.latitude || '' } />
              </FormGroup>
              <FormGroup>
                <Label for='Longitude'>Longitude</Label>
                <Input
                  type='number'
                  name='longitude'
                  id='Longitude'
                  placeholder='Longitude (eg. 44.430)'
                  value={ position.longitude || '' }
                  min={-180}
                  max={180}
                  onChange={e => dispatch(update('position',  new Parse.GeoPoint([document.getElementById('Latitude').value, document.getElementById('Longitude').value])))}
                />
              </FormGroup>
              <FormGroup>
                <Label for='Label'>Label</Label>
                <Input
                  type='text'
                  name='label'
                  id='Label'
                  placeholder='Oil platform' value={point.get('label') || ''}
                  onChange={e => dispatch(update('label', e.target.value))} />
              </FormGroup>
            </Col>
            <Col xs='12' sm='6'>
              <FormGroup tag='fieldset'>
                <Label>Type</Label>
                <FormGroup check>
                  <Label check>
                    <Input type='radio' name='type'
                      checked={'map-pin' === point.get('markerType')}
                      onChange={e => dispatch(update('markerType','map-pin')) }  />
                    <span>
                      <i className='fa fa-map-pin fa-sm' aria-hidden='true'></i>
                      Platform
                    </span>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input type='radio' name='type'
                      checked={'ship' === point.get('markerType')}
                      onChange={() => dispatch(update('markerType', 'ship')) } />
                    <span>
                      <i className='fa fa-ship fa-sm' aria-hidden='true'></i>
                      Harbour
                    </span>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input type='radio' name='type'
                      checked={'map-marker' === point.get('markerType')}
                      onChange={() => dispatch(update('markerType', 'map-marker'))} />
                    <span>
                      <i className='fa fa-map-marker fa-sm' aria-hidden='true'></i>
                      POI
                    </span>
                  </Label>
                </FormGroup>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>

        {( position.longitude
         && position.latitude
         && point.get('label')
         && point.get('markerType'))
         ?
         <ModalFooter>
           <Button color='secondary' onClick={e => { e.preventDefault(); dispatch(toggleForm(false)) }}>Cancel</Button>
           {point.isNew() || (<Button color='danger' onClick={e => { e.preventDefault(); dispatch(startRemove(point)) }}>Remove</Button>)}
           <Button  color='primary' onClick={e => { e.preventDefault(); dispatch(startSave(point)) }} >Save</Button>
         </ModalFooter>
         :
         <ModalFooter>
           <Button color='secondary' onClick={e => { e.preventDefault(); dispatch(toggleForm(false)) }}>Cancel</Button>
         </ModalFooter>
        }
      </Form>
    </Modal>
    <a className='addPOI' onClick={() => dispatch(editPOI())}>+ <i className='fa fa-map-marker fa-sm' aria-hidden='true'></i></a>
  </div>

);


export default connect((state) => {

  return ({ showForm: state.POI.showForm,
            point: state.POI.point,
            position: state.POI.point.get('position') || {} });
})(POIAdmin);
