// jshint esversion:6

import Parse from '../parse';
import _ from 'lodash';

const FuelType = Parse.Object.extend("FuelType");
const fuelTypesCache = {};

export const loading = () => {
  return {
    type: 'FUELTYPES_LOADING'
  };
};

export const loaded = (fuelTypes) => {
  return {
    type: 'FUELTYPES_LOADED',
    fuelTypes
  };
};

const makeFuelTypesQuery = (voyage, pkIds) => {
  const query = new Parse.Query(FuelType);
  query.equalTo('vessel', voyage.get('vessel'));
  query.containedIn('pkId', pkIds);
  return query;
};

export const loadFuelTypes = (voyage) => (dispatch) => {
  const pkIds = (voyage.get('consumptionAtSea') || [])
    .map(c => String(c.fuelTypeId))
    .concat((voyage.get('consumptionAtArrivalPort') || []).map(c => String(c.fuelTypeId)));

  const key = _.join(pkIds, "_") + "_" + voyage.id;
  if (fuelTypesCache[key]) dispatch(loaded(fuelTypesCache[key]));
  else makeFuelTypesQuery(voyage, pkIds)
    .find()
    .then(fuelTypes => {
      Parse.Object.fetchAllIfNeeded(fuelTypes).then(fuelTypes => {
        fuelTypesCache[key] = fuelTypes.reduce((acc, ft) => {
          acc[ft.get('pkId')] = ft;
          return acc;
        }, {});
        return dispatch(loaded(fuelTypesCache[key]));
      });
    });
}

export const findFuelTypes = (voyage) => {
  const pkIds = (voyage.get('consumptionAtSea') || [])
    .map(c => String(c.fuelTypeId))
    .concat((voyage.get('consumptionAtArrivalPort') || []).map(c => String(c.fuelTypeId)));

  const key = _.join(pkIds, "_") + "_" + voyage.id;
  return new Promise(function(resolve, reject) {
    if (fuelTypesCache[key]) resolve(fuelTypesCache[key]);
    else makeFuelTypesQuery(voyage, pkIds)
      .find()
      .then(fuelTypes => {
        Parse.Object.fetchAllIfNeeded(fuelTypes).then(fuelTypes => {
          fuelTypesCache[key] = fuelTypes.reduce((acc, ft) => {
            acc[ft.get('pkId')] = ft;
            return acc;
          }, {});
          resolve(fuelTypesCache[key]);
        });
      });
  })
}