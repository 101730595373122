import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  CardGroup,
  Card,
  CardBody,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  Alert,
  Form,
} from "reactstrap";
import { resetPassword } from "../../../actions/account";

const getErrorMessage = (error) => {
  return !error ? null : <Alert color="danger">{error.message}</Alert>;
};

const Login = ({ accounts, dispatch }) => (
  <Form
    onSubmit={(e) => {
      e.preventDefault();
      dispatch(resetPassword(document.getElementById("UserEmail").value));
    }}
    className="app flex-row align-items-center"
  >
    <div className="app flex-row align-items-center login-background">
      <Container>
        <Row className="justify-content-center">
          <Col md="6">
            <CardGroup className="mb-0">
              <Card className="p-4">
                <CardBody className="card-body">
                  <h1>Lost password.</h1>
                  <p className="text-muted">
                    Please email{" "}
                    <strong>
                      <a href="mailto:marine-support@krohne.com">support</a>
                    </strong>
                    , and a member of our team will be in contact.
                  </p>
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  </Form>
);

export default connect((state) => ({ accounts: state.accounts }))(Login);
