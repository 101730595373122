// jshint esversion:6
import _ from 'lodash';

export const SORT_ASC = 'asc';
export const SORT_DESC = 'desc';
export const DEFAULT_SORT_KEY = 'startTime';

export const STARTTIME_KEY = 'startTime';
export const MASSTOTAL_KEY = 'massTotal';
export const MASSTOTALBDN_KEY = 'massTotalBdn';
export const AVGMASSFLOW_KEY = 'avgMassFlow';
export const AERATION_KEY = 'aeration';

const defaultState = { bunkerItems: [], page: 0, limit: 6, count: 0, sortKey: DEFAULT_SORT_KEY, sortOrder: SORT_DESC, keyword: '' };

export default (state = defaultState, action = {}) => {
    switch (action.type) {
        case 'ACCOUNT_LOGOUT':
            return { ...defaultState };
        case 'ALLBUNKERITEMS_LOADING':
            return { ...state, loading: true };
        case 'ALLBUNKERITEMS_LOADED':
            return { ...state, loading: false, bunkerItems: action.bunkerItems };
        case 'ALLBUNKERITEMS_PAGE':
            return { ...state, page: action.page };
        case 'ALLBUNKERITEMS_COUNT':
            return { ...state, count: action.count };
        case 'ALLBUNKERITEMS_SEARCH':
            return { ...state, keyword: action.keyword };

        case 'ALLBUNKERITEMS_SORT':
            let sortKey = action.sortKey || DEFAULT_SORT_KEY;
            if (sortKey === state.sortKey)
                (state.sortOrder === SORT_DESC) ?
                    state.sortOrder = SORT_ASC :
                    state.sortOrder = SORT_DESC;
            return { ...state, sortKey: sortKey, sortOrder: state.sortOrder };
        default: return state;
    }
};