import React from "react";
import { connect } from 'react-redux';
import { CardDeck, CardBody, CardHeader, Card, Row, Col, Container, UncontrolledTooltip } from "reactstrap";
import { Link } from 'react-router-dom';

import Consumption from './Consumption.js';
import Performance from './Performance.js';
import Distance from './Distance.js';
import VesselTable from './VesselTable.js';
import BunkerTable from './BunkerTable';
import FuelConsumption from './FuelConsumption.js';
import VoyageDetails from '../../Components/VoyageDetails/VoyageDetails.js';
import Logs from '../../Components/Logs/Logs.js'

import SetDays from '../../Components/SetDays/SetDays.js';
import MapCard from '../../Components/Map/MapCard.js';
import POI from '../../Components/Map/POI.js';


import { setToMonth, setToWeek, setToDay, setRange, showRange } from '../../../actions/fleet.js';


const Dashboard = ({ span, to, from, showTheRange, history, bounds }) => (
    <Container fluid>
        <div className="dashboard page">
            <Row>
                <Col>
                    <SetDays
                        to={to}
                        from={from}
                        span={span}
                        showTheRange={showTheRange}
                        showRange={showRange}
                        setRange={setRange}
                        setDateMonth={setToMonth}
                        setDateWeek={setToWeek}
                        setDateDay={setToDay}></SetDays>
                </Col>
            </Row>
            <Row>
                <Col>
                    <CardDeck>
                        <Card>
                            <POI></POI>
                            <MapCard history={history} bounds={bounds} ></MapCard>
                            <VoyageDetails></VoyageDetails>
                        </Card>
                        <FuelConsumption></FuelConsumption>
                    </CardDeck>
                </Col>
            </Row>
            <Row>
                <Col xs="12" sm="4">
                    <Consumption />
                </Col>
                <Col xs="12" sm="4">
                    <Performance />
                </Col>
                <Col xs="12" sm="4">
                    <Distance />
                </Col>

            </Row>
            <VesselTable></VesselTable>
            <Row>
                <Col xs="12">
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col xs="12">
                                    Bunkerings
                                    <UncontrolledTooltip delay={{show:500, hide:250}} target="SearchLink">
                                    Go to bunker search page
                                    </UncontrolledTooltip>
                                    <Link to="/bunkersearch" id="SearchLink" className="padleft">
                                        <i className="fa fa-search" />
                                    </Link>
                                </Col>
                            </Row>
                        </CardHeader>
                        <BunkerTable />
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <Card>
                        <CardHeader>
                            Warnings log
                        </CardHeader>
                        <CardBody className="card-body dashLogs">
                            <Logs link={true}></Logs>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    </Container>
)

export default connect((state) => ({
    span: state.fleet.span,
    to: state.fleet.to,
    from: state.fleet.from,
    bounds: state.fleet.bounds,
    history: state.fleet.history,
    showTheRange: state.fleet.selectRange
}))(Dashboard);
