import React from "react";
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { loadPage } from '../../../actions/bunkerItems.js';
import { setRange } from '../../../actions/vessels.js';
import { vesselsURL } from '../../../actions/navigation.js';

import largePagination from './LargePagination.js';
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Table,
  UncontrolledTooltip
} from "reactstrap";

const BunkeringsCard = ({ bunkerItems, dispatch, count, limit, page, path }) => (
    <div>
        <Table responsive striped className="noTopLine">
            <thead>
                <tr>
                    <th>Vessel</th>
                    <th>Port</th>
                    <th>Dates</th>
                    <th>Fuel Type</th>
                    <th>Total Mass [MT]</th>
                </tr>
            </thead>
            <tbody className="clickAble">
                {bunkerItems.bunkerItems.map((bunker) => (<tr key={bunker.id} onClick={() => dispatch(vesselsURL(path, bunker.get('vessel'), 'bunker/' + bunker.get('pkId')))} >
                    <UncontrolledTooltip delay={{ show: 500, hide: 250 }} target={"BunkerDate" + bunker.id} placement="right" >Sets the time range to match this bunkering</UncontrolledTooltip>

                    <td><span className="ref" style={{ backgroundColor: bunker.get('vessel').get('colour') }}></span> {bunker.get('vessel').get('name')}</td>
                    <td>{bunker.get('port')}</td>
                    <td ><Button id={"BunkerDate" + bunker.id} outline color="primary" style={{ borderColor: bunker.get('vessel').get('colour') }} onClick={e => {
                            e.stopPropagation();
                            dispatch(setRange(moment(bunker.get('startTime')), moment(bunker.get('endTime')), bunker.get('vessel')));
                    }} >{moment(bunker.get('startTime')).format('DD MMM HH:mm ')}-
                        {moment(bunker.get('endTime')).format(' HH:mm')}</Button></td>
                    <td>{bunker.get('fuelType').get('name')}</td>
                    <td>{_.round(bunker.get('massTotal') / 1000, 2)}</td>
                </tr>))}
            </tbody>
        </Table>
        <div className="row justify-content-center">
            {largePagination(dispatch, count, limit, page, loadPage)}
        </div>
    </div>
);

export default connect(() => ({ bunkerItems, routing }) => ({
    bunkerItems: bunkerItems,
    page: bunkerItems.page,
    limit: bunkerItems.limit,
    count: bunkerItems.count,
    path: routing.location.pathname,
}))(BunkeringsCard);
