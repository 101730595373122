import _ from 'lodash';

const past = [];
let pastOptions;

const defaultState = {
    showForm: false,
    vesselForm: false,
    vessel: false,
    vesselOption: {},
    loading: true,
    groups: [],
    groupItems: {},
    items: [],
    deletedItems: [],
    editGroup: false,
    updated: false,
    saving: false,
    vesselSaving: false,
    isValid: true,
    deleting: false,
    askToDelete:false,
    groupedVessels: []};


const updateGroup = (state, action) => {
    return state.groups.map(group => {
        if(group._getId() === action.group._getId()) {
            const newGroup = _.clone(action.group);
            newGroup.set(action.key, action.value);
            return newGroup;
        }
        return group;
    });
};

const updateGroupItem = (state, action) => {
    const groupItems = {...state.groupItems };
    groupItems[state.editGroup._getId()] = groupItems[state.editGroup._getId()].map(item => {
        if(item._getId() === action.item._getId()) {
            const newItem = _.clone(action.item);
            newItem.set(action.key, action.value);
            return newItem;
        }
        return item;
    });
    return groupItems;
};

const sort = (a, b) => {
    if (a.get('name').toLowerCase() > b.get('name').toLowerCase()) return 1;
    if (a.get('name').toLowerCase() < b.get('name').toLowerCase()) return -1;
    return 0;
};

const groupedVessels = (groups, groupItems, vessels) => {
    const keyGroups = _.keyBy(groups, g => g._getId());
    return _.keys(groupItems).map(key => ({
        group : keyGroups[key],
        items : groupItems[key].sort(sort).map( item => ({
            item,
            vessels: vessels.filter(v => ( _.indexOf((v.get('groupItems')||[])
                                                     .map( i => i._getId()),item._getId()) > -1))
                .sort(sort)
        }))
    }));
};

const indexItems = (items) => {
    return cleanItems(items).reduce((all,item) => (all[item.get('group')._getId()] = item, all), {});
};

const cleanItems = (items) => _.compact(items).filter(item => item.get('group'));

const updateVesselOptions = (state, action) => {
    const vessel = _.clone(state.vessel);
    const items = cleanItems((vessel.get('groupItems') || [])).filter(item => item.get('group')._getId() !== action.groupId);
    const item = _.find(state.groupItems[action.groupId], item => item._getId() === action.itemId);
    if (item) items.push(item);

    vessel.set('groupItems', items);
    return {...state, vessel, vesselOption: indexItems(items)};
};

const addGroupItem = (state, action) => {
    const groupItems = {...state.groupItems };
    groupItems[state.editGroup._getId()] = groupItems[state.editGroup._getId()] ? [...groupItems[state.editGroup._getId()], action.item] : [action.item];
    return groupItems;
};

const removeGroupItem = (state, action) => {
    const groupItems = {...state.groupItems };
    groupItems[state.editGroup._getId()] = _.without(groupItems[state.editGroup._getId()], action.item);
    return groupItems;
}

const removeGroupsItems = (state) => {
    const groupItems = {...state.groupItems };
    delete groupItems[state.editGroup._getId()];
    return groupItems;
}

const pushState = (state) => (past.push(state),state);
const popState = () => {
    const state = past.pop();
    _.each(state.groups, g => g.revert());
    _.each(state.items, vo => vo.revert());
    return state;
}

const setPastVesselOptions = (vessel) => {
    pastOptions = vessel.get('groupItems');
    return vessel;
};

const revertVesselOptions = (vessel) => {
    vessel.set('groupItems', pastOptions);
    return vessel;
}

const toggleGroupState = (state, action) => {
    if (action.showForm) return {...pushState(state), showForm: action.showForm};
    else if (!state.saving) return {...popState(), showForm: action.showForm};
    return state;
}

const isValid = (state) => {
    return {...state, isValid: state.groups.reduce((valid, group) => valid && Boolean(group.get("name")), true)
            && state.items.reduce((valid, item) => valid && Boolean(item.get("name")), true)};
}

export default (state = defaultState, action = {}) => {
    switch (action.type) {
    case 'ACCOUNT_LOGOUT':
        return {...defaultState };
    case 'NEW_GROUP':
        return {...state, isValid: false, updated: true, groups: [...state.groups, action.group], editGroup: action.group};
    case 'NEW_GROUP_ITEM':
        return {...state, updated: true, isValid: false, groupItems: addGroupItem(state, action)};
    case 'ASK_TO_DELETE_GROUP':
        return { ...state, askToDelete: action.askToDelete };
    case 'DELETE_GROUP_ITEM':
        return isValid({...state, updated: true, groupItems: removeGroupItem(state, action), deletedItems: [...state.deletedItems , action.item]});
    case 'DELETE_GROUP':
        return isValid(pushState({...state, deleting: false,  groupItems:removeGroupsItems(state),
                                  editGroup: false, groups:state.groups.filter(group => group._getId() !== state.editGroup._getId())}));
    case 'DELETING_GROUP':
        return {...state, deleting: true};
    case 'ALL_SAVING':
        return {...state, saving: true};
    case 'ALL_SAVED':
        return {...state, editGroup: false, showForm: false, updated: false, isValid: true, saving: false,
                groupedVessels: groupedVessels(state.groups, state.groupItems, state.vessels)};
    case 'GROUP_SAVING_VESSEL':
        return {...state, vesselSaving: true };

    case 'GROUP_SAVED_VESSEL':
        return {...state, vesselForm: false, vessel: false, vesselSaving: false,
                groupedVessels: groupedVessels(state.groups, state.groupItems, state.vessels) };
    case 'UPDATE_GROUP_ITEM':
        return  isValid({...state, updated: true, groupItems: updateGroupItem(state, action)});
    case 'EDIT_GROUP':
        return {...state, editGroup: action.group};
    case 'GROUP_SHOW_FORM':
        return toggleGroupState(state, action);
    case 'GROUP_SHOW_VESSEL_FORM':
        return { ...state, vesselForm: action.showForm, vessel: action.showForm ? state.vessel : revertVesselOptions(state.vessel) };
    case 'GROUP_EDIT_VESSEL':
        return { ...state,
                 vessel: setPastVesselOptions(action.vessel),
                 vesselOption: indexItems(action.vessel.get('groupItems') || []),
                 vesselForm: true };
    case 'GROUP_EDIT_VESSEL_OPTION':
        return isValid(updateVesselOptions(state, action));

    case 'LOADING':
        return {...state, loading: true };
    case 'UPDATE_GROUP':
        return isValid({ ...state, updated: true, groups: updateGroup(state, action)});
    case 'VESSELS_LOADED':
        return { ...state, vessels: action.vessels.sort(sort),
                 groupedVessels: groupedVessels(state.groups, state.groupItems, action.vessels.sort(sort)) };
    case 'ALL_GROUPS_LOADED':
        return { ...state, groups: action.groups.sort(sort),
                 groupedVessels: groupedVessels(action.groups.sort(sort), state.groupItems, state.vessels),
                 editGroup: false };
    case 'ALL_GROUP_ITEMS_LOADED':
        const groupItems =  _.groupBy(action.items.sort(sort), item => item.get('group')._getId());
        return { ...state, loading: false,
                 groupedVessels: groupedVessels(state.groups, groupItems, state.vessels),
                 items: action.items.sort(sort),
                 groupItems: groupItems };
    default:
        return state;
    }
};
