import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';

import { showWarning, deleteUser } from '../../../actions/account';

const toggle = (dispatch) => {
  dispatch(showWarning(false));
}

const DeleteModal = ({open, username, dispatch}) => (
  <div>
    <Modal isOpen={open} toggle={() => toggle(dispatch)} >
      <ModalHeader toggle={() => toggle(dispatch)}>Delete the <strong>{username}</strong> account.</ModalHeader>
      <ModalBody>
        Are you sure you want to delete the <strong>{username}</strong> account?
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={() => {toggle(dispatch); dispatch(deleteUser())}}>Delete <strong>{username}</strong></Button>{' '}
        <Button color="secondary" onClick={() => toggle(dispatch)}>Cancel</Button>
      </ModalFooter>
    </Modal>
  </div>
);


export default connect((state) => ({ open: state.accounts.askToDelete, username: state.accounts.editing.get('username') || ''}))(DeleteModal);
