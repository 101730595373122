
import React, { Component } from 'react';
import { Form, FormGroup, Row, Col, Button, Input, Label, InputGroup, InputGroupAddon, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import { connect } from 'react-redux';
import Spinner from 'react-spinkit';
import { saveUser, showWarning } from '../../../actions/account';

const Warning = (open, click) => (
<div>
    <Modal isOpen={open} toggle={() => toggle(dispatch)} >
      <ModalHeader toggle={() => toggle(dispatch)}>Error saving</ModalHeader>
      <ModalBody>
        Password must be atleast 6 characters long.
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={() => click()}>Ok</Button>
      </ModalFooter>
    </Modal>
  </div>    
);

class ProfileComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: this.props.username,
            email: this.props.email,
            password: undefined,
            isUserAdmin: this.props.isUserAdmin,
            warning: false
        };
    }
    save() {
        if (this.state.password && this.state.password.length < 6) {
            this.setState({warning: true});
        }
        else {
            this.props.dispatch(saveUser(this.state.username, this.state.email, this.state.password, this.state.isUserAdmin));
        }
    }
    render() {
        return (
            <Form onSubmit={e => {
                    e.preventDefault();
                    this.save();
            }}>
            {Warning(this.state.warning, () => this.setState({warning:false}))}
                <InputGroup className="mb-3">
                    <InputGroupAddon  addonType="prepend">
                        <i className="icon-user input-group-text"></i></InputGroupAddon>
                    <Input type="text" onChange={e=> this.setState({username: e.target.value})} placeholder="Username" value={this.state.username} />
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroupAddon addonType="prepend">@</InputGroupAddon>
                    <Input type="text" onChange={e=> this.setState({email: e.target.value})}
                        placeholder="Email" value={this.state.email} />
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroupAddon addonType="prepend"><i className="icon-lock input-group-text"></i></InputGroupAddon>
                    <Input autoComplete="off" className="password" type="password" placeholder="Password" onChange={e=> this.setState({password: e.target.value})} />
                </InputGroup>
                <Row>
                    <Col md="6">
                        <FormGroup check>
                            <Label check>
                                <Input type="checkbox" id="AdminCheckbox" checked={this.state.isUserAdmin} onChange={e=> this.setState({isUserAdmin: e.target.checked})}  />{' '}
                                Admin?
                            </Label>
                        </FormGroup>
                    </Col>
                </Row>
        
                <Row>
                    <Col md="6">
                        <Button className="secondary-btn" onClick={ e => { this.props.dispatch(showWarning(true)); e.preventDefault() }} >Delete user</Button>
                    </Col>
                    <Col md="6">
        
                        <Button color="primary" className="btn btn-primary f-right" disabled={this.props.saving}>
                            {this.props.saving ? (<Spinner className="ref" color="#FFFFFF" name='chasing-dots' />)
                             : "Save changes"}
                        </Button>
                    </Col>
                </Row>
            </Form>
        )
    }
}

export default connect((state) => ({ saving: state.accounts.saving,
                                     isUserAdmin: Boolean(state.accounts.editing.get('isUserAdmin')),
                                     email: state.accounts.editing.get('email') || '',
                                     username: state.accounts.editing.get('username') || '' }))(ProfileComponent);
