// jshint esversion:6

import Parse from "../parse";
import { push } from "react-router-redux";
import { addErrorMessage } from "./error.js";
import { loadAllVesselGroups } from "./vesselGroups.js";
import { loadAllVessels } from "./vessels.js";

export const loggedOut = () => {
  return {
    type: "ACCOUNT_LOGOUT",
  };
};

export const logout = () => {
  return {
    type: "ACCOUNT_LOGOUT",
  };
};

export const authenticating = () => {
  return {
    type: "ACCOUNT_AUTHENTICATING",
  };
};

export const loggingInError = (e) => {
  return {
    type: "ACCOUNT_LOGGING_IN_ERROR",
    e,
  };
};

export const error = (e) => {
  return {
    type: "ACCOUNT_ERROR",
    e,
  };
};

export const login = (parseUser) => {
  return {
    type: "ACCOUNT_LOGIN",
    parseUser,
  };
};

export const updateDestination = (destination) => {
  return {
    type: "ACCOUNT_DESTINATION",
    destination,
  };
};

export const admin = (userIsAdmin) => {
  return {
    type: "ACCOUNT_ADMIN",
    userIsAdmin,
  };
};

export const saving = () => {
  return {
    type: "ACCOUNT_SAVING",
  };
};

export const saved = () => {
  return {
    type: "ACCOUNT_SAVED",
  };
};

export const updated = (user) => {
  return {
    type: "ACCOUNT_UPDATED",
    user,
  };
};

export const loadUsers = (users) => {
  return {
    type: "ACCOUNTS_LOADED",
    users,
  };
};

export const updatingUser = (user) => {
  return {
    type: "ACCOUNT_EDITING",
    user,
  };
};

export const isAdminUser = (isAdminUser) => {
  return {
    type: "ACCOUNT_IS_ADMIN",
    isAdminUser,
  };
};

export const loadUserVessels = (vessels) => {
  return {
    type: "ACCOUNT_VESSELS",
    vessels,
  };
};

export const removeUserVessel = (vessel) => {
  return {
    type: "REMOVE_ACCOUNT_VESSEL",
    vessel,
  };
};

export const addUserVessel = (vessel) => {
  return {
    type: "ADD_ACCOUNT_VESSEL",
    vessel,
  };
};

export const removeUser = (user) => {
  return {
    type: "REMOVE_ACCOUNT",
    user,
  };
};

export const addUser = () => {
  return {
    type: "ADD_ACCOUNT",
  };
};

export const showWarning = (askToDelete) => {
  return {
    type: "ASK_TO_DELETE_ACCOUNT",
    askToDelete,
  };
};

export const editUser = (user) => (dispatch) => {
  user
    .relation("vessels")
    .query()
    .find()
    .then((vessels) => {
      dispatch(loadUserVessels(vessels));
    });

  Parse.Cloud.run("isUserAdmin", user.toJSON())
    .then((isAdmin) => {
      user.set("isUserAdmin", isAdmin);
      dispatch(updatingUser(user));
    })
    .catch((e) => {
      dispatch(
        addErrorMessage({
          txt:
            "There was an error determining if " +
            user.get("name") +
            " is an administrator",
          colour: "danger",
        })
      );
      dispatch(addErrorMessage({ txt: e.message, colour: "info" }));
    });
};

export const saveUser =
  (username, email, password, isUserAdmin) => (dispatch, getState) => {
    var { accounts } = getState();
    dispatch(saving());
    var editedUser = { ...accounts.editing.toJSON(), isUserAdmin: isUserAdmin };
    if (username) {
      editedUser = { ...editedUser, username: username };
    }
    if (email) {
      editedUser = { ...editedUser, email: email };
    }
    if (password) {
      editedUser = { ...editedUser, password: password };
    }
    Parse.Cloud.run("saveUser", editedUser)
      .then((user) => {
        dispatch(editUser(user));
      })
      .catch((e) => {
        dispatch(
          addErrorMessage({
            txt: "There was an error saving the user data",
            colour: "danger",
          })
        );
        dispatch(addErrorMessage({ txt: e.message, colour: "info" }));
      })
      .then(() => {
        dispatch(saved());
      });
  };

export const deleteUser = () => (dispatch, getState) => {
  var { accounts } = getState();
  Parse.Cloud.run("removeUser", accounts.editing.toJSON()).then(() => {
    dispatch(removeUser(accounts.editing));
  });
};

export const saveProfile = () => (dispatch, getState) => {
  var { accounts } = getState();
  dispatch(saving());
  accounts.user.save().then((user) => dispatch(saved(user)));
};

export const makeRequest = () => (dispatch, getState) => {
  const RequestAccess = Parse.Object.extend("RequestAccess");
  var {
    accounts: { user },
  } = getState();
  const name = user.get("RQName");
  const email = user.get("RQAddress");
  const phone = user.get("RQPhone");
  const company = user.get("company").get("name");

  if (name && email && phone) {
    dispatch(saving());
    const requestAccess = new RequestAccess({
      name,
      email,
      phone,
      company,
    });
    requestAccess.save().then(() => {
      user.set("RQName", "");
      user.set("RQAddress", "");
      user.set("RQPhone", "");

      dispatch(saved());
      dispatch(push("/access_requested"));
    });
  }
};

export const updateProfile = (key, value) => (dispatch, getState) => {
  var { accounts } = getState();
  accounts.user.set(key, value);
  dispatch(updated(accounts.user));
};

export const addVessel = (vessel) => (dispatch, getState) => {
  var { accounts } = getState();
  const relation = accounts.editing.relation("vessels");
  relation.add(vessel);
  dispatch(addUserVessel(vessel));
};

export const removeVessel = (vessel) => (dispatch, getState) => {
  var { accounts } = getState();
  const relation = accounts.editing.relation("vessels");
  relation.remove(vessel);
  dispatch(removeUserVessel(vessel));
};

export const startLogin = (username, password) => (dispatch, getState) => {
  dispatch(authenticating());
  var { accounts } = getState();
  const dest = accounts.destination;
  Parse.User.logIn(username, password)
    .then((user) => {
      dispatch(isAdmin());
      dispatch(login(user));
      dispatch(push(dest));
    })
    .catch((e) => dispatch(loggingInError(e)));
};

export const resetPassword = (email) => (dispatch, getState) => {
  dispatch(authenticating());

  Parse.User.requestPasswordReset("m.michelli@krohne.com")
    .then(() => {
      alert("ok");
    })
    .catch((error) => {
      // Show the error message somewhere
      alert("Error: " + error.code + " " + error.message);
    });
};

export const startLogout = () => (dispatch) => {
  dispatch(logout());
  Parse.User.logOut()
    .then(() => {
      dispatch(push("/login"));
    })
    .catch((e) => {
      dispatch(
        addErrorMessage({
          txt: "There was an error logging out.",
          colour: "danger",
        })
      );
      dispatch(loggingInError(e));
      location.reload();
    });
};

export const isloggedIn = () => (dispatch) => {
  const user = Parse.User.current();
  if (user) {
    user
      .get("company")
      .fetch()
      .then(() => {
        dispatch(login(user));
        dispatch(isAdmin());
      });
  } else {
    if (
      !(
        window.location.pathname.includes("login") ||
        window.location.pathname.includes("forgot_password")
      )
    ) {
      dispatch(logout());
      dispatch(updateDestination(window.location.pathname));
      dispatch(push("/login"));
      debugger;
    }
  }
};

export const isAdmin = () => (dispatch) => {
  Parse.Cloud.run("isadmin")
    .then((isAdmin) => {
      dispatch(admin(isAdmin));
      dispatch(loadAllVesselGroups());
      if (isAdmin) {
        dispatch(loadAllUsers());
        dispatch(loadAllVessels());
      }
    })
    .catch(() => {
      dispatch(
        addErrorMessage({
          txt: "There was an error determining if you are an administrator.",
          colour: "danger",
        })
      );
    });
};

export const loadAllUsers = () => (dispatch, getState) => {
  var { accounts } = getState();
  var company = accounts.user.get("company");
  var userQuery = new Parse.Query(Parse.User);
  userQuery.equalTo("company", company);
  userQuery
    .find()
    .then((users) => Parse.Object.fetchAllIfNeeded(users))
    .then((users) => dispatch(loadUsers(users)));
};
