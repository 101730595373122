import React from 'react';
import {Row, Button,  ListGroup, ListGroupItem} from "reactstrap";
import { connect } from 'react-redux';

import { removeVessel } from '../../../actions/account.js';


const ListVessels = ({vessels, dispatch}) => (
    <ListGroup className="mb-3 scroll" >
        {(vessels || []).map(vessel =>
            (
                <ListGroupItem key={vessel.id} key={vessel.id}>{vessel.get('name')}
                    <Button block className="col-md-1 col-sm-1 col-1 remove-btn secondary-btn f-right" >
                        <i onClick={ () => dispatch(removeVessel(vessel))} className="icon-close"></i></Button>
                </ListGroupItem>
            ))}
    </ListGroup>
);


export default connect((state) => ({ vessels: state.accounts.vessels}))(ListVessels);
