import React, {Component} from "react";
import { connect } from 'react-redux';
import { round } from '../../../config/sparkLineChartOptions.js';
import {
  Card,
  CardBody,
} from "reactstrap";

const Performance = ({ fleet }) => (
  <Card>
    <CardBody className="card-body">
      <p>Performance <small>[kg/nmi]</small></p>
      <h2>{round(fleet.performance)}</h2>
    </CardBody>
  </Card>
)
export default connect((state) => ({fleet: state.fleet.fleet }))(Performance);
