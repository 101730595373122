import React from "react";
import { connect } from "react-redux";
import { Line } from "react-chartjs-2";
import moment from "moment";
import humanizeDuration from "humanize-duration";
import { Row, Col, Card, Container, CardBody } from "reactstrap";
import {
  backgroundColor,
  color,
  round,
} from "../../../config/sparkLineChartOptions.js";
import Spinner from "react-spinkit";

const check = (func, err) => {
  return func && func != "NaN" || func == 0 ? func : err;
}

const getEndTime = (bunker) => {
  return moment(bunker.get("endTime") || new Date());
};

const getStartTime = (bunker) => {
  return moment(bunker.get("startTime") || new Date());
};

const getStartDateTime = (bunker) => {
  return bunker.get("startTime") || new Date();
};

const getEndDateTime = (bunker) => {
  return bunker.get("endTime") || new Date();
};

const getDuration = (bunker) => {
  const diff = getEndTime(bunker).unix() - getStartTime(bunker).unix();
  return humanizeDuration(diff * 1000);
};

const order = {
  MassFlow: 1,
  Temperature: 2,
  Density: 3,
  SensorSignal: 4,
  OutletPressure: 5,
  InletPressure: 6,
};

const getOrder = (bunkerMeasurements) =>
  bunkerMeasurements.sort(
    (a, b) => (order[a.get("type")] || 99) - (order[b.get("type")] || 99)
  );

const rename = (type) => {
  if (type == "InletPressure") {
    return "ValveOutletPressure";
  }
  return type;
};

const lineGraph = (bunkerMeasurement, startDate) => {
  var factor = 1;
  var type = rename(bunkerMeasurement.get("type"));
  var unit = "";
  if (type === "MassFlow") {
    factor = 3600 / 1000;
    unit = "[MT/h]";
  } else if (type === "Temperature") {
    unit = "[\u00B0C]";
  } else if (type === "Density") {
    unit = "[kg/m\u00B3]";
  } else if (type === "SensorSignal") {
    unit = "[%]";
  }
  return (
    <Col key={bunkerMeasurement.id} xs="12" xl="6">
      <Card>
        <CardBody>
          <h4>
            {rename(bunkerMeasurement.get("type")).replace(/([A-Z])/g, " $1")}{" "}
            {unit}{" "}
          </h4>
          <Line
            data={lineData(
              bunkerMeasurement.get("values").map((v) => v * factor),
              startDate,
              rename(bunkerMeasurement.get("type")).replace(/([A-Z])/g, " $1")
            )}
            options={{
              responsive: true,
              tooltips: {
                intersect: false,
                mode: "label",
                callbacks: {
                  label: ({ yLabel }) => {
                    return round(yLabel, 2);
                  },
                },
              },
              maintainAspectRatio: true,
              scales: {
                xAxes: [
                  {
                    display: true,
                  },
                ],

                yAxes: [
                  {
                    display: true,
                  },
                ],
              },
              elements: {
                line: {
                  borderWidth: 2,
                },
                point: {
                  radius: 0,
                  hitRadius: 10,
                  hoverRadius: 4,
                  hoverBorderWidth: 3,
                },
              },
              legend: {
                display: false,
              },
            }}
            width={100}
            height={40}
          />
        </CardBody>
      </Card>
    </Col>
  );
};

const lineData = (values, startDate) => {
  const startTicks = startDate ? startDate.getTime() : 0;
  // NOTE: Chart does not support UTC (only local date) so convert to UTC first
  return () => ({
    labels: values.map((val, index) =>
      moment(new Date(startTicks + index * 1000)).format("DD MMM HH:mm")
    ),
    datasets: [
      {
        data: values,
        backgroundColor: backgroundColor,
        borderColor: color,
      },
    ],
  });
};

const BunkerReport = ({
  bunker,
  bunkerMeasurements,
  startDate,
  endDate,
  measurementsLoaded,
}) => (
  <Container fluid className="page">
    <Row>
      <Col>
        <Card>
          <CardBody>
            <h4>
              {bunker.get("vessel") ? bunker.get("vessel").get("name") : ""}
            </h4>
            <h5>
              {bunker.get("port")} {getStartTime(bunker).format("DD MMMM YYYY")}
            </h5>
            <p className="small text-muted">
              {getStartTime(bunker).format("DD MMM YYYY HH:mm")} --{" "}
              {getEndTime(bunker).format("DD MMM YYYY HH:mm")}
            </p>
            <Row className="bunkerItems">
              <Col sm="12" lg="4">
                <ul>
                  <li>
                    <strong>Batch: </strong>{" "}
                    <span>{check(bunker.get("batchId"), '---')}</span>
                  </li>
                  <li>
                    <strong>Stream: </strong>{" "}
                    <span>{check(bunker.get("stream"), '---')}</span>
                  </li>
                  <li>
                    <strong>Product: </strong>{" "}
                    <span>
                      {check(bunker.get("fuelType")?.get("name"), '---')}
                    </span>
                  </li>
                  <li>
                    <strong>Duration: </strong>{" "}
                    <span>{check(getDuration(bunker), '---')}</span>
                  </li>
                  <li>
                    <strong>Port: </strong> <span>{check(bunker.get("port"), '---')}</span>
                  </li>
                </ul>
              </Col>
              <Col sm="12" lg="4">
                <ul>
                  <li>
                    <strong>Total mass: </strong>{" "}
                    <span>{check(round(bunker.get("massTotal") / 1000, 2), '---')} MT</span>
                  </li>
                  <li>
                    <strong>Total volume: </strong>{" "}
                    <span>{check(round(bunker.get("volumeTotal"), 2), '---')} m&sup3;</span>
                  </li>
                  <li>
                    <strong>Avg. massflow: </strong>{" "}
                    <span>
                      {check(round((bunker.get("avgMassFlow") * 3600) / 1000, 2), '---')} MT/h
                    </span>
                  </li>
                  <li>
                    <strong>Avg. temp: </strong>{" "}
                    <span>{check(round(bunker.get("avgTemp"), 1), '---')} &deg;C</span>
                  </li>
                  <li>
                    <strong>Avg. density: </strong>{" "}
                    <span>{check(round(bunker.get("avgDensity")), '---')} kg/m&sup3;</span>
                  </li>
                </ul>
              </Col>
              <Col sm="12" lg="4">
                <ul>
                  <li>
                    <strong>Aeration: </strong>{" "}
                    <span>{check(round(bunker.get("aeration")), '---')} %</span>
                  </li>
                  <li>
                    <strong>Supplier: </strong>{" "}
                    <span>{check(bunker.get("supplier"), '---')}</span>
                  </li>
                  <li>
                    <strong>BDN: </strong>{" "}
                    <span>
                      {check(round(bunker.get("massTotalBdn") / 1000, 2), '---')} MT
                    </span>
                  </li>
                  <li>
                    <strong>Diff: </strong>{" "}
                    <span>
                      {check(round(
                        (bunker.get("massTotal") - bunker.get("massTotalBdn")) /
                          1000,
                        2
                      ), '---')}{" "}
                      MT
                    </span>
                  </li>
                  <li>
                    <strong>Comment: </strong>{" "}
                    <span>{check(bunker.get("comment"), '---')}</span>
                  </li>
                </ul>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
    <Row>
      {bunkerMeasurements ? (
        getOrder(bunkerMeasurements).map((bunkerMeasurement) =>
          lineGraph(bunkerMeasurement, startDate, endDate)
        )
      ) : (
        <Col>
          <div className="centerLoader">
            <p>Loading measurement data</p>
            <Spinner color="#005DA8" name="folding-cube" />
          </div>
        </Col>
      )}
    </Row>
  </Container>
);

export default connect(() => ({ bunkerItems }) => ({
  bunker: bunkerItems.bunker,
  startDate: getStartDateTime(bunkerItems.bunker),
  endDate: getEndDateTime(bunkerItems.bunker),
  bunkerMeasurements: bunkerItems.bunkerMeasurements,
  measurementsLoaded: bunkerItems.measurementsLoaded,
}))(BunkerReport);
