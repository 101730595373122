import React, { Component } from "react";
import {
  Form,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import { connect } from "react-redux";

import { makeRequest, updateProfile } from "../../../actions/account";

const Profile = ({ name, address, phone, company, updated, dispatch }) => (
  <div className="app flex-row align-items-center">
    <Container>
      <Row className="justify-content-center">
        <Col md="6">
          <Card className="mx-4">
            <CardBody className="card-body p-4">
              <h4>NEW EcoMATE Cloud</h4>
              <p>
                Fill in the form below to request access, or email us at{" "}
                <a href="mailto:marine-support@krohne.com">
                  marine-support@krohne.com
                </a>
              </p>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();

                  dispatch(makeRequest());
                }}
              >
                <label htmlFor="RQName">Name</label>
                <InputGroup className="mb-3">
                  <InputGroupAddon
                    addonType="prepend"
                    tag="label"
                    htmlFor="RQName"
                  >
                    <i className="icon-user input-group-text"></i>
                  </InputGroupAddon>
                  <Input
                    id="RQName"
                    type="text"
                    onChange={(e) =>
                      dispatch(updateProfile("RQName", e.target.value))
                    }
                    placeholder="Tom Smith"
                    value={name || ""}
                  />
                </InputGroup>
                <label htmlFor="RQAddress">Email address</label>
                <InputGroup className="mb-3">
                  <InputGroupAddon
                    addonType="prepend"
                    tag="label"
                    htmlFor="RQAddress"
                  >
                    @
                  </InputGroupAddon>
                  <Input
                    id="RQAddress"
                    type="text"
                    onChange={(e) => {
                      dispatch(updateProfile("RQAddress", e.target.value));
                    }}
                    placeholder="name@domain.com"
                    value={address || ""}
                  />
                </InputGroup>
                <label htmlFor="RQPhone">Phone number</label>
                <InputGroup className="mb-3">
                  <InputGroupAddon
                    addonType="prepend"
                    tag="label"
                    htmlFor="RQPhone"
                  >
                    <i className="icon-phone input-group-text"></i>
                  </InputGroupAddon>
                  <Input
                    id="RQPhone"
                    type="text"
                    onChange={(e) =>
                      dispatch(updateProfile("RQPhone", e.target.value))
                    }
                    placeholder="+47 00000000"
                    value={phone || ""}
                  />
                </InputGroup>
                <Button color={updated ? "success" : "secondary"} block>
                  Request Access
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  </div>
);

export default connect((state) => ({
  name: state.accounts.user.get("RQName"),
  address: state.accounts.user.get("RQAddress"),
  phone: state.accounts.user.get("RQPhone"),
  saving: state.accounts.saving,
  updated: state.accounts.updated,
  company: state.accounts.user.get("company"),
}))(Profile);
