import React from "react";
import { connect } from 'react-redux';
import _ from 'lodash';
import {
    Card,
    CardHeader,
    CardBody,
    Table,
    Row,
    Col,
    Container
} from "reactstrap";

import GroupForm from './GroupForm.js';
import VesselForm from './VesselForm.js';
import { editVessel } from '../../../actions/vesselGroups.js';



const getName = (vessel, groupItems) => {
    const ids = (vessel.get('groupItems') || [])
        .map(item => item._getId ? item._getId() : null);
    return (groupItems || []).filter(item => _.indexOf(ids, item._getId()) > -1)
                       .reduce((names, item) => names + item.get('name'), '');
};

const makeTableData = (vessels, groups, groupItems) => vessels.map(vessel => ({
    key: vessel._getId(),
    vessel,
    name: vessel.get('name'),
    groups: groups.map(group => ({
        key: group._getId(),
        name: getName(vessel, groupItems[group._getId()])
    }))
}));

const Organise = ({groups, data, dispatch}) => (
    <Container fluid>
        <div  className="organise page">
            <VesselForm></VesselForm>
            <Card>
                <CardHeader>
                    <Row>
                        <Col>Organise vessels</Col>
                        <Col>
                            <GroupForm></GroupForm>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Table className="clickAble" responsive striped>
                        <thead>
                            <tr>
                                <th></th>

                                {groups.map(group => (
                                    <th key={group._getId()}>
                                        {group.get('name')}
                                    </th>))}
                            </tr>
                        </thead>
                        <tbody>
                            {data.map(row => (
                                <tr key={row.key} onClick={ () => dispatch(editVessel(row.vessel))}>
                                    <td>{row.name}</td>
                                    {row.groups.map(col =>
                                        (<td key={col.key}>
                                            {col.name}
                                        </td>))}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        </div>
    </Container>);

export default connect(() => ({ vessels, vesselGroups }) => {
    return ({ vesselOption: vesselGroups.vesselOption,
              groups: vesselGroups.groups,
              data: makeTableData(vessels.allVessels, vesselGroups.groups, vesselGroups.groupItems)});
})(Organise);
