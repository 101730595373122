
import React from 'react';
import { render } from 'react-dom';
import MapPopUp from './MapPopUp.js';
import { connect } from 'react-redux';
import MarkerClusterGroup from 'react-leaflet-markercluster';

import { store } from '../../../stores/index.js';
import _ from 'lodash';
import L from 'leaflet';
import PropTypes from 'prop-types';


const circleMarker = colour => new L.DivIcon({
    html: '<div style="background-color:' + colour + '"></div>',
    className: 'marker-cluster circle',
    iconSize: new L.Point(10, 10)
});

const makePopup = (e, points, vessel) => {
    var element = document.createElement("div");
    render((<MapPopUp points={_.orderBy(points, ['timestamp'], ['asc'])} vessel={vessel} store={store} />), element);
    return L.popup({minWidth: 300, maxWidth: 500})
        .setLatLng(e.getLatLng())
        .setContent(element)
        .openOn(e._map);
}

const MarkerCluster = ({ vessel, history, dispatch }) => (
        <MarkerClusterGroup
    key={vessel.get('pkId')}
    spiderfyOnMaxZoom={false}
    showCoverageOnHover={false}
    zoomToBoundsOnClick={false}
    removeOutsideVisibleBounds={false}
    disableClusteringAtZoom={13}
    maxClusterRadius={30}

    iconCreateFunction={(() => {
        return new L.DivIcon({
            html: '<div style="background-color:' + vessel.get('colour') + '"></div>',
            className: 'marker-cluster circle',
            iconSize: new L.Point(10, 10)
        });
    })}

    onMarkerClick={(e) => {
        makePopup(e, [e.options.point], vessel, dispatch);
    }}

    onClusterClick={(e) => {
        makePopup(e, e.getAllChildMarkers().map(m => m.options.point), vessel, dispatch);
    }}

    markers={history.map(point => (
        {
            position: point.position,
            options: { icon: circleMarker(vessel.get('colour')), fillColor: vessel.get('colour'), point }
        }))}
        />);

MarkerCluster.propTypes = {
    vessel: PropTypes.object,
    dispatch: PropTypes.func,
    history: PropTypes.array,
};

export default connect((state, { history, vessel }) => ({ vessel, history }))(MarkerCluster);
