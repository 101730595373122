import React, { Component }  from 'react';
import { render } from 'react-dom';
import { Map, TileLayer, Polyline, Tooltip, AttributionControl } from 'react-leaflet';

import { toggleVoyageDetails } from '../../../actions/voyages.js';
import { connect } from 'react-redux';
import _ from 'lodash';

import { editPOI } from '../../../actions/POI.js';
import MarkerCluster from './MarkerCluster.js';
import BoatMarker from './BoatMarker.js';
import MapPopUp from './MapPopUp.js';
import POIMarker from './POIMarker.js';
import modeMap from './Mode.js';
import isValidPoint from '../../../gis/isValidPoint.js';
import { store } from '../../../stores/index.js';

const makeLine = points => {
    const lines = breakLines([], points || []);
    return lines.map((line, i) =>
        (<Polyline
             key={i}
             color={modeMap[line[0].mode].colour}
             opacity={0.7}
             positions={line.map(point => point.position)}>
        </Polyline>));
}

const breakLines = (lines, pts, line) => {
    let points = [...pts];
    let point = points.pop();

    if(!point && line && line.length > 1) {
        lines.push(line);
    }

    if (!point) return lines;

    if(!line && isValidPoint(point)) {
        line = [point];
    } else if (!line && !isValidPoint(point)) {
        return breakLines(lines, points);
    }

    var dist = Math.abs(point.position[1] - _.last(line).position[1]);
    if (dist > 30) {
        if (line.length > 1) {
            lines.push(line);
        }
        line = null;
    }
    else if (!isValidPoint(point)) {
        if (line.length > 1) {
            lines.push(line);
        }
        line = null;
    }
    else {
        line.push(point);
        if (point.mode !== _.last(line).mode) {
            lines.push(line);
            line = null;
        }
    }

    return breakLines(lines, points, line);
}


const makePopup = (e, point, vessel) => {
    var element = document.createElement("div");
    render((<MapPopUp points={[point]} vessel={vessel} store={store} />), element);
    L.popup({minWidth: 300, maxWidth: 500})
     .setLatLng(e.latlng)
     .setContent(element)
     .openOn(e.target._map);
}

const makePoints = (h) => {
    const points = h.history;
    const filteredPoints = points.filter(isValidPoint);
    return (filteredPoints.length) ? (
        <div key={h.vessel.get('pkId')}>
            <MarkerCluster vessel={h.vessel}  history={filteredPoints}></MarkerCluster>
            {makeLine(points)}
            <BoatMarker
                vessel={h.vessel}
                position={_.last(filteredPoints).position}
                last={_.last(filteredPoints)}
                color={h.vessel.get('colour')}
                onClick={e => makePopup(e, _.last(filteredPoints), h.vessel)}>
                <Tooltip
                    direction='right'
                    offset={[20, -2]}
                    opacity={0.75}
                    permanent>
                    <span><span className="ref" style={{backgroundColor: h.vessel.get('colour')}}></span> {h.vessel.get('name')}</span>
                </Tooltip>
            </BoatMarker>
        </div>
    ) : null;
}

class MapCard extends Component {


    render() {
        const {history, bounds, poi, dispatch} = this.props;

        return (
            <Map
            ref={(m) => { this.map = m; }}
            scrollWheelZoom={false}
            touchZoom={true}
            bounceAtZoomLimits={true}
            className="vesselsMap"
            zoom={11}
            minZoom={2}
            maxZoom={12}
            worldCopyJump={true}
            inertia={true}
            attributionControl={false}
            bounds={bounds || [[-180, -180], [180, 180]]}
            onPopupClose={e => {
                dispatch(toggleVoyageDetails(false));
            }}
            attributionControl={false}>
            <TileLayer
            url='https://api.maptiler.com/maps/voyager/{z}/{x}/{y}.png?key=RtpzrAWmnPkmA6SHqzr5'
            attribution={'&copy; <a href="http://cartodb.com/attributions">CARTO</a> &copy;<a href="https://www.maptiler.com/license/maps/">MapTiler</a> &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap contributors</a>'}/>
            <AttributionControl position="bottomright" />
            {history.map(h => makePoints(h))}
            {poi.filter(p => p.get("position")).map(p => (
                <POIMarker key= { p.id || "" }
                position={_.isArray(p.get("position"))? p.get("position").map(c => Number(c)) : [Number(p.get("position").latitude), Number(p.get("position").longitude)]}
                markerType={p.get("markerType")}
                point={p}
                onClick={() => dispatch(editPOI(p))}>
                { p.get('label') ? (
                    <Tooltip direction='right' offset={[20, -2]}>
                        <span>{p.get('label')}</span>
                    </Tooltip>) : null}
                    </POIMarker>
            ))}

            </Map>
        )
    }
}

export default connect((state) => {
    return ({
        poi: state.POI.points
    });
})(MapCard);
