
import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';

import accounts from './account.js';
import vessels  from './vessels.js';
import fleet  from './fleet.js';
import nav from './nav.js';

import tags from './tags.js';
import bunkerItems from './bunkerItems.js';
import voyages from './voyages.js';
import consumers from './consumers.js';
import fuelTypes from './fuelTypes.js';
import POI from './POI.js';
import logs from './logs.js';
import fuelprices from './fuelprices.js';
import error from './error.js';
import vesselGroups from './vesselGroups.js';
import allBunkerItems from './allBunkerItems.js';
import search from './search.js';
import saveReport from './saveReport.js';

const reducers = combineReducers({
    accounts,
    vessels,
    fleet,
    nav,
    tags,
    bunkerItems,
    voyages,
    fuelTypes,
    fuelprices,
    consumers,
    POI,
    logs,
    routing: routerReducer,
    search,
    error,
    vesselGroups,
    allBunkerItems,
    saveReport
});

export default reducers;
