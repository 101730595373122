// jshint esversion:6

import Parse from '../parse';
import * as Excel from '@progress/kendo-ooxml';
import { saveAs } from '@progress/kendo-file-saver';
import { addErrorMessage } from './error.js';
import { findFuelTypes } from './fuelTypes.js';
const Voyage = Parse.Object.extend("Voyage");
import moment from 'moment';
import _ from 'lodash';

export const loading = () => {
    return {
        type: 'SAVEREPORT_LOADING'
    };
}

export const loaded = () => {
    return {
        type: 'SAVEREPORT_LOADED'
    };
}
// Excel style parameters
const headerFrontColor = "#44546a";
const headerBackColor = "#ffffff";
const headerBorderThickness = 2; // 1, 2 or 3
const headerFontSize = 20; // 20 translates to 15 in MS Excel 16.0.11126.20102
const headerRowHeigth = 30;
const subHeaderBackColor = "#70ad47";
const subHeaderFrontColor = "#ffffff";
const subHeaderBorderThickness = 1; // 1, 2 or 3
const subHeaderFontSize = 15; // 15 translates to 11.5 in MS Excel 16.0.11126.20102
const subHeaderRowHeigth = 21;
const borderColor = "#4472c4";
const fontFamily = "Calibri";
const fontSize = 14.5; // 14.5 translates to 11 in MS Excel 16.0.11126.20102
const dataRowHeight = 21;


// Default styles for header cells
const defaultFirstRowCells = {
    background: headerBackColor,
    borderBottom: {
        color: borderColor,
        size: headerBorderThickness
    },
    color: headerFrontColor,
    fontFamily: fontFamily,
    fontSize: headerFontSize,
    textAlign: "center",
    verticalAlign: "center",
    wrap: true
}
const defaultSecondRowCells = {
    background: headerBackColor,
    borderBottom: {
        color: borderColor,
        size: headerBorderThickness
    },
    color: headerFrontColor,
    fontFamily: fontFamily,
    fontSize: headerFontSize,
    textAlign: "center",
    verticalAlign: "center",
    wrap: true
}
const defaultThirdRowCells = {
    colSpan: 1,
    rowSpan: 1,
    bold: true,
    background: subHeaderBackColor,
    borderBottom: {
        color: borderColor,
        size: subHeaderBorderThickness
    },
    color: subHeaderFrontColor,
    fontFamily: fontFamily,
    fontSize: subHeaderFontSize,
    textAlign: "left",
    verticalAlign: "center"
}
// Default style for data cells
const defaultDataCell = {
    fontFamily: fontFamily,
    format: "0.00",
    fontSize: fontSize
}

const defaultConsumption = {
    'Total': 0,
    'At sea': 0,
    'At berth': 0
}

// Configures columns based on the keys provided.
const columnConfiguration = (metaKeys, totalKeys, fuelKeys, consumerKeys) => {
    const result = [];
    Array(metaKeys.length).fill('').forEach(() => {
      result.push({
          autoWidth: true
          // width: 150
      });
    });
    result.push({
        width: 30
    });

    Array(totalKeys.length).fill('').forEach(() => {
      result.push({
          autoWidth: true
          // width: 150
      });
    });
    result.push({
        width: 30
    });
    Array(fuelKeys.length * totalKeys.length).fill('').forEach(() => {
      result.push({
          autoWidth: true
          // width: 150
      });
    });
    result.push({
        width: 30
    });    
    Array(consumerKeys.length * totalKeys.length).fill('').forEach(() => {
      result.push({
          autoWidth: true
          // width: 150
      });
    });

    return result;
}
// Configures header rows based on the data and keys provided
const headerRowConfiguration = (data, metaKeys, totalKeys, fuelKeys, consumerKeys) => {
    const title = "Voyage report " + data[_.keys(data)[0]]['Vessel name']
    var index = 0;
    const firstRowCells = [];
    const secondRowCells = [];
    const thirdRowCells = [];

    // Meta data
    firstRowCells.push({
        ...defaultFirstRowCells,
        index: index,
        colSpan: 3,
        rowSpan: 2,
        value: title.toUpperCase()
    });
    firstRowCells.push({
        ...defaultFirstRowCells,
        index: index + 3,
        colSpan: metaKeys.length - 3,
        rowSpan: 2,
        value: "VOYAGE DATA"
    });
    metaKeys.forEach(key => {
        thirdRowCells.push({
            ...defaultThirdRowCells,
            index: index,
            value: key
        });
        index++;
    });
    index++;

    // Total consumption
    firstRowCells.push({
        ...defaultFirstRowCells,
        index: index,
        colSpan: totalKeys.length,
        rowSpan: 2,
        value: "Total consumption [MT]".toUpperCase()
    })
    totalKeys.forEach(key => {
        thirdRowCells.push({
            ...defaultThirdRowCells,
            index: index,
            value: key
        });
        index++;
    });
    index++;

    // Pr fuel type
    firstRowCells.push({
        ...defaultFirstRowCells,
        index: index,
        colSpan: fuelKeys.length * totalKeys.length,
        rowSpan: 1,
        value: "Pr Fuel Type [MT]".toUpperCase()
    })
    fuelKeys.forEach(fuelKey => {
        secondRowCells.push({
            ...defaultSecondRowCells,
            index: index,
            colSpan: totalKeys.length,
            rowSpan: 1,
            value: fuelKey.toUpperCase()
        });
        totalKeys.forEach(key => {
            thirdRowCells.push({
                ...defaultThirdRowCells,
                index: index,
                value: key
            });
            index++;
        });
    });
    index++;

    // Pr consumer
    firstRowCells.push({
        ...defaultFirstRowCells,
        index: index,
        colSpan: consumerKeys.length * totalKeys.length,
        rowSpan: 1,
        value: "Pr Consumer [MT]".toUpperCase()
    })
    consumerKeys.forEach(consumerKey => {
        secondRowCells.push({
            ...defaultSecondRowCells,
            index: index,
            colSpan: totalKeys.length,
            rowSpan: 1,
            value: consumerKey.toUpperCase()
        });
        totalKeys.forEach(key => {
            thirdRowCells.push({
                ...defaultThirdRowCells,
                index: index,
                value: key
            });
            index++;
        });
    });

    return [{
            cells: firstRowCells,
            height: headerRowHeigth,
            index: 0
        },
        {
            cells: secondRowCells,
            height: headerRowHeigth,
            index: 1
        },
        {
            cells: thirdRowCells,
            height: subHeaderRowHeigth,
            index: 2
        }
    ]
}

// Tools
const fileTitle = (vesselIds, from, to) => {
    var name = (Array.isArray(vesselIds) && vesselIds.length > 1) ? "Voyage report" : vesselIds[0];
    return name + " " + moment(from).format("MMMDD") + "-" + moment(to).format("MMMDD");
}

// Custom converter
const createExcelData = (value, dividend = 1) => {
    if (moment.isMoment(value)) return value.format("lll");
    else if (moment.isDuration(value)) {
        var hours = value.asMinutes() / 60;
        var rhours = Math.floor(hours);
        var minutes = Math.round((hours - rhours) * 60);
        if (minutes < 10) minutes = "0" + minutes;
        if (rhours < 10) rhours = "0" + rhours;
        return rhours + ":" + minutes;
    } else if (!isNaN(value)) {
        return value / dividend;
    } else return value ? value.toString() : "";
}

// Gets meta data from voyage
const voyageMetaData = (voyages, objectTemplate) => {
    var reportData = {};
    const vesselIds = [];
    voyages.forEach((v) => {
        var vesselName = v.get('vessel').get('name');
        // Adds to vesselId, if it's not there
        if (_.indexOf(vesselIds, vesselName) === -1) vesselIds.push(vesselName);

        // Creates an index for reportData and creates a temporary object
        var voyageNumber = v.get('vesselId') + v.get('voyageNo');
        const out = reportData[voyageNumber] || {
            ...objectTemplate,
            VoyageID: voyageNumber
        };

        // Finds essential times
        const startTime = moment(v.get('startTime').valueOf());
        const arrivalTime = moment(v.get('events').find(event => (event.EventType == "ArrivedAtBerth")).EventTime.valueOf());
        const endTime = moment(v.get('endTime').valueOf());

        // Fills temporary object with data
        out['VesselId'] = v.get('vesselId');
        out['Vessel name'] = vesselName;
        out['Voyage number'] = v.get('voyageNo');
        out['Departure port'] = v.get('departurePort');
        out['Arrival port'] = v.get('arrivalPort');
        out['Distance sailed [nmi]'] = v.get('distanceSailed') / 1852;
        out['Departure'] = startTime;
        out['Arrival'] = arrivalTime;
        out['End'] = endTime;
        out['Duration [hh:mm]'] = moment.duration(endTime.diff(startTime));
        out['Time at sea [hh:mm]'] = moment.duration(arrivalTime.diff(startTime));
        out['Time at berth [hh:mm]'] = moment.duration(endTime.diff(arrivalTime));
        out['Method'] = v.get('monitoringMethod');

        // Insert or updates reportData for the voyage.
        reportData[voyageNumber] = out;
    });
    return {
        reportData: reportData,
        vesselIds: vesselIds
    };
}

// Loads the extra data needed for the report generation
const findAggregateData = (voyage) => {
    // Creates a promise to find and return consumption and fueltypes
    return Promise.all([
            findFuelTypes(voyage).then(r => {
                return {
                    name: "fuelTypes",
                    result: r
                }
            }),
            // Finds consumption data at berth
            Parse.Cloud.run('getFuelConsumptionReport2', {
                vesselId: voyage.get('vesselId'),
                from: moment(voyage.get('events').find(event => (event.EventType == "ArrivedAtBerth")).EventTime.valueOf()).toISOString(),
                to: moment(voyage.get('endTime').valueOf()).toISOString(),
                includeConsumers: true
            }).then(r => {
                return {
                    name: "atBerthConsumer",
                    result: r
                }
            }),
            // Finds consumption data at sea
            Parse.Cloud.run('getFuelConsumptionReport2', {
                vesselId: voyage.get('vesselId'),
                from: moment(voyage.get('startTime').valueOf()).toISOString(),
                to: moment(voyage.get('events').find(event => (event.EventType == "ArrivedAtBerth")).EventTime.valueOf()).toISOString(),
                includeConsumers: true
            }).then(r => {
                return {
                    name: "atSeaConsumer",
                    result: r
                }
            })
        ])
        .then((results) => {
            // Returns an object with named parameters.
            return results.reduce((prev, curr) => {
                prev[curr.name] = curr.result;
                return prev;
            }, {});
        })
}

// Inserts, or updates, data in prconsumer
const consumerCalc = (prconsumer, consumption, key) => {
    var outConsumer = prconsumer[consumption.consumer] || {
        ...defaultConsumption
    };
    outConsumer['Total'] += consumption.consumption;
    outConsumer[key] += consumption.consumption;
    prconsumer[consumption.consumer] = outConsumer;
    return prconsumer;
}

// Main methods

// Creates and fills a workbook with provided data. Returns the URL of the workbook.
const writeXlsFile = (data) => {
    // Gets the different keys for each section of the report
    const voyageKeys = _.keys(data);
    const metaKeys = _.pull(_.keys(data[_.keys(data)[0]]), "VesselId", "VoyageID", "Vessel name", "Pr Fuel Type [MT]", "Pr Consumer [MT]", "Total consumption [MT]");
    const totalKeys = _.keys(data[_.keys(data)[0]]['Total consumption [MT]']);
    var fuelKeys = [];
    var consumersKeys = [];
    voyageKeys.forEach(key => {
        fuelKeys = fuelKeys.concat(_.keys(data[key]['Pr Fuel Type [MT]']));
        consumersKeys = consumersKeys.concat(_.keys(data[key]['Pr Consumer [MT]']));
    });

    // Remove duplicates
    fuelKeys = _.uniqBy(fuelKeys);
    consumersKeys = _.uniqBy(consumersKeys);

    // Initializes the rows with header rows
    var rows = headerRowConfiguration(data, metaKeys, totalKeys, fuelKeys, consumersKeys);
    var rowIndex = rows.length;

    // Add data to rows
    voyageKeys.forEach(voyageKey => {
        var index = 0;
        var row = {
            index: rowIndex,
            cells: [],
            height: dataRowHeight
        };

        // Fill row with meta data
        metaKeys.forEach(metaKey => {
            var format = (metaKey == 'Voyage number') ? "" : "0.00";
            row.cells.push({
                ...defaultDataCell,
                index: index,
                format: format,
                value: createExcelData(data[voyageKey][metaKey])
            });
            index++;
        });
        index++;

        // Fill row with totalizer data
        totalKeys.forEach(totalKey => {
            row.cells.push({
                ...defaultDataCell,
                index: index,
                value: createExcelData(data[voyageKey]['Total consumption [MT]'][totalKey], 1000)
            });
            index++;
        })
        index++;

        // Fill row with data pr fuel type
        fuelKeys.forEach(fuelKey => {
            var fuel = data[voyageKey]['Pr Fuel Type [MT]'][fuelKey];
            // If this voyage has not used this fuel type, add default row (with 0 values)
            fuel = fuel || defaultConsumption;
            totalKeys.forEach(totalKey => {
                row.cells.push({
                    ...defaultDataCell,
                    index: index,
                    value: createExcelData(fuel ? fuel[totalKey] : fuel, 1000)
                });
                index++;
            })
        })
        index++;
        // Fill row with data pr consumer
        consumersKeys.forEach(consumerKey => {
            var consumer = data[voyageKey]['Pr Consumer [MT]'][consumerKey];
            totalKeys.forEach(totalKey => {
                row.cells.push({
                    ...defaultDataCell,
                    index: index,
                    value: createExcelData(consumer ? consumer[totalKey] : consumer)
                });
                index++;
            })
        });

        rows.push(row);
        rowIndex++;
    });

    // Create a new workbook
    var workbook = new Excel.Workbook({
        creator: "Krohne Marine - ECOmate",
        sheets: [{
            name: "CONSUMPTION",
            frozenColumns: 3,
            frozenRows: 3,
            columns: columnConfiguration(metaKeys, totalKeys, fuelKeys, consumersKeys),
            rows: rows
        }]
    });

    return workbook.toDataURL();
}

// Creates a query for loading voyages for each vessel
const makeReportVoyageQuery = (vessels, from, to) => {
    if (moment.isMoment(from)) from = from.toDate();
    if (moment.isMoment(to)) to = to.toDate();
    if (!(vessels instanceof Array)) vessels = [vessels];
    const query = new Parse.Query(Voyage);
    query.containedIn('vessel', vessels);
    query.descending("startTime");
    query.notEqualTo("deleted", true);
    query.notEqualTo("draft", true);
    query.lessThanOrEqualTo("startTime", to);
    query.greaterThanOrEqualTo("startTime", from);
    return query;
}

// Creates a promise to return all the data for the voyages
const buildReportDataXls = (voyages) => {
    const requests = [];
    requests.push(new Promise((resolve) => {
            var metaData = voyageMetaData(voyages);
            resolve(metaData);
        })
        .then((metaData) => {
            // Fills report data with metadata for each voyage
            var reportData = metaData.reportData;

            // Contains each vessel id in voyages array
            var vesselIds = metaData.vesselIds;

            // Requests to promise and resolve
            const subrequests = [];

            // Create a request for each voyage
            voyages.forEach((v) => {
                subrequests.push(findAggregateData(v)
                    .then((r) => {
                        // Initialization of variables and constants

                        var fuelTypes, atSeaConsumer, atBerthConsumer, voyageNumber;

                        // Create temporary object for inserting or updating each voyage
                        voyageNumber = v.get('vesselId') + v.get('voyageNo');
                        const out = reportData[voyageNumber] || {
                            ...objectTemplate,
                            VoyageID: voyageNumber
                        };

                        // Create object for storing data pr consumer and fill with data
                        const prconsumer = {};
                        atSeaConsumer = r.atSeaConsumer.consumption;
                        atSeaConsumer.forEach(consumption => consumerCalc(prconsumer, consumption, 'At sea'));
                        atBerthConsumer = r.atBerthConsumer.consumption;
                        atBerthConsumer.forEach(consumption => consumerCalc(prconsumer, consumption, 'At berth'));
                        out['Pr Consumer [MT]'] = prconsumer;

                        // Create object for storing data pr fuel type
                        const prfuel = {};
                        fuelTypes = r.fuelTypes;
                        _.keys(fuelTypes).forEach(key => {
                            prfuel[fuelTypes[key].get('category')] = {
                                ...defaultConsumption
                            }
                        });

                        // Create object for storing total consumption
                        const totalConsumption = {
                            ...defaultConsumption
                        };

                        // Fill pr fuel type and total consumption objects with data
                        v.get('consumptionAtSea').forEach(consumption => {
                            prfuel[fuelTypes[consumption.fuelTypeId].get('category')]['Total'] += consumption.totalFuel;
                            prfuel[fuelTypes[consumption.fuelTypeId].get('category')]['At sea'] = consumption.totalFuel;
                            totalConsumption['Total'] += consumption.totalFuel;
                            totalConsumption['At sea'] += consumption.totalFuel;
                        });
                        v.get('consumptionAtArrivalPort').forEach(consumption => {
                            prfuel[fuelTypes[consumption.fuelTypeId].get('category')]['Total'] += consumption.totalFuel;
                            prfuel[fuelTypes[consumption.fuelTypeId].get('category')]['At berth'] = consumption.totalFuel;
                            totalConsumption['Total'] += consumption.totalFuel;
                            totalConsumption['At berth'] += consumption.totalFuel;
                        });

                        out['Pr Fuel Type [MT]'] = prfuel;
                        out['Total consumption [MT]'] = totalConsumption;

                        // Insert or update current voyage data in report data
                        reportData[voyageNumber] = out;
                    }));
            })
            return Promise.all(subrequests)
                .then(() => {
                    return {
                        reportData,
                        vesselIds
                    };
                });
        }));
    return Promise.all(requests)
        .then((result) => {
            return result;
        });
}

export const saveReportXls = (vessels, from, to) => async (dispatch) => {
    try {
        dispatch(loading());
        var basicVoyages = await makeReportVoyageQuery(vessels, from, to).find();
        if (basicVoyages.length === 0) {
            dispatch(addErrorMessage({
                txt: "No voyages in selected time period",
                colour: "info"
            }));
            return;
        }
        var voyages = await Parse.Object.fetchAllIfNeeded(basicVoyages);
        var data = await buildReportDataXls(voyages);
        var url = await writeXlsFile(data[0].reportData);
        saveAs(url, fileTitle(data[0].vesselIds, from, to));
    }
    catch(ex) {
        console.log(ex);
        dispatch(addErrorMessage({
            txt: "Unkown error occured",
            colour: "danger"
        }));

    }
    finally {
        dispatch(loaded());
    }
}