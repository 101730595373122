import React from "react";
import { connect } from 'react-redux';
import moment from 'moment';
import { push } from 'react-router-redux';
import _ from 'lodash';


import {
  Pagination,
  PaginationItem,
  PaginationLink,
  UncontrolledTooltip
} from "reactstrap";

const maxItems = 7;

const pgItem = (i, page, dispatch, loadPage) => (<PaginationItem key={i} active={i === page}>
  <PaginationLink onClick={() => { dispatch(loadPage(i)) }} >{i + 1}</PaginationLink>
</PaginationItem>);

const largePagination = (dispatch, count, limit, page, loadPage) => {
  if (limit >= count) return null;
  var pages = [];
  const l = Math.ceil(count / limit);
  const maxPages = Math.floor(Math.min(l, maxItems) / 2) ;
  let start = page - maxPages;
  let end = page + maxPages + 1;

  end -= (start < 0) ? start : 0;
  start += (end > l) ? (l - end) : 0;

  start = Math.max(0, start);
  end = Math.min(l, end);

  if (page > 0) pages.push(
  <PaginationItem key="previous">
    <PaginationLink previous onClick={() => { dispatch(loadPage(page - 1) )}} />
  </PaginationItem>);
  
  for (var i = start; i < end; i++) {
    pages.push(pgItem(i, page, dispatch, loadPage));
  }

  if (page < l - 1) pages.push(<PaginationItem key="next">
    <PaginationLink next onClick={() => { dispatch(loadPage(page + 1)) }} />
  </PaginationItem>);

  return (<Pagination>{pages}</Pagination>);
};

export default largePagination;
