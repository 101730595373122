// jshint esversion:6

import Parse from '../parse';

import { loadBunkerItems, loadBunkerCount } from './bunkerItems';
import { loadVoyages, loadVoyageCount } from './voyages';
import { loadFuelPricesPage, loadFuelPricesCount } from './fuelprices.js';


import { loadPage, loadLogsCount } from './vesselLogs.js';
import { addErrorMessage } from './error.js';

import { startLogout } from '../actions/account';
const Vessel = Parse.Object.extend("Vessel");

import moment from 'moment';


export const loading = () => {
    return {
        type: 'VESSELS_LOADING'
    };
};

export const loaded = (vessels) => {
    return {
        type: 'VESSELS_LOADED',
        vessels
    };
};

export const loadedVessel = (vessel, data) => {
    return {
        type: 'VESSEL_LOADED',
        vessel,
        data
    };
};

export const setActiveVessels = (ids) => {
    return {
        type: 'VESSEL_ACTIVE',
        ids
    };
};

export const vesselUpdated = (vessel, values) => {
    return {
        type: 'VESSEL_UPDATED',
        vessel,
        values
    };
};

export const setTab = (tab) => {
    return {
        type: 'VESSEL_SET_TAB',
        tab
    };
};

export const loadedVesselHistory = (vessel, values) => {
    return {
        type: 'VESSEL_LOADED_HISTORY',
        vessel,
        values
    };
};

export const loadingVesselHistory = (vessel) => {
    return {
        type: 'VESSEL_LOADING_HISTORY',
        vessel
    };
};

export const loadedVessels = (vessels) => {
    return {
        type: 'ALL_VESSELS_LOADED',
        vessels
    };
};


export const loadingFuelConsumption = (fuelConsumption) => {
    return {
        type: 'FUEL_CONSUMPTION_LOADING',
        fuelConsumption
    };
};

export const loadedFuelConsumption = (fuelConsumption) => {
    return {
        type: 'FUEL_CONSUMPTION_LOADED',
        fuelConsumption
    };
};

export const showAllRange = (show) => {
    return {
        type: 'VESSEL_ALL_SELECT_RANGE',
        show
    };
};

export const showRange = (show, vessel) => {
    return {
        type: 'VESSEL_SELECT_RANGE',
        show,
        vessel
    };
};

export const setRangeToFrom = (from, to, vessel) => {
    return {
        type: 'VESSEL_RANGE',
        to,
        from,
        span: 'range',
        vessel
    };
};

export const setRangeTo = (to, vessel) => {
    return {
        type: 'VESSEL_RANGE_TO',
        to,
        span: 'range',
        vessel
    };
};

export const setRangeFrom = (from, vessel) => {
    return {
        type: 'VESSEL_RANGE_FROM',
        from,
        span: 'range',
        vessel
    };
};

export const setDay = (vessel) => {
    return {
        type: 'VESSEL_RANGE',
        to: moment().startOf('hour'),
        from: moment().startOf('day').subtract(1, 'days'),
        span: 'day',
        vessel
    };
};

export const setWeek = (vessel) => {
    return {
        type: 'VESSEL_RANGE',
        to: moment().startOf('hour'),
        from: moment().startOf('day').subtract(1, 'weeks'),
        span: 'week',
        vessel
    };
};

export const setMonth = (vessel) => {
    return {
        type: 'VESSEL_RANGE',
        to: moment().startOf('hour'),
        from: moment().startOf('day').subtract(1, 'months'),
        span: 'month',
        vessel
    };
};

export const setRange = (from, to, vessel) => (dispatch) => {
    if (from == null)
        dispatch(setRangeTo(to, vessel));
    else if (to == null)
        dispatch(setRangeFrom(from, vessel));
    else
        dispatch(setRangeToFrom(from, to, vessel));
    dispatch(updateVessels());
};



export const setDateDay = (vessel) => (dispatch) => {
    dispatch(setDay(vessel));
    dispatch(updateVessels());
};

export const setDateWeek = (vessel) => (dispatch) => {
    dispatch(setWeek(vessel));
    dispatch(updateVessels());
};

export const setDateMonth = (vessel) => (dispatch) => {
    dispatch(setMonth(vessel));
    dispatch(updateVessels());
};


export const loadVessels = (path) => (dispatch, getState) => {
    dispatch(loading());

    const { accounts, vessels } = getState();
    if (!accounts.user) return false;

    if (vessels.vessels.length) {
        dispatch(loaded(vessels.vessels));
        dispatch(updateVessels(path));

    } else {
        try {
            accounts.user.relation("vessels").query()
            .notEqualTo('deleted', true)
            .find()
            .then(vessels => {
                vessels.sort(vessel => vessel.get('name'));
                dispatch(loaded(vessels));
                dispatch(updateVessels(path));
            })
            .catch(e => {
                dispatch(addErrorMessage({txt: "There was an error loading the vessels.", colour: "danger"}));
                if (e.code) {
                    dispatch(addErrorMessage({txt: "There was a session login error, reloading the page now.", colour: "danger"}));
                    setTimeout(() => dispatch(startLogout()), 3000);
                }
            });
        }
        catch(e) {
            dispatch(addErrorMessage({txt: "There was a session login error, reloading the page now.", colour: "danger"}));
            setTimeout(() => dispatch(startLogout()), 3000);
        }
    }
};

const toISOString = (td) => {
    return moment(td).toISOString();
}

export const updateVessels = ( path ) => (dispatch, getState) => {
    const { routing, vessels } = getState();
    const fullPath = path || routing.location.pathname || '';
    const activeVessels = vessels.vessels.filter(v => fullPath.indexOf(v.get('pkId')) !== -1);
    dispatch(setActiveVessels(activeVessels.map(v => v.get('pkId'))));

    activeVessels.map(active => dispatch(loadVessel(active)));
    dispatch(loadFuelConsumption(fullPath));
    dispatch(loadPage(0, fullPath));
    dispatch(loadLogsCount());
    dispatch(loadFuelPricesPage(0, fullPath));
    dispatch(loadFuelPricesCount(fullPath));
    dispatch(loadVoyages(fullPath));
    dispatch(loadVoyageCount());
    dispatch(loadBunkerItems(fullPath));
    dispatch(loadBunkerCount());
};

export const loadVessel = ( active ) => (dispatch, getState) => {

    const { vessels } = getState();

    const to = moment(active.get('to') || vessels.to);
    const from = moment(active.get('from') || vessels.from);

    if ((!active.get('history'))
        || (Number(moment(active.get('historyFrom') || 0) ) !== Number(from))
        || (Number(moment(active.get('historyTo') || 0)) !== Number(to))) {

        dispatch(loadedVesselHistory(active, { historyFrom: from.toDate(),
                                               historyTo: to.toDate() }));

        dispatch(loadedVesselHistory(active, { historyFrom: from.toDate(),
                                               historyTo: to.toDate() }));
        dispatch(loadingVesselHistory(active));

        Parse.Cloud.run('getVesselHistory', {
            vesselId: active.get('pkId'),
            from: toISOString(from),
            to: toISOString(to),
            includeConsumers: true
        }).then(data => {
            dispatch(loadedVesselHistory(active, { consumer: data.consumerData,
                                                   history: data.vesselData}));
        })
            .catch(e => dispatch(addErrorMessage({txt: `There was an error loading the ${active.get("name")}'s data.`, colour: "danger"})));
    }
};

export const loadFuelConsumption = (path) => (dispatch, getState) => {
    const { vessels, routing } = getState();
    const fullPath = path || routing.location.pathname || '';

    if (fullPath.indexOf('fuelConsumption') !== -1 ) vessels.activeVessels.map(vessel => Parse.Cloud.run('getFuelConsumptionReport2', {
        vesselId: vessel.get('pkId'),
        from: toISOString(vessel.get('from') || vessels.from),
        to: toISOString(moment.min(moment(vessel.get('lastReception')), moment(vessel.get('to') || vessels.to))),
    }).then(data => dispatch(loadedFuelConsumption(data))).catch(e =>
                                                                 dispatch(addErrorMessage({txt: `There was an error loading the fuel consumption data for ${vessel.get("name")}'s data.`, colour: "danger"})))
                                                                              );
};

export const loadAllVessels = () => (dispatch, getState) => {
    var { accounts } = getState();
    var company = accounts.user.get("company");
    var query = new Parse.Query(Vessel);
    query.notEqualTo('deleted', true);
    query.equalTo('company', company);
    query.find()
        .then(vessels => Parse.Object.fetchAllIfNeeded(vessels))
        .then(vessels => dispatch(loadedVessels(vessels)))
        .catch(e => dispatch(addErrorMessage({txt: `There was an error loading the Vessel data.`} )));
};
