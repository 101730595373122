
import React from "react";
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import _ from 'lodash';
import humanizeDuration from 'humanize-duration';

import { round } from '../../../config/sparkLineChartOptions.js';

import { Row, Col, Badge, Card, CardHeader, Table } from "reactstrap";

const getBadge = (hours) => {
  const time = hours ? humanizeDuration(_.round(hours * 60 * 60) * 1000, { largest: 2, round: true }) : "Never";
  return <Badge color={getColor(hours)}>{time}</Badge>;
};

const getColor = (hours) => {
  if (!hours) return "danger";
  if (hours < 6) return "success";
  if (hours < 24) return "warning";
  return "danger";
};

const renderVessel = (vessel, dispatch) => (
  <tr key={vessel.vesselId} onClick={() => dispatch(push('./vessels/' + vessel.vesselId))}>
    <td>{vessel.vesselName}</td>
    <td>{vessel.vesselId}</td>
    <td>{round(vessel.consumption, 1)}</td>
    <td>{round(vessel.avgConsumption, 1)}</td>
    <td>{round(vessel.distance, 1)}</td>
    <td>{round(vessel.avgSpeed, 1)}</td>
    <td>{round(vessel.performance, 1)}</td>
    <td>{getBadge(vessel.receptionHours)}</td>
  </tr>
);

const VesselTable = ({ vessels, dispatch }) => (
  <Row>
    <Col xs="12">
      <Card>
        <CardHeader>
          Fleet
        </CardHeader>
        <Table id="VesselsTables" responsive striped>
          <thead>
            <tr>
              <th>Vessel</th>
              <th>IMO</th>
              <th>Consumption [MT]</th>
              <th>Avg. Consumption [kg/h]</th>
              <th>Distance [nmi]</th>
              <th>Avg. Speed [kn]</th>
              <th>Performance [kg/nmi]</th>
              <th>Last reception</th>
            </tr>
          </thead>
          <tbody>
            {(vessels || []).map(v => renderVessel(v, dispatch))}
          </tbody>
        </Table>
      </Card>
    </Col>
  </Row>);

export default connect((state, dispatch) =>
  ({ vessels: state.fleet.fleet.vessels, dispatch }))(VesselTable);
