import React, { Component } from "react";
import {
  Form,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import { connect } from "react-redux";

import { makeRequest, updateProfile } from "../../../actions/account";

const Profile = ({ name, address, phone, company, updated, dispatch }) => (
  <div className="app flex-row align-items-center">
    <Container>
      <Row className="justify-content-center">
        <Card className="mx-4">
          <CardHeader>Access requested</CardHeader>
          <CardBody className="card-body p-5">
            <h2 className="success">
              <i className="icon-check"></i>
            </h2>
            <p>
              KROHNE Marine has received your request. <br />
              Will be in contact as soon as your account is ready.
            </p>
          </CardBody>
        </Card>
      </Row>
    </Container>
  </div>
);

export default connect((state) => ({
  name: state.accounts.user.get("RQName"),
  address: state.accounts.user.get("RQAddress"),
  phone: state.accounts.user.get("RQPhone"),
  saving: state.accounts.saving,
  updated: state.accounts.updated,
  company: state.accounts.user.get("company"),
}))(Profile);
