// jshint esversion:6

import _ from 'lodash';
import { push, goBack } from 'react-router-redux';

export const toggleVessel = ( pkId ) => (dispatch, getState) => {
  const { routing } = getState();
  var fullPath = routing.location.pathname || '';
  const vesselsPath = fullPath.indexOf('vessel') > -1;
  const active = fullPath.indexOf(pkId) > -1;
  if (active) fullPath = fullPath.replace('/' + pkId, '');
  else if (vesselsPath) fullPath = fullPath.replace('vessel/', 'vessels/') + '/' + pkId;
  else fullPath = 'vessels/' + pkId;
  fullPath = (fullPath.length > 8) ? fullPath : "/";
  dispatch(push(fullPath));
};

export const toggleShowAllSidebar = (show) => {
    return {
        type: 'SIDEBAR_SHOWALL',
        show
    };
};

export const vesselsURL = (path = "", vessel, page = "") => {
    return push(makeURL(path, vessel, page));
}

export const makeURL = (path = "", vessel, page = "") => {
    return _.first(path.split('vessels')) + 'vessels/' + vessel.get("pkId") + "/" + page;
}

export const goback = () => {
    return goBack();
}
