import React from "react";
import { connect } from 'react-redux';
import { Line } from "react-chartjs-2";
import moment from 'moment';
import _ from 'lodash';
import { color, backgroundColor, round } from '../../../config/sparkLineChartOptions.js';

export const sparklineChartOpts = {
  responsive: true,
  maintainAspectRatio: true,
  tooltips: {
    intersect: false,
    mode: 'label',
    callbacks: {
      label: ({ yLabel }) => {
        return round(yLabel) + ' kg/h';
      }
    }
  },
  scales: {
    xAxes: [{
      display: true
    }],
    yAxes: [
      {
        type: 'linear',
        display: true,
        position: 'left',
        gridLines: {
          display: true
        },
        labels: {
          show: true
        },
        scaleLabel: {
          display: true,
          labelString: 'Consumption [kg/hour]'
        }
      }]
  },
  elements: {
    line: {
      borderWidth: 2
    },
    point: {
      radius: 0,
      hitRadius: 10,
      hoverRadius: 4,
      hoverBorderWidth: 3
    }
  },
  legend: {
    display: true
  }
};

const LineGraph = ({ data }) => (
  <div>
    <Line data={data} options={sparklineChartOpts} height={40} legend={{ display: false }} />
  </div>
);

export default connect(() => ({ vessels }) => {
  const consumptionTrend = vessels.fuelConsumption.trend || [];
  return {
    data: () => ({
      labels: consumptionTrend.map(m => moment(m.time).format('DD MMM HH:mm')),
      datasets: [
        {
          label: '[kg/h]',
          data: consumptionTrend.map(measurement => measurement.value),
          backgroundColor: backgroundColor,
          borderColor: color
        }
      ]
    })
  };
})(LineGraph);
