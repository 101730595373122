import React  from "react";
import { connect } from 'react-redux';

import { Modal, ModalHeader, ModalBody, ModalFooter,
         UncontrolledTooltip } from 'reactstrap';
import { Button, Form, FormGroup, Input, Col, Label } from 'reactstrap';
import Spinner from 'react-spinkit';
import { toggleVesselForm, updateVesselOption, saveVesselOptions } from '../../../actions/vesselGroups.js';



const GroupForm = ({vessel, saving, groups, vesselOption, groupItems, showForm, dispatch}) => vessel && (
    <Modal size="lg" isOpen={showForm} toggle={() => dispatch(toggleVesselForm(false))}>
        <ModalHeader toggle={() => dispatch(toggleVesselForm(false))}>
            {vessel.get('name')}
        </ModalHeader>
        <ModalBody >
            <Form className="vesselGroups" onSubmit={e => { e.preventDefault(); dispatch(saveVesselOptions())}}>
                {groups.map(group => (
                    <FormGroup key={group._getId()} row>
                        <Label for={'SelectGroupOption' +  group._getId()} sm={2}>{group.get('name')}</Label>
                        <Col sm={10}>
                            <Input
                                onChange={e => dispatch(updateVesselOption(group._getId(), e.target.options[e.target.selectedIndex].value))}
                                id={'SelectGroupOption' +  group._getId()}
                                value={vesselOption[group._getId()] ? vesselOption[group._getId()]._getId() : ''}
                                type="select">
                                <option>Select</option>
                                {(groupItems[group._getId()] || []).map(item =>
                                    (<option value={item._getId()} key={item._getId()} >{item.get('name')}</option>))}
                            </Input>
                        </Col>
                    </FormGroup>
                ))}
            </Form>
        </ModalBody>
        <ModalFooter>
            <Button  id="CancelVesselOptions" color="light" onClick={() => dispatch(toggleVesselForm(false))} >Cancel</Button>
            <Button
                disabled={saving}
                id="SaveVesselOptions"
                color="primary"
                onClick={e => { e.preventDefault(); dispatch(saveVesselOptions()) }} >
                {saving ? (<Spinner className="ref" color="#FFFFFF" name='chasing-dots' />)
                 : "Save changes"}

            </Button>
            {!!showForm && (
                 <span>
                     <UncontrolledTooltip delay={{show:500, hide:250}} target="CancelVesselOptions">
                         Revert all changes
                     </UncontrolledTooltip>
                     <UncontrolledTooltip delay={{show:500, hide:250}} target="SaveVesselOptions">
                         Save {vessel.get('name')}
                     </UncontrolledTooltip>
                 </span>
            )}
        </ModalFooter>
    </Modal>
);

export default connect(() => ({ vesselGroups }) => {
    return ({
        vessel: vesselGroups.vessel,
        vesselOption: vesselGroups.vesselOption,
        saving: vesselGroups.vesselSaving,
        groups: vesselGroups.groups,
        groupItems: vesselGroups.groupItems,
        showForm: vesselGroups.vesselForm });
})(GroupForm);
