import React from "react";
import { connect } from 'react-redux';
import SetDateRange from '../../Components/SetDateRange/SetDateRange.js'

import moment from 'moment';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter,  Row, Col, Badge, UncontrolledTooltip} from 'reactstrap';
import {setDateMonth, setDateWeek, setDateDay, showAllRange, setRange} from '../../../actions/vessels.js';
import _ from 'lodash';
import ReactGA from 'react-ga';

const SetAllVesselDays = ({vessels, to, from, showTheRange, dispatch}) => {
  return (
      <div className="datePicker">

          <SetDateRange
              startDate={from}
              endDate={to}
              showRange={showAllRange}
              showTheRange={showTheRange}
              vessels={vessels}
              setRange={setRange}
              setDateDay={setDateDay}
              setDateWeek={setDateWeek}
              setDateMonth={setDateMonth}
          ></SetDateRange>

          <UncontrolledTooltip delay={{show:500, hide:250}} target="SetAllToRange">
              Sets a custom time range for all displayed vessels</UncontrolledTooltip>
          <span className="spans vesselDetails">
              <a
                  id="SetAllToRange"
                  href="#"
                  onClick={e => {
                          e.preventDefault();
                          ReactGA.event({
                            category: 'Setting',
                            action: 'Set time range from overview, all'
                          })
                          dispatch(showAllRange(!showTheRange));
                  }}>Set interval</a>
          </span>
      </div>
  );
};

export default connect(() => ({vessels}) =>  {
  return ({ vessels: vessels.activeVessels,
            to: _.first(vessels.activeVessels).get('to') || vessels.to,
            showTheRange: vessels.showRange,
            from: _.first(vessels.activeVessels).get('from') || vessels.from});
})(SetAllVesselDays);
