// jshint esversion:6
import _ from 'lodash';
import { SORT_ASC, SORT_DESC, DEFAULT_SORT_KEY } from './allBunkerItems.js';

export const PORT_KEY = 'port';
export const VESSEL_KEY = 'vessel';
export const SUPPLIER_KEY = 'supplier';
export const PRODUCT_KEY = 'product';

const defaultState = { loading: false, bunkerItems: [], page: 0, limit: 15, count: 0, searchkeys: { port: '', supplier: '', product: '', vessel: '' }, sortKey: DEFAULT_SORT_KEY, sortOrder: SORT_DESC, keyword: '' };

export default (state = defaultState, action = {}) => {
    switch (action.type) {
        case 'ACCOUNT_LOGOUT':
            return { ...defaultState };
        case 'SEARCH_LOADING':
            return { ...state, loading: true };
        case 'SEARCH_LOADED':
            return { ...state, loading: false, bunkerItems: action.searchResult };
        case 'SEARCH_PAGE':
            return { ...state, page: action.page };
        case 'SEARCH_COUNT':
            return { ...state, count: action.count };
        case 'SEARCH_KEYWORDS':
            let temp = state.searchkeys;
            temp[action.key] = action.keyword;
            return { ...state, searchkeys: temp };
        case 'SEARCH_SORT':
            let sortKey = action.sortKey || DEFAULT_SORT_KEY;
            if (sortKey === state.sortKey)
                (state.sortOrder === SORT_DESC) ?
                    state.sortOrder = SORT_ASC :
                    state.sortOrder = SORT_DESC;
            return { ...state, sortKey: sortKey, sortOrder: state.sortOrder };
        default: return state;
    }
}
