import FileSaver from 'file-saver';
import _ from 'lodash';

const defaultObject = { Timestamp: 0,
                        'Position Latitude': 0,
                        'Position Longitude': 0,
                        'Speed': 0,
                        'Distance': 0,
                        'Heading': 0,
                        'Momentary consumption': 0,
                        'Total consumption': 0,
                        'Mode': 0
};
export const saveFile = (vessel, separator, filetype) => () => {
  const history = vessel.get('history');
  const consumer = vessel.get('consumer');

  var out = history.reduce((all, h) => {
    const pointer = all[h.timestamp] || {...defaultObject, Timestamp: h.timestamp };
    pointer['Position Latitude'] = _.round(Number(_.first(h.position)), 3) || '';
    pointer['Position Longitude'] = _.round(_.last(h.position), 3) || '';
    pointer.Speed = _.round(h.speed, 2) ;
    pointer.Distance = _.round(h.distance, 2);
    pointer.Heading = _.round(h.heading, 2) ;
    pointer["Momentary consumption"] = _.round(h.momentaryConsumption, 2) || '';
    pointer["Total consumption"] = _.round(h.totalConsumption, 2) || '';
    pointer.Mode = h.mode;
    all[h.timestamp] = pointer;
    return all;
  }, {});

  out = consumer.reduce((all, type) => {
    const id = type.Name;
    return type.Data.reduce((all, row) => {
      const pointer = all[row.timestamp] || {...defaultObject, Timestamp: row.timestamp };
      pointer[`${id} Type`] = type.Type ;
      pointer[`${id} Consumption`] = _.round(row.consumption, 2) || '';
      pointer[`${id} Total Consumption`] = _.round(row.totalConsumption, 2) || '';
      pointer[`${id} RPM`] = _.round(row.rpm, 2) || '';
        pointer[`${id} Pitch`] = _.round(row.pitch, 2) || '';
      all[row.timestamp] = pointer;
      return all;
    }, all)
  }, out);

  const keys = _.keys(out);
  var csv = [_.join(_.keys(out[_.first(keys)]), separator)];
  csv = keys.reduce((all, key) => {
    all.push(_.join(_.values(out[key]), separator));
    return all;
  }, csv);

  var blob = new Blob([_.join(csv, "\n")], {type: "text/" + filetype +  ";charset=utf-8"});
  FileSaver.saveAs(blob, vessel.get('name') + "." + filetype);
};
