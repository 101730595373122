
import { Marker } from 'react-leaflet';
import 'leaflet-rotatedmarker';
import L from 'leaflet';
import { Marker as LeafMarker } from 'leaflet';

export default class BoatMarker extends Marker {
  createLeafletElement(props) {
    const options = {
			color: props.color,
      idleCircle: true,
      rotationAngle: props.last.heading,
      rotationOrigin: 'center center',
      speed: props.last.speed,
      iconSize: new L.Point(30, 30),
      iconUrl: "/img/boat.svg"
		};
    options.icon =  new L.Icon(options);
    return new LeafMarker(props.last.position, options);
  }

  updateLeafletElement(fromProps, toProps) {
    if (toProps.last.heading !== fromProps.last.heading) this.leafletElement.setRotationAngle(toProps.last.heading);
    super.updateLeafletElement(fromProps, toProps);
  }
}
