// jshint esversion:6

import Parse from '../parse';
import moment from 'moment';
import _ from 'lodash';
import {loadAllLogs} from './vesselLogs.js';
import {loadBunkerItems, loadBunkerCount} from './allBunkerItems.js';

var fleetCache = {};
var fleetHistoryCache = {};

export const loadedHistory = (vessel, history) => {
    return {
        type: 'FLEET_LOADED_HISTORY',
        history,
        vessel
    };
};

export const loaded = (fleet) => {
    return {
        type: 'FLEET_LOADED',
        fleet
    };
};

export const setRangeToFrom = (from, to) => {
    return {
        type: 'FLEET_RANGE',
        to,
        from
    };
};

export const setRangeFrom = (from) => {
    return {
        type: 'FLEET_RANGE_FROM',
        from
    };
};

export const setRangeTo = (to) => {
    return {
        type: 'FLEET_RANGE_TO',
        to
    };
};

export const showRange = (show) => {
    return {
        type: 'FLEET_SELECT_RANGE',
        show
    };
};

export const setDay = () => {
    return {
        type: 'FLEET_DAY'
    };
};

export const setWeek = () => {
    return {
        type: 'FLEET_WEEK'
    };
};

export const setMonth = () => {
    return {
        type: 'FLEET_MONTH'
    };
};
export const updateBounds = () => {
    return {
        type: 'FLEET_UPDATE_BOUNDS'
    };
};


export const setToDay = () => (dispatch) => {
    dispatch(setDay());
    dispatch(loadFleet());
};

export const setToWeek = () => (dispatch) => {
    dispatch(setWeek());
    dispatch(loadFleet());
};

export const setToMonth = () => (dispatch) => {
    dispatch(setMonth());
    dispatch(loadFleet());
};

export const setRange = (from, to) => (dispatch) => {
    if (from == null) dispatch(setRangeTo(to));
    else if (to == null) dispatch(setRangeFrom(from));
    else dispatch(setRangeToFrom(from, to));
    dispatch(loadFleet());
};

const withoutTime = (td) => {
    return moment(td)
        .toISOString()
        .split("T")[0] + "T00:00:00.000Z";
}

export const isFleetURL = (path) => {
    return (path === "/" || path === "" || path.indexOf("group") === 1);
}

const groupPathToGroupArray = (path = '') => {
    const pathLessGroup = path.replace('/group', '');
    if (pathLessGroup === '/' || pathLessGroup === '') return ['all'];
    return _.compact(pathLessGroup.split('/'));
}

const getVessels = (path, getState) => {
    const { vessels, vesselGroups } = getState();
    const groupNamePointer = groupPathToGroupArray(path);
    const groupId = _.first(groupNamePointer);
    const choice = _.last(groupNamePointer);

    if (groupId === 'all') return vessels.vessels;

    const group = vesselGroups.groupedVessels.find(level1 => level1.group.get('name') === groupId);

    const items = group ? group.items : [];
    if (groupNamePointer.length === 1 ) {
        return _.flatten(items.map(item => item.vessels));
    }
    return _.flatten(items.filter(item => item.item.get('name') === choice).map(item => item.vessels));

}

export const loadFleet = (path) => (dispatch, getState) => {
    const { fleet, routing } = getState();
    const fullPath = path || routing.location.pathname || '';

    if (!isFleetURL(fullPath)) return null;
    const vessels = getVessels(fullPath, getState);
    if (vessels.length) {

        
        const ids = vessels.map(v => v.get('pkId'));
        const to = withoutTime(fleet.to);
        const from = withoutTime(fleet.from);
        
        const key = _.join(ids, "_") + from + to;
        
        if (fleetCache[key]) {
            dispatch(loaded(fleetCache[key]));
        } else {
            Parse.Cloud.run('getFleetData2', {
                vesselIds: ids,
                from: from,
                to: to
            }).then(data => {
                fleetCache[key] = data;
                dispatch(loaded(data));
            });
        }
        
        dispatch(loadBunkerCount());
        dispatch(loadBunkerItems());
        dispatch(loadHistory());
        dispatch(loadAllLogs(vessels));
    }
};


export const clearFleetCache = ( ) => () => {
    fleetCache = {};
    fleetHistoryCache = {};
};


export const loadHistory = ( ) => (dispatch, getState) => {
    const { fleet, routing } = getState();

    const fullPath = routing.location.pathname || '';
    const vessels = getVessels(fullPath, getState);

    Promise.all(vessels.map(v => {
        const to = withoutTime(fleet.to);
        const from = withoutTime(fleet.from);
        const key = v.get('pkId') + from + to;

        if (fleetHistoryCache[key]) {
            dispatch(loadedHistory(v, fleetHistoryCache[key]));
            return dispatch(updateBounds());
        }

        return Parse.Cloud.run('getVesselHistory', {
            vesselId: v.get('pkId'),
            from: from,
            to: to
        }).then(data => {
            fleetHistoryCache[key] = data.vesselData;
            dispatch(loadedHistory(v, data.vesselData));
        });
    })).then(e => {
        dispatch(updateBounds());
    });
};
