
export const round = (num, decimals) => {
  return (typeof num === 'number') ? num.toFixed(decimals || 1) : "---";
}
export const NM = 1 / 1852;
export const color = "#005DA8";
export const backgroundColor = "#F4FAFF";

export const sparklineChartOpts = {
  responsive: true,
  tooltips: {
    intersect: false,
    mode: 'label',
    callbacks: {
      label: ({yLabel}) => {
        return round(yLabel, 2);
      }
    }
  },
  maintainAspectRatio: true,
  scales: {
    xAxes: [{
      display: true
    }],
    yAxes: [{
      display: true
    }]
  },
  elements: {
    line: {
      borderWidth: 2
    },
    point: {
      radius: 0,
      hitRadius: 10,
      hoverRadius: 4,
      hoverBorderWidth: 3
    }
  },
  legend: {
    display: true
  }
};


export const barOpts = {
  maintainAspectRatio: true,
  tooltips: {
    intersect: false,
    mode: 'label'
  },
  legend: {
    display: true
  },
  scales: {
    xAxes: [{
      stacked: true,
      display: true,
    }],
    yAxes: [{
      stacked: true,
      display: true,
    }]
  }
}


export const horizontalBar = {
  maintainAspectRatio: true,
  tooltips: {
    intersect: true,
    mode: 'label',
    callbacks: {
      label: (tooltipItems, data) => {
        var dataset = data.datasets[tooltipItems.datasetIndex].label;
        return dataset + ': ' + round(tooltipItems.xLabel, 1) + ' MT';
      }
    }
  },
  legend: {
    display: true
  },
  scales: {
    xAxes: [{
      stacked: true,
      display: true,
    }],
    yAxes: [{
      stacked: true,
      display: true,
    }]
  }
}
