import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  CardGroup,
  Card,
  CardBody,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  Alert,
  Form,
} from "reactstrap";
import { startLogin } from "../../../actions/account";

const getErrorMessage = (error) => {
  return !error ? null : <Alert color="danger">{error.message}</Alert>;
};

const Login = ({ accounts, dispatch }) => (
  <Form
    onSubmit={(e) => {
      e.preventDefault();
      dispatch(
        startLogin(
          document.getElementById("LoginPageUsername").value,
          document.getElementById("LoginPagePassword").value
        )
      );
    }}
    className="app flex-row align-items-center"
  >
    <div className="app flex-row align-items-center login-background">
      <Container>
        <Row className="justify-content-center">
          <Col md="6">
            <CardGroup className="mb-0">
              <Card className="p-4">
                <CardBody className="card-body">
                  <h1>Login</h1>
                  <p className="text-muted">Sign In to your account</p>
                  {getErrorMessage(accounts.loggingInError)}
                  <InputGroup className="mb-3">
                    <InputGroupAddon addonType="prepend">
                      <i className="icon-user input-group-text"></i>
                    </InputGroupAddon>
                    <Input
                      type="text"
                      autoFocus
                      id="LoginPageUsername"
                      placeholder="Username"
                    />
                  </InputGroup>
                  <InputGroup className="mb-4">
                    <InputGroupAddon addonType="prepend">
                      <i className="icon-lock input-group-text"></i>
                    </InputGroupAddon>
                    <Input
                      type="password"
                      id="LoginPagePassword"
                      placeholder="Password"
                    />
                  </InputGroup>
                  <Row>
                    <Col m="12" md="6">
                      <Button color="primary" className="px-4">
                        Login
                      </Button>
                    </Col>

                    <Col m="12" md="6" className="p-2">
                      <a href="/forgot_password">Forgot password?</a>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  </Form>
);

export default connect((state) => ({ accounts: state.accounts }))(Login);
