// jshint esversion:6
import _ from 'lodash';

const defaultState = {  points: [], showForm: false, point: {get: () => null }};

const updatePoint = (action, point) => {
  const updatedPoint = _.clone(point);
  point.set(action.key, action.value);
  return updatedPoint;
}

const removePoint = (point, points) => {
  return points.filter(p => (p.id || "") !== point.id || "");
}

export default (state = defaultState, action = {}) => {
  switch (action.type) {
  case 'POI_LOADING':
    return {...defaultState, loading: true };
  case 'POI_LOADED':
    return { ...state, points: action.points };
  case 'POI_ADD':
    return { ...state, points: [...state.points, action.point] };
  case 'POI_SAVE':
    return { ...state, points: [...removePoint(state.point, state.points), action.point], showForm: false };
  case 'POI_REMOVE':
    return { ...state, points: removePoint(state.point, state.points), showForm: false };
  case 'POI_UPDATE':
    return { ...state, point: updatePoint(action, state.point)};
  case 'POI_SHOWFORM':
    return { ...state, showForm: action.showForm };
  case 'POI_EDIT':
    return { ...state, point: action.point, showForm: true };
  default:
    return state;
  }
};
