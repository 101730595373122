import React from "react";
import { connect } from 'react-redux';
import { Bar } from "react-chartjs-2";
import moment from 'moment';
import _ from 'lodash';
import tinycolor from 'tinycolor2';
import { round } from '../../../config/sparkLineChartOptions.js';

import {
  Row,
  Col,
  Badge
} from "reactstrap";

const barOpts = {
  responsive: true,
  maintainAspectRatio: true,

  scales: {
    xAxes: [{
      stacked: true,
      scaleLabel: {
        display: true,
        labelString: 'Days'
      }
    }],
    yAxes: [{
      stacked: true,
      scaleLabel: {
        display: true,
        labelString: 'Fuel consumption [MT/day]'
      }
    }]
  },
  legend: {
    display: false
  },
  tooltips: {
    callbacks: {
      label: ({yLabel}) => {
        return round(yLabel, 2) + " MT/day";
      },

      title: (items, {datasets}) => {
        return _.join(items.map(({datasetIndex, index}) => datasets[datasetIndex].label
                                                       + ' '
                                                       + moment(datasets[datasetIndex].consumerData[index].timestamp).format('DD MMM YYYY')))
      }
    }
  },
};
const FuelConsumptionCard = ({ data, consumer }) => (
  <div>
    <Row className="trendKey">
      {consumer.map((t, i) => (
        <Col className="consumption" xs="12" lg="6"  xl="4" key={i}>
          <span className="ref" style={{backgroundColor: t.colour}}></span>
          <span>{t.name}</span> <br />
          <small>Total: {round(_.sum(t.data.map(d => d.mainEngine + (d.auxiliaryEngine || 0)))/1000,1) || "-"} [MT]<br />
            Average: {round(_.mean(t.data.map(d => d.mainEngine + (d.auxiliaryEngine || 0)))/1000,1) || "-"} [MT/day]</small>
        </Col>
      ))}
    </Row>
    <Bar data={data} options={barOpts} width={100} height={30}/>
  </div>
);

export default connect(() => ({ vessels }) => {
  const start = _.minBy(vessels.consumer, c => c.start);
  const end =  _.maxBy(vessels.consumer, c => c.end);
  const days = Math.max(...vessels.consumer.map(c=>_.ceil(moment(c.end).diff(moment(c.start), 'days'))));

  return {
    consumer: vessels.consumer,
    data: () =>  ({
      labels: _.range(1, days + 3),
      datasets: (_.flatten(vessels.consumer.map(c => ([{
        stack: c.name,
        label: c.name + ' Main Engine',
        fill: false,
        consumerData: c.data,
        data: c.data.map(d => d.mainEngine / 1000),
        borderColor: tinycolor(c.colour).lighten(10).toString(),
        backgroundColor: c.colour,
      }, {
        stack: c.name,
        label: c.name  + ' Auxiliary',
        fill: false,
        consumerData: c.data,
        data: c.data.map(d => d.auxiliaryEngine / 1000),
        borderColor: tinycolor(c.colour).darken(20).toString(),
        backgroundColor: tinycolor(c.colour).darken(20).toString(),
      }]))))
    })};
})(FuelConsumptionCard);
