import React, { Component } from "react";
import { startLogout } from "../../../actions/account";
import { connect } from "react-redux";
import _ from "lodash";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Nav,
  NavItem,
  NavbarToggler,
  UncontrolledTooltip,
  DropdownToggle,
} from "reactstrap";

import { AppHeader } from "@coreui/react";

class Header extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this);

    this.state = {
      dropdownOpen: false,
      sideOpen: false,
    };
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  sidebarToggle() {
    document.body.classList.toggle("sidebar-hidden");
  }

  sidebarMinimize() {
    document.body.classList.toggle("sidebar-minimized");
  }

  mobileSidebarToggle() {
    if (document.body.classList.contains("sidebar-show"))
      document.body.classList.remove("sidebar-show");
    else document.body.classList.add("sidebar-show");

    this.setState({
      sideOpen: !this.state.sideOpen,
    });
  }

  adminLink() {
    return this.props.admin ? (
      <DropdownItem>
        <Link to="/users">
          <i className="fa fa-users"></i> Users
        </Link>
      </DropdownItem>
    ) : null;
  }

  organiseLink() {
    return this.props.admin ? (
      <DropdownItem>
        <Link to="/organise">
          <i className="fa fa-indent"></i> Organise
        </Link>{" "}
      </DropdownItem>
    ) : null;
  }

  render() {
    if (this.props.user) {
      return (
        <AppHeader fixed={true} className="app-header navbar">
          <NavbarToggler
            onClick={this.mobileSidebarToggle}
            className={
              "d-xl-none hamburger hamburger--squeeze" +
              (this.state.sideOpen ? " is-active" : "")
            }
            type="button"
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </NavbarToggler>

          <Link to="/" className="navbar-brand"></Link>

          <span className="name">EcoMATE™</span>

          <Nav className="ml-auto" navbar>
            <NavItem className="success">
              <Link to="/request_access">
                Update <i className="icon-badge "></i>
              </Link>
            </NavItem>
            <UncontrolledTooltip
              delay={{ show: 500, hide: 250 }}
              target="helplink"
            >
              Open Manual
            </UncontrolledTooltip>
            <NavItem id="helplink">
              <a href="/docs/manual.pdf" target="_blank">
                <i className="fa fa-question"></i>
              </a>
            </NavItem>
            <NavItem>
              <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                <DropdownToggle className="nav-link dropdown-toggle">
                  <i className="fa fa-cogs"></i>{" "}
                </DropdownToggle>
                <DropdownMenu
                  right
                  className={this.state.dropdownOpen ? "show" : ""}
                >
                  <DropdownItem header>
                    {_.capitalize(this.props.user ? this.props.username : "")}
                  </DropdownItem>
                  <DropdownItem>
                    <Link to="/profile">
                      <i className="fa fa-address-card"></i> Profile
                    </Link>{" "}
                  </DropdownItem>
                  {this.adminLink()}
                  <DropdownItem>
                    <Link to="/fuelprices">
                      <i className="fa fa-line-chart"></i> Fuel prices
                    </Link>{" "}
                  </DropdownItem>
                  {this.organiseLink()}
                  <DropdownItem
                    onClick={(e) => this.props.dispatch(startLogout(e))}
                  >
                    <Link to="/login">
                      <i className="fa fa-lock"></i> Logout
                    </Link>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </NavItem>
          </Nav>
        </AppHeader>
      );
    }

    return null;
  }
}

Header.propTypes = {
  admin: PropTypes.bool,
  dispatch: PropTypes.func,
  username: PropTypes.string,
  user: PropTypes.object,
};

export default connect((state) => ({
  user: state.accounts.user,
  admin: state.accounts.admin,
  username: state.accounts.user ? state.accounts.user.get("username") : "",
}))(Header);
