// jshint esversion:6

import Parse from '../parse';
import _ from 'lodash';

const Notification = Parse.Object.extend("Notification");

var logsCache = {};
var logsCountCache = {};

export const loading = () => {
  return {
    type: 'VESSELLOGS_LOADING'
  };
};

export const loaded = (items) => {
  return {
    type: 'VESSELLOGS_LOADED',
    items
  };
};

export const loadedLogsCount = (count) => {
  return {
    type: 'VESSELLOGS_COUNT',
    count
  };
};

export const setPage = (page) => {
  return {
    type: 'VESSELLOGS_PAGE',
    page
  };
};

export const clearLogsCache = ( ) => () => {
  logsCache = {};
  logsCountCache = {};
};


export const loadPage = (page, path) => (dispatch) => {
  dispatch(setPage(page));
  dispatch(loadLogs(path));
};

export const loadLogsCount = () => (dispatch, getState) => {
  const { vessels } = getState();
  const activeVessels = vessels.activeVessels;

  const key = _.join(activeVessels.map(v => v.id), "_");
  if (logsCountCache[key]) dispatch(loadedLogsCount(logsCountCache[key]));
  else {
    const query = new Parse.Query(Notification);
    query.containedIn('vesselId', activeVessels.map(v => v.get('pkId')));
    query.count().then(count => {
      logsCountCache[key] = count;
      dispatch(loadedLogsCount(count));
    });
  }
};

const makeLogsQuery = (vessels, logs, limit, page) => {
  const query = new Parse.Query(Notification);
  query.containedIn('vesselId', vessels.map(v => v.get('pkId')));
  query.descending("timestamp");
  query.limit(limit);
  query.skip(limit * page);
  return query;
};

export const loadLogs = (path) => (dispatch, getState) => {
  const { vessels, routing, logs } = getState();
  const fullPath = path || routing.location.pathname || '';
  const activeVessels = vessels.activeVessels;
  if (fullPath.indexOf('logs') === -1 || !activeVessels) return null;
  const key = _.join(activeVessels.map(v => v.id), "_") + logs.limit + (logs.limit * logs.page);

  if (logsCache[key]) {
    dispatch(loaded(logsCache[key]));
  } else {
    makeLogsQuery(activeVessels, logs, logs.limit, logs.page)
      .find()
      .then(basicLogsItems => {
        Parse.Object.fetchAllIfNeeded(basicLogsItems).then(logs => {
          logsCache[key] = logs;
          dispatch(loaded(logs));
        });
      });
  }
};

export const loadAllLogs = (vessels) => (dispatch, getState) => {
  const { logs } = getState();
  dispatch(setPage(0));

    const key = _.join(vessels.map(v => v.id), "_") + logs.dashboardLimit;
  if (logsCache[key]) {
    dispatch(loaded(logsCache[key]));
  } else {
      makeLogsQuery(vessels, logs, logs.dashboardLimit, 0)
      .find()
      .then(basicLogsItems => {
        Parse.Object.fetchAllIfNeeded(basicLogsItems).then(logs => {
          logsCache[key] = logs;
          dispatch(loaded(logs));
        });
      });
  }
};
