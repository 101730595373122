// jshint esversion:6
import _ from 'lodash';

const defaultState = {
    pricePoints: [],
    page: 0,
    limit: 10,
    dashboardLimit: 10,
    count: 0,
    showForm: false,
    vesselFilter: false,
    categoryFilter: false,
    fuelCategories: ["DieselGasOil",
                     "LFO",
                     "HFO",
                     "LPG",
                     "LNG",
                     "Methanol",
                     "Ethanol"],
    pricePoint: {get: () => null }};

const updatePricePoint = (action, point) => {
    const updatedPoint = _.clone(point);
    updatedPoint.set(action.key, action.value);
    return updatedPoint;
};

const removePricePoint = (point, points) => {
    return points.filter(p => (p.id || "") !== point.id || "");
};

export default (state = defaultState, action = {}) => {
    switch (action.type) {
    case 'ACCOUNT_LOGOUT':
        return {...defaultState };
    case 'FUELPRICES_LOADING':
        return {...defaultState, loading: true };
    case 'FUELPRICES_LOADED':
        return { ...state, showForm: false, loading: false, pricePoints: action.pricePoints };
    case 'FUELPRICES_COUNT':
        return { ...state, count: action.count };
    case 'FUELPRICES_PAGE':
        return { ...state, page: action.page };
    case 'FUELPRICES_ADD':
        return { ...state, pricePoints: [...state.pricePoints, action.pricePoint] };
    case 'FUELPRICES_SAVE':
        return { ...state, pricePoints: [...removePricePoint(state.pricePoint, state.pricePoints), action.pricePoint], showForm: false };
    case 'FUELPRICES_REMOVE':
        return { ...state, pricePoints: removePricePoint(state.pricePoint, state.pricePoints), showForm: false };
    case 'FUELPRICES_UPDATE':
        return { ...state, pricePoint: updatePricePoint(action, state.pricePoint)};
    case 'FUELPRICES_SHOWFORM':
        return { ...state, showForm: action.showForm };
    case 'FUELPRICES_EDIT':
        return { ...state, pricePoint: action.pricePoint, showForm: true };
    case 'FUELPRICES_FILTER_VESSEL':
        return { ...state, vesselFilter: action.vessel};
    case 'FUELPRICES_FILTER_CATEGORY':
        return { ...state, categoryFilter: action.category};

    default:
        return state;
    }
};
