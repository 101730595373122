import React from "react";
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import Spinner from 'react-spinkit';

import {mainOptions, consumerOptions, colours} from '../../options.js';

import {
  CardDeck,
  CardBody,
  Card,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  NavbarBrand,
} from "reactstrap";

import { toggleVoyageDetails, setVoyageConsumerDetails } from '../../../actions/voyages.js';
import { Line } from 'react-chartjs-2';

const makeOptions = (id) => ((id > -1) ? consumerOptions : mainOptions);

const makeLineData = (consumerData, id) => ({
  labels: _.first(consumerData).Data.map(d => moment( d.timestamp).format('DD MMM HH:mm') ),
  datasets: makeDataSets(consumerData, id)
});


const makeDateSet = (data, label, colourId, yAxisID) => (
  {
    type:'line',
    label,
    fill: false,
    backgroundColor: colours[colourId],
    borderWidth: 2,
    borderColor: colours[colourId],
    pointBorderWidth: 0,
    pointBorderColor: colours[colourId],
    pointFillColor: colours[0],
    pointBackgroundColor: colours[colourId],
    pointHoverRadius: 5,
    pointHoverBackgroundColor: colours[colourId],
    pointHoverBorderColor: colours[colourId],
    pointHoverBorderWidth: 2,
    pointRadius: 0,
    pointHitRadius: 2,
    data,
    yAxisID
  }
)

const makeDataSets = (consumerData, id) => {

  if (id > -1) return [
    makeDateSet(consumerData[id].Data.map(d => d.consumption), "Consumption", 0, 'Main'),
    makeDateSet(consumerData[id].Data.map(d => d.rpm), "RPM", 3, 'Main'),
    makeDateSet(consumerData[id].Data.map(d => d.pitch || 0), "Pitch", 4, 'Small')
  ];
  return consumerData.map( (consumer, i) =>  makeDateSet(consumer.Data.map(d => d.consumption), consumer.Name.replace('ENGINE', ''), i, 'Main'));
}

const VoyageDetails = ({ vessel, names, showVoyageDetails, lineData, voyageConsumerDetails, options, dispatch }) => (
  <div className={showVoyageDetails ? "showVoyageDetails" : "hideVoyageDetails"}>
    <CardDeck>
      <Card>
        <button onClick={() => dispatch(toggleVoyageDetails(false))} type="button" className="close" aria-label="Close"><span aria-hidden="true">×</span></button>
        <CardBody>
          <Row>
            <Col>
              <Navbar className="voyageDetails">
                <NavbarBrand >{ vessel ?  (<h5>{vessel.get('name')}</h5>) : null }</NavbarBrand>
                {names && (<Nav navbar>
                  <NavItem key={-1} className={voyageConsumerDetails === -1 ? "active" : ""}>
                    <NavLink href="#" onClick={e => {
                        e.preventDefault();
                        dispatch(setVoyageConsumerDetails(-1));
                    }} >
                      All
                    </NavLink>
                  </NavItem>
                  {names.map((name, i) => (
                    <NavItem key={i} className={voyageConsumerDetails === i ? "active" : ""}>
                      <NavLink href="#" onClick={e => {
                          e.preventDefault();
                          dispatch(setVoyageConsumerDetails(i));
                      }}>
                        {name.replace('ENGINE', '')}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>)}
              </Navbar>
            </Col>
          </Row>
          <Row>
            <Col>
              {lineData ? (<Line key={voyageConsumerDetails} data={lineData} options={options} height={90} legend={{ position: 'right'}}></Line>)
               : (<div className="centerLoader">
                 <p>
                   Loading the vessel's consumption data.
                 </p>
                 <Spinner color="#005DA8" name='folding-cube' />
               </div>)}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </CardDeck>
  </div>
)

export default connect((state) => {

  return ({fleet: state.fleet.fleet,
           showVoyageDetails: state.voyages.showDetails,
           voyageDetails: state.voyages.voyageDetails,
           vessel: state.voyages.voyageDetails.vessel,
           voyageConsumerDetails: state.voyages.voyageConsumerDetails,
           options: makeOptions(state.voyages.voyageConsumerDetails),
           names: state.voyages.voyageDetails.data && state.voyages.voyageDetails.data.consumerData ? state.voyages.voyageDetails.data.consumerData.map(d => d.Name) : false,
           lineData: state.voyages.voyageDetails.data && state.voyages.voyageDetails.data.consumerData ?
                     makeLineData(state.voyages.voyageDetails.data.consumerData, state.voyages.voyageConsumerDetails) : false});
})(VoyageDetails);
