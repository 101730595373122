// jshint esversion:6

export const addErrorMessage = (message) => {
  return {
    type: 'ADD_ERROR_MESSAGE',
    message
  };
};

export const clearAddErrorMessage = (id) => {
  return {
    type: 'CLEAR_ERROR_MESSAGE',
    id
  };
};

export const clearAllErrorMessages = () => {
  return {
    type: 'CLEAR_ALL_ERROR_MESSAGE'
  };
};
