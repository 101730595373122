// jshint esversion:6

import _ from 'lodash';
import moment from 'moment';
import isValidPoint from '../gis/isValidPoint.js';

const defaultState = { span: 'week',
                       history: [],
                       bounds: [[-180, -180], [180, 180]],
                       fleet: {},
                       loaded: false,
                       selectRange: false,
                       to: moment().startOf('hour'),
                       from: moment().startOf('day').subtract(1, 'weeks') };


const getBounds = h => {
    const history = _.flatten(h.map(v => v.history)).filter(isValidPoint);
    const bounds =  (history && history.length) ? ([[_.minBy(history, o => o.position[0]).position[0], _.minBy(history, o => o.position[1]).position[1]],
                                                    [_.maxBy(history, o => o.position[0]).position[0], _.maxBy(history, o => o.position[1]).position[1]]]) : defaultState.bounds;
    return bounds;
};

const sort = (a, b) => {
    if (a.vessel.get('name') > b.vessel.get('name')) return 1;
    if (a.vessel.get('name') < b.vessel.get('name')) return -1;
    return 0;
};

export default (state = defaultState, action = {}) => {
    switch (action.type) {
    case 'ACCOUNT_LOGOUT':
        return {...defaultState };
    case 'FLEET_LOADED_HISTORY':
        var history = state.history.filter(h => h.vessel.get("pkId") !== action.vessel.get("pkId") );
        const filteredHistory = action.history.filter(h => h
                                                      && h.position
                                                      && _.isNumber(h.position[0])
                                                      && _.isNumber(h.position[1]));
        history.push({ history: filteredHistory, vessel: action.vessel });
        history.sort(sort);
        return { ...state, history};
    case 'FLEET_UPDATE_BOUNDS':
        return { ...state, bounds: getBounds(state.history) };
    case 'FLEET_LOADED':
        return { ...state, fleet: action.fleet, loaded: true };
    case 'FLEET_MONTH':
        return { ...state, from: moment().startOf('day').subtract(1, 'months'), to: moment().startOf('hour'), span: 'month'};
    case 'FLEET_DAY':
        return { ...state, from: moment().startOf('day').subtract(1, 'days'), to: moment().startOf('hour'), span: 'day'};
    case 'FLEET_WEEK':
        return { ...state, from: moment().startOf('day').subtract(1, 'weeks'), to: moment().startOf('hour'), span: 'week'};
    case 'FLEET_RANGE':
        return { ...state, from: action.from, to: action.to, span: 'range', selectFleetRange: false};
    case 'FLEET_RANGE_TO':
        return { ...state, to: action.to, span: 'range', selectFleetRange: false};
    case 'FLEET_RANGE_FROM':
        return { ...state, from: action.from, span: 'range', selectFleetRange: false};
    case 'FLEET_SELECT_RANGE':
        return { ...state, selectRange: action.show};
    default:
        return state;
    }
};
