import React, {Component} from "react";
import { connect } from 'react-redux';

import { round } from '../../../config/sparkLineChartOptions.js';

import {
  Card,
  CardBody,
} from "reactstrap";

const Distance = ({ fleet}) => (
  <Card>
    <CardBody className="card-body">
      <p>Total distance <small>[nmi]</small></p>
      <h2>{round(fleet.totalDistance)}</h2>
    </CardBody>
  </Card>
)
export default connect((state) => ({fleet: state.fleet.fleet }))(Distance);
