// jshint esversion:6

import Parse from '../parse';
import { loadFuelTypes } from './fuelTypes.js';
const Voyage = Parse.Object.extend("Voyage");
import moment from 'moment';
import _ from 'lodash';

const voyagesCache = {};
const voyageCountCache = {};


export const loading = () => {
  return {
    type: 'VOYAGES_LOADING'
  };
};

export const loaded = (voyages) => {
  return {
    type: 'VOYAGES_LOADED',
    voyages
  };
};

export const loadedVoage = (voyage) => {
  return {
    type: 'VOYAGE_LOADED',
    voyage
  };
};

export const setPage = (page) => {
  return {
    type: 'VOYAGE_PAGE',
    page
  };
};

export const loadedVoageCount = (count) => {
  return {
    type: 'VOYAGE_COUNT',
    count
  };
};

export const toggleVoyageDetails = (show) => {
  return {
    type: 'VOYAGE_SHOWVOYAGEDETAILS',
    show
  };
};

export const voyageDetails = (details) => {
  return {
    type: 'VOYAGE_DETAILS',
    details
  };
};

export const setVoyageConsumerDetails = (id) => {
  return {
    type: 'VOYAGE_CONSUMER_DETAILS',
    id
  };
};

export const loadPage = (page) => (dispatch) => {

  dispatch(setPage(page));
  dispatch(loadVoyages());
};

export const loadVoyageCount = () => (dispatch, getState) => {
  const { vessels } = getState();
  const activeVessels = vessels.activeVessels;

  const key = _.join(activeVessels.map(v => v.id), "_");
  if (voyageCountCache[key]) dispatch(loadedVoageCount(voyageCountCache[key]));
  else {
    const query = new Parse.Query(Voyage);
    query.containedIn('vessel', activeVessels);
    query.notEqualTo("deleted", true);
    query.notEqualTo("draft", true);
    query.count().then(count => {
      voyageCountCache[key] = count;
      dispatch(loadedVoageCount(count));
    });
  }
};

const makeVoyageQuery = (vessels, voyages) => {
  const query = new Parse.Query(Voyage);
  query.containedIn('vessel', vessels);
  query.descending("startTime");
  query.notEqualTo("deleted", true);
  query.notEqualTo("draft", true);
  query.limit(voyages.limit);
  query.skip(voyages.limit * voyages.page);
  return query;
};

export const loadVoyages = (path) => (dispatch, getState) => {

  const { vessels } = getState();
  const activeVessels = vessels.activeVessels;
  if (!activeVessels) return null;

  const { voyages } = getState();
  const key = _.join(activeVessels.map(v => v.id), "_") + voyages.limit + (voyages.limit * voyages.page);
  if (voyagesCache[key]) {
    dispatch(loaded(voyagesCache[key]));
    dispatch(loadVoyage(path));
  } else {
    makeVoyageQuery(activeVessels, voyages)
      .find()
      .then(basicVoyages => {
        Parse.Object.fetchAllIfNeeded(basicVoyages).then(voyages => {
          voyagesCache[key] = voyages;
          dispatch(loaded(voyages));
          dispatch(loadVoyage(path));
        });
      });
  }
}

export const loadVoyage = (path) => (dispatch, getState) => {
  const { routing, voyages } = getState();
  const fullPath = path || routing.location.pathname || '';
  const active = voyages.voyages.find(b => fullPath.indexOf(b.get('pkId')) !== -1);
  if (active) {
    dispatch(loadedVoage(active));
    dispatch(loadVoyagePrice(active));
    dispatch(loadFuelTypes(active));
  }
};

export const loadVoyagePrice = (voyage) => (dispatch) => {
  return Parse.Cloud.run('calculateVoyageCost', {
    vesselId: voyage.get('vesselId'),
    voyageId: voyage.get('pkId')
  }).then(data => {
    voyage.set('price', data.totalPrice);
    dispatch(loadedVoage(voyage));
  });
};

export const loadVoyageDetails = (vessel, points) => (dispatch, getState) => {
  const { voyages } = getState();
  const from = points.length > 1 ? moment(_.last(points).timestamp) : moment(_.first(points).timestamp).subtract(1, 'hours');
  const to =   points.length > 1 ? moment(_.first(points).timestamp) : moment(_.first(points).timestamp).add(1, 'hours');

  if (!voyages.voyageDetails.data
      || (!voyages.voyageDetails.to.isSame(to)
          || !voyages.voyageDetails.from.isSame(from)
          || points !== voyages.voyageDetails.points
          || vessel !== voyages.voyageDetails.vessel)) {
    dispatch(voyageDetails({}));
    return Parse.Cloud.run('getVesselHistory', {
      vesselId: vessel.get('pkId'),
        from: moment.min(from, to).toISOString(),
        to: moment.max(from, to).toISOString(),
      includeConsumers: true
    }).then(data => {
      dispatch(voyageDetails({data, vessel, points, from, to}));
    });
  }
};
