import React from "react";
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';

import moment from 'moment';

import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip
} from "reactstrap";

let endTempDate, startTempDate;

const SetDateRange = ({ endDate, startDate, vessels, setRange, showRange, showTheRange, setDateDay, setDateWeek, setDateMonth, dispatch }) =>  (
  <Modal
    isOpen={showTheRange}
    size='lg'
    toggle={() => vessels.map(vessel => dispatch(showRange(false, vessel)))}
    modalClassName="dateRangeDialog">
    <ModalHeader toggle={() => vessels.map(vessel => dispatch(showRange(false, vessel)))}>
        <span>Set interval for: {vessels.map(v => v.get ? v.get('name') : 'Fleet').join(', ')}</span>
    </ModalHeader>
    <ModalBody >
      <Row>
        <Col>
          <h6>
            <input name="startDate"
              type="datetime"
              className="form-control"
              value={(startTempDate) || moment(startDate).format('DD-M-YYYY HH:mm Z')}
              onChange={e => {
                startTempDate = e.target.value;
                var start = moment(e.target.value, 'DD-M-YYYY HH:mm Z');
                if (start.isValid()) {
                  start = moment.min(start, moment().startOf('hour'));
                  vessels.map(vessel => dispatch(setRange(start, moment(endDate), vessel)));
                }
              }}
              onBlur={() => { startTempDate = false }}
              />
          </h6>
          <DatePicker
            inline
            selected={moment(startDate)}
            selectsStart
            startDate={moment(startDate)}
            endDate={moment(endDate)}
            maxDate={moment(Date.now())}
            showTimeSelect
            timeFormat="HH:mm"
            onChange={start => {
                startTempDate = false;
                start = moment.min(moment(start), moment().startOf('hour'));
                let end = moment(endDate);
                vessels.map(vessel => dispatch(setRange(moment.min(start, end), moment.max(start, end), vessel)));
            }}
          />
        </Col>
        <Col>
          <h6>
            <input name="startDate"
              type="datetime"
              className="form-control"
              value={(endTempDate) || moment(endDate).format('DD-M-YYYY HH:mm Z')}
              onChange={e => {
                endTempDate = e.target.value;
                var end = moment(e.target.value, 'DD-M-YYYY HH:mm Z');
                if (end.isValid()) {
                  end = moment.min(end, moment().startOf('hour'));
                  vessels.map(vessel => dispatch(setRange(moment(startDate), end, vessel)));
                }
              }}
              onBlur={() => { endTempDate = false }}
              />
          </h6>
          <DatePicker
            inline
            selected={moment(endDate)}
            selectsEnd
            startDate={moment(startDate)}
            endDate={moment(endDate)}
            minDate={moment(startDate)}
            maxDate={moment(Date.now())}
            showTimeSelect
            timeFormat="HH:mm"
            onChange={end => {
                endTempDate = false;
                end = moment.min(moment(end), moment().startOf('hour'));
                let start = moment(startDate);
                vessels.map(vessel => dispatch(setRange(moment.min(start, end), moment.max(start, end), vessel)));
            }}
          />
        </Col>
      </Row>
    </ModalBody>
    <ModalFooter>
      <h6>
        <span>Set range to last: </span>
        <a
          id="SetAllToDayModal"
          href="#"
          onClick={e => {
              e.preventDefault();
              vessels.map(vessel => dispatch(setDateDay(vessel)));
          }}>Day</a>
        <span> | </span>
        <a
          id="SetAllToWeekModal"
          href="#"
          onClick={e => {
              e.preventDefault();
              vessels.map(vessel => dispatch(setDateWeek(vessel)));
          }}>Week</a>
        <span> | </span>
        <a
          id="SetAllToMonthModal"
          href="#"
          onClick={e => {
              e.preventDefault();
              vessels.map(vessel => dispatch(setDateMonth(vessel)));
          }}>Month</a>
      </h6>
      <UncontrolledTooltip delay={{show:500, hide:250}} target="SetAllToDayModal">
        Sets the time range to yesterday
      </UncontrolledTooltip>
      <UncontrolledTooltip delay={{show:500, hide:250}} target="SetAllToWeekModal">
        Sets the time range to last 7 days
      </UncontrolledTooltip>
      <UncontrolledTooltip delay={{show:500, hide:250}} target="SetAllToMonthModal">
        Sets the time range to last 30 days
      </UncontrolledTooltip>
    </ModalFooter>

  </Modal>

);

export default connect((state, dispatch) => ({ dispatch}))(SetDateRange);
