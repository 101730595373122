import React  from "react";

import { connect } from 'react-redux';

import { Modal, ModalHeader, ModalBody, ModalFooter, ButtonGroup,
         UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle, UncontrolledTooltip, FormFeedback } from 'reactstrap';
import { Button, Form, FormGroup, InputGroup, Input, InputGroupAddon, Col, Row, Label } from 'reactstrap';
import Spinner from 'react-spinkit';
import AskToDelete from './AskToDelete.js';

import { toggleForm, newVesselGroup, updateGroup, editGroup, addGroupItem, deleteGroupItem, updateGroupItem, saveGroups, showWarning } from '../../../actions/vesselGroups.js';



const GroupForm = ({groups, editing, saving, updated, groupItems, showForm, isValid, dispatch}) => (
    <div>
        <AskToDelete></AskToDelete>
        <Modal size="lg" isOpen={showForm} toggle={() => dispatch(toggleForm(false))}>
            <ModalHeader className="fullTile" toggle={() => dispatch(toggleForm(false))}>
                <Row>
                    <Col>
                        <ButtonGroup>
                            <UncontrolledDropdown>
                                <DropdownToggle caret outline color="primary">
                                    {editing ?  (editing.get('name') || 'Group name') : 'Select a group'}
                                </DropdownToggle>
                                <DropdownMenu>
                                    {groups.map(group =>
                                        (<DropdownItem
                                             onClick={() => dispatch(editGroup(group))}
                                             key={group._getId()}
                                             active={editing && group._getId() === editing._getId()}>{group.get('name') || 'Group name'}
                                        </DropdownItem>))}
                                    <DropdownItem
                                        onClick={() => dispatch(newVesselGroup())}>
                                        <i className="fa fa-plus-circle"></i> New Group
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </ButtonGroup>
                    </Col>
                </Row>
            </ModalHeader>
            <ModalBody>
                <Form className="vesselGroups" onSubmit={e => { e.preventDefault(); dispatch(saveGroups()) }}>
                    {editing && (
                         <div key={editing._getId()}>
                             <FormGroup row>
                                 <Label for="groupName" sm={2}>Name</Label>
                                 <Col sm={10}>
                                     <Input
                                         id="groupName"
                                         type="input"
                                         placeholder="Group Name"
                                         invalid={(editing.get('name') || '').length < 1}
                                         value={editing.get('name') || ''}
                                         onChange={e => dispatch(updateGroup(editing, 'name', e.target.value))} />
                                     <FormFeedback>Groups must have a name.</FormFeedback>
                                 </Col>
                             </FormGroup>
                             <FormGroup row>
                                 <InputGroup>
                                     <Label for="GroupDescription" sm={2}>Description</Label>
                                     <Col sm={10}>
                                         <Input
                                             type="input"
                                             placeholder="Group Description"
                                             value={editing.get('description') || ''}
                                             onChange={e => dispatch(updateGroup(editing, 'description', e.target.value))} />
                                     </Col>
                                 </InputGroup>
                             </FormGroup>
                             <FormGroup row>
                                 <Col sm={12} className="text-right">
                                     {editing && (<Button id="DeleteGroup" color="primary" onClick={e => { e.preventDefault(); dispatch(showWarning(true)) }}>Delete <i className="fa fa-trash"></i></Button>)}
                                     {editing && (<UncontrolledTooltip delay={{show:500, hide:250}} target="DeleteGroup">
                                         Delete group and options.
                                     </UncontrolledTooltip>)}
                                 </Col>
                             </FormGroup>
                             <hr className="dashed"/>
                             <Row>
                                 <Col><h5>Options:</h5></Col>
                             </Row>

                             {(groupItems[editing._getId()] || []).map((item, i, all) => (
                                 <div  key={item._getId()}>
                                     <FormGroup>
                                         <InputGroup>
                                             <InputGroupAddon addonType="prepend"><i className="icon-folder input-group-text"></i></InputGroupAddon>
                                             <Input
                                                 invalid={(item.get('name') || '').length < 1}
                                                 type="input"
                                                 placeholder="Item"
                                                 value={item.get('name') || ''}
                                                 onChange={e => dispatch(updateGroupItem(item, 'name', e.target.value))}
                                             >
                                             </Input>
                                             <FormFeedback>Options must have a name.</FormFeedback>
                                         </InputGroup>
                                     </FormGroup>
                                     <FormGroup>
                                         <InputGroup>
                                             <InputGroupAddon addonType="prepend"><i className="icon-speech input-group-text"></i></InputGroupAddon>
                                             <Input
                                                 type="input"
                                                 placeholder="Item Description"
                                                 value={item.get('description') || ''}
                                                 onChange={e => dispatch(updateGroupItem(item, 'description', e.target.value))} />
                                             <InputGroupAddon addonType="append">
                                                 <Button outline id={"DeleteItem" +  item._getId() } color="primary" onClick={ e => { e.preventDefault(); dispatch(deleteGroupItem(item)) }}>
                                                     Delete
                                                 </Button>
                                                 <UncontrolledTooltip delay={{show:500, hide:250}} target={"DeleteItem" +  item._getId()}>
                                                     Delete option
                                                 </UncontrolledTooltip>
                                             </InputGroupAddon>
                                         </InputGroup>
                                     </FormGroup>
                                     {(i < (all.length - 1)) && (<hr/>)}
                                 </div>
                             ))}
                             <Row>
                                 <Col className="text-right">
                                     <p>
                                         <Button color="link" onClick={ e => { e.preventDefault(); dispatch(addGroupItem()) }}><i className="fa fa-plus-circle"></i> New Option</Button>
                                     </p>
                                 </Col>
                             </Row>
                         </div>

                    ) || (
                         <p>No group selected.</p>
                    )}
                </Form>
            </ModalBody>
            <ModalFooter>

                <Button  id="CancelGroup" color="light" onClick={() => dispatch(toggleForm(false))} >Cancel</Button>
                <UncontrolledTooltip delay={{show:500, hide:250}} target="CancelGroup">
                    Revert all changes
                </UncontrolledTooltip>

                {!!updated && (
                     <Button
                         disabled={saving || !(isValid)}
                         id="SaveGroup"
                         color="primary"
                         onClick={e => { e.preventDefault(); dispatch(saveGroups()) }}>
                         {saving ? (<Spinner className="ref" color="#FFFFFF" name='chasing-dots' />)
                          : "Save changes"}
                     </Button>
                )}


                {!!updated && (
                     <UncontrolledTooltip delay={{show:500, hide:250}} target="SaveGroup">
                         Saves all
                     </UncontrolledTooltip>
                )}


            </ModalFooter>
        </Modal>
        <div className="text-right">
            <a className="addFuelType" onClick={() => dispatch(toggleForm(true))}>Groups</a>
        </div>


    </div>
);

export default connect(() => ({ vesselGroups }) => {
    return ({
        updated: vesselGroups.updated,
        groups: vesselGroups.groups,
        editing: vesselGroups.editGroup,
        saving: vesselGroups.saving,
        isValid: vesselGroups.isValid,
        groupItems: vesselGroups.groupItems,
        showForm: vesselGroups.showForm });
})(GroupForm);
