import React from "react";
import { connect } from 'react-redux';
import moment from 'moment';

import _ from 'lodash';

import { vesselsURL } from '../../../actions/navigation.js';
import { loadPage } from '../../../actions/voyages.js'
import { setRange } from '../../../actions/vessels.js';
import largePagination from './LargePagination.js';

import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Table,
  UncontrolledTooltip
} from "reactstrap";
import { round } from '../../../config/sparkLineChartOptions.js';


const voyageRow = (voyage, dispatch, path) => (
    <tr key={voyage.id}
        onClick={ () => dispatch(vesselsURL(path , voyage.get('vessel'), 'voyage/' + voyage.get('pkId'))) }>
        <td><span className="ref" style={{backgroundColor: voyage.get('vessel').get('colour')}}></span> {voyage.get('vessel').get('name')}</td>
        <td>{voyage.get('departurePort')}</td>
        <td>{voyage.get('arrivalPort')}</td>
        <UncontrolledTooltip delay={{show:500, hide:250}} target={"VoyageDate" + voyage.id} placement="right" >Sets the time range to match this voyage</UncontrolledTooltip>
        <td ><Button  id={"VoyageDate" + voyage.id} outline color="primary" style={{borderColor: voyage.get('vessel').get('colour')}} onClick={e => {
                e.stopPropagation();
                dispatch(setRange(moment(voyage.get('startTime')), moment(voyage.get('endTime')), voyage.get('vessel')));
        }} >{ moment(voyage.get('startTime')).format('DD MMM YY')} - { moment(voyage.get('endTime')).format('DD MMM YY')}</Button></td>
        <td>{ round(((voyage.get('totalFuelAtSea') + voyage.get('totalFuelAtArrivalPort')) / 1000))}</td>
        <td>{voyage.get('EEOI') || "-"}</td>
    </tr>);

const VoyagesCard = ({ voyages, dispatch, count, limit, page, path }) => (
    <div>
        <Table responsive striped className="noTopLine">
            <thead>
                <tr>
                    <th>Vessel</th>
                    <th>Departure</th>
                    <th>Arrival</th>
                    <th>Dates</th>
                    <th>Fuel [MT]</th>
                    <th>EEOI</th>
                </tr>
            </thead>
            <tbody className="clickAble">
                { voyages.map( voyage => voyageRow(voyage, dispatch, path)) }
            </tbody>
        </Table>
        <div className="row justify-content-center">
            {largePagination(dispatch, count, limit, page, loadPage)}
        </div>
    </div>
);

export default connect(() => ({ voyages, routing }) => ({ voyages: voyages.voyages || [],
                                                          path: routing.location.pathname,
                                                          page: voyages.page,
                                                          limit: voyages.limit,
                                                          count: voyages.count}))(VoyagesCard);
