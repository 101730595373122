// jshint esversion:6

import Parse from '../parse';
import _ from 'lodash';


const Measurement = Parse.Object.extend("Measurement");
export const WEEK = 7;
export const DAY = 1;

export const loading = () => {
  return {
    type: 'MEASUREMENTS_LOADING'
  };
};

export const setDays = (days) => {
  return {
    type: 'MEASUREMENTS_DAYS',
    days
  };
}

export const loaded = (measurements) => {
  return {
    type: 'MEASUREMENTS_LOADED',
    measurements
  };
};

export const consumersMeasurementsLoaded = (consumersMeasurements) => {
  return {
    type: 'MEASUREMENTS_CONSUMERS_LOADED',
    consumersMeasurements
  };
};


const consumerMeasurements = (consumers, measurements) =>  {
  var cm = consumers.reduce( (acc, consumer) => {
    return consumer.get('tags').reduce((acc, tag) => {
      acc[tag.get('name')] = _.union(acc[tag.get('name')] || [], measurements.filter(measurement => {
        if (measurement.get('tagId') === tag.get('pkId')) {
          measurement.set('consumer', consumer);
          return true;
        }
        return false;
      }) || []);
      return acc;
    }, {});
  }, {});
  return cm;
};

export const loadConsumersMeasurements = (consumers) => (dispatch, getState) => {
  const { measurements } = getState();

  const tags = _.flatten(consumers.map( consumer => consumer.get('tags')) || []);
  if (!tags || !tags.length) return null;

  var query = new Parse.Query(Measurement);

  var ago = new Date();
  ago.setDate(ago.getDate() - measurements.days);

  ago.setDate(ago.getDate() - measurements.days);
  Parse.Object.fetchAllIfNeeded(tags).then(tags => {
    query.containedIn('tag', tags);
    query.ascending("timestamp");
    query.greaterThan('timestamp', ago);
    query.find().then(basicMeasurements => Parse.Object.fetchAllIfNeeded(basicMeasurements)
                      .then(measurements => dispatch(consumersMeasurementsLoaded(consumerMeasurements(consumers, measurements)))));
  });
};

export const groupMeasurements = (tags, measurements) => {
  return tags.reduce((acc, tag) => {
    acc[tag.get('name')] = measurements.filter(measurement => measurement.get('tagId') === tag.get('pkId')) || [];
    return acc;
  }, {});
};

export const loadMeasurements = (tags) => (dispatch, getState) => {
  const { measurements } = getState();

  if (!tags || !tags.length) return dispatch(loaded({}));

  var query = new Parse.Query(Measurement);

  var ago = new Date();
  ago.setDate(ago.getDate() - measurements.days);

  query.containedIn('tag', tags);
  query.ascending("timestamp");
  query.greaterThan('timestamp', ago);
  query.find().then(basicMeasurements => {
    Parse.Object.fetchAllIfNeeded(basicMeasurements).then(measurements => {
      dispatch(loaded(groupMeasurements(tags, measurements)));
    });
  });
};
