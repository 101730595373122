import Parse from '../parse';

import { addErrorMessage } from './error.js';

const VesselGroup = Parse.Object.extend("VesselGroup");
const VesselGroupItem = Parse.Object.extend("VesselGroupItem");
const Vessel = Parse.Object.extend("Vessel");
import { loadFleet } from './fleet.js';

export const loadedGroups = (groups) => {
    return {
        type: 'ALL_GROUPS_LOADED',
        groups
    };
};

export const saved = () => {
    return {
        type: 'ALL_SAVED'
    };
};

export const saving = () => {
    return {
        type: 'ALL_SAVING'
    };
};

export const loadedItems = (items) => {
    return {
        type: 'ALL_GROUP_ITEMS_LOADED',
        items
    };
};

export const newGroup = (group) => {
    return {
        type: 'NEW_GROUP',
        group
    };
};

export const newGroupItem = (item) => {
    return {
        type: 'NEW_GROUP_ITEM',
        item
    };
};

export const deleteGroupItem = (item) => {
    return {
        type: 'DELETE_GROUP_ITEM',
        item
    };
};
export const deleteGroup = (group) => {
    return {
        type: 'DELETE_GROUP',
        group
    };
};

export const updateGroupItem = (item, key, value) => {
    return {
        type: 'UPDATE_GROUP_ITEM',
        item,
        key,
        value
    };
};

export const editGroup = (group) => {
    return {
        type: 'EDIT_GROUP',
        group
    };
};

export const updateGroup = (group, key, value) => {
    return {
        type: 'UPDATE_GROUP',
        key,
        group,
        value
    };
};

export const toggleForm = (showForm) => {
    return {
        type: 'GROUP_SHOW_FORM',
        showForm
    };
};

export const toggleVesselForm = (showForm) => {
    return {
        type: 'GROUP_SHOW_VESSEL_FORM',
        showForm
    };
};

export const editVessel = (vessel) => {
    return {
        type: 'GROUP_EDIT_VESSEL',
        vessel
    };
};

export const savingVessel = () => {
    return {
        type: 'GROUP_SAVING_VESSEL'
    };
};

export const savedVessel = () => {
    return {
        type: 'GROUP_SAVED_VESSEL'
    };
};

export const updateVesselOption = (groupId, itemId) => {
    return {
        type: 'GROUP_EDIT_VESSEL_OPTION',
        groupId,
        itemId
    };
};

export const addGroupItem = () => (dispatch) => {
    const item = new VesselGroupItem();
    dispatch(newGroupItem(item));
};

export const startDeleteGroup = () => (dispatch, getState) => {
    var { vesselGroups } = getState();

    Promise.all((vesselGroups.groupItems[vesselGroups.editGroup.id] || []).map(item => item.destroy()))
        .then(() => vesselGroups.editGroup.destroy())
        .then(() => {
            dispatch(deleteGroup());
        })
        .catch(e => {
            dispatch(addErrorMessage({txt: 'There was an error deleting the Group and Group items.'}));
            dispatch(addErrorMessage({txt: e.message} ));
        });
};

export const showWarning = (askToDelete) => {
    return {
        type: 'ASK_TO_DELETE_GROUP',
        askToDelete
    };
};

export const newVesselGroup = () => (dispatch, getState) => {
    let { accounts } = getState();
    let company = accounts.user.get("company");
    const group = new VesselGroup();
    group.set({company});
    dispatch(newGroup(group));
};


export const saveVesselOptions = () => (dispatch, getState) => {
    var { vesselGroups } = getState();
    const vessel = vesselGroups.vessel;
    const items = vesselGroups.vessel.get('groupItems');
    const backup = vesselGroups.vessel._toFullJSON();
    dispatch(savingVessel());
    vessel.revert();
    vessel.set('groupItems', items);
    vessel
        .save()
        .then(() => {
            Parse.Object.fromJSON(backup);
            dispatch(savedVessel());
             })
        .catch(e => {
            dispatch(addErrorMessage({txt: 'There was an error saving the Vessel'}));
            dispatch(addErrorMessage({txt: e.message} ));
        });
};


export const saveGroups = () => (dispatch, getState) => {
    var { vesselGroups } = getState();
    dispatch(saving());
    Promise.all(vesselGroups.groups.filter(group => group.dirty())
                .map(group => group.save()))
        .then(() => vesselGroups.groups.map(group => (vesselGroups.groupItems[group.id] || []).map(item => {
                                                       item.set({group});
                                                       return item.save();
                                                   })))
        .then(() => Promise.all(vesselGroups.deletedItems.map(item => item.destroy())))
        .then(() => dispatch(saved()))
        .catch(e => {
            dispatch(addErrorMessage({txt: 'There was an error saving the Groups and Group items.'}));
            dispatch(addErrorMessage({txt: e.message} ));
            console.log('There was an error saving the Groups and Group items', e);
        });
};

export const loadVesselGroupItems = () => (dispatch, getState) => {
    var { vesselGroups } = getState();
    var query = new Parse.Query(VesselGroupItem);


    query.containedIn('group', vesselGroups.groups);
    query.find()
        .then(groupItems => Parse.Object.fetchAllIfNeeded(groupItems))
        .then(groupItems => {
            dispatch(loadedItems(groupItems));
            dispatch(loadFleet());
        })
        .catch(() => dispatch(addErrorMessage({txt: `There was an error loading the Vessel group items.`} )));
};

export const loadAllVesselGroups = () => (dispatch, getState) => {
    var { accounts } = getState();
    var company = accounts.user.get("company");
    var query = new Parse.Query(VesselGroup);
    query.equalTo('company', company);
    query.find()
        .then(groups => Parse.Object.fetchAllIfNeeded(groups))
        .then(groups => {
            dispatch(loadedGroups(groups));
            dispatch(loadVesselGroupItems());
        })
        .catch(() => dispatch(addErrorMessage({txt: `There was an error loading the Vessel groups.`} )));
};
