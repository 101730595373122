// jshint esversion:6
const defaultState = {  tags: [] };

export default (state = defaultState, action = {}) => {
    switch (action.type) {
    case 'ACCOUNT_LOGOUT':
        return {...defaultState };
  case 'TAGS_LOADING':
    return {...defaultState, loading: true };
  case 'TAGS_LOADED':
    return { ...state, loading: false, tags: action.tags };
  default:
    return state;
  }
};
