import React from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { connect } from 'react-redux';
import { addVessel } from '../../../actions/account.js';
import _ from 'lodash';

const AddVessel = ({vessels, dispatch}) =>   !!vessels.length && (
    <UncontrolledDropdown  className="f-right">
        <DropdownToggle outline caret color="primary">
            Add a vessel
        </DropdownToggle>
        <DropdownMenu className="scroll">
            {vessels.map(vessel => (<DropdownItem onClick={ () => dispatch(addVessel(vessel))} key={vessel.id}>{vessel.get("name")}</DropdownItem>))}
        </DropdownMenu>
    </UncontrolledDropdown>
);

export default connect((state) => ({
    user: state.accounts.editing,
    vessels: state.vessels.allVessels.filter(v => {
        return _.findIndex(state.accounts.vessels, vessel => v._getId() === vessel._getId())  === -1;
    })}))(AddVessel);
