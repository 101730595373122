import React from "react";
import { connect } from 'react-redux';
import moment from 'moment';
import { ButtonGroup, Button, ButtonDropdown, DropdownMenu, DropdownToggle, DropdownItem, Badge, UncontrolledTooltip} from 'reactstrap';
import { Link } from 'react-router-dom';
import Spinner from 'react-spinkit';

import SetDateRange from '../../Components/SetDateRange/SetDateRange.js'
import {setDateMonth, setDateWeek, setDateDay, showRange, setRange } from '../../../actions/vessels.js';
import {saveFile} from '../../../actions/saveFile.js';
import {saveReportXls} from '../../../actions/saveReport.js';
import { makeURL } from '../../../actions/navigation.js';
import ReactGA from 'react-ga';



const getColor = (vessel) => {
    const hours = diff(vessel) / 60 / 60;
  if (!hours) return "danger";
  if (hours < 6) return "success";
  if (hours < 24) return "warning";
  return "danger";
};

const getLastReception = (vessel) => {
  return vessel.get('lastReception') ? moment(vessel.get('lastReception')).fromNow() : "Never";
}

const diff = (vessel) => {
  return moment( new Date()).unix() - moment(vessel.get('lastReception')).unix();
}

class VesselDetails extends React.Component {
    constructor(props) {
        super(props);
    
        this.toggle = this.toggle.bind(this);
        this.state = {
            dropdownOpen: false
        };
        }
    
        toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
        }
    
    render () {
        return (
            <div className="datePicker">
                <SetDateRange
                    startDate={this.props.from}
                    endDate={this.props.to}
                    showRange={showRange}
                    showTheRange={this.props.showTheRange}
                    vessels={[this.props.vessel]}
                    setRange={setRange}
                    setDateDay={setDateDay}
                    setDateWeek={setDateWeek}
                    setDateMonth={setDateMonth}
                ></SetDateRange>
    
                <div className="spans vesselDetails">
                    <h4 className="vesselTitle">
                        {this.props.loading ? (<Spinner className="ref" color={this.props.colour} name='chasing-dots' />)
                            : (<span className="ref" style={{backgroundColor: this.props.colour}}></span>)}
                        {this.props.vessel.get('name')}
                        <small><Badge color={getColor(this.props.vessel)} pill> Synced {getLastReception(this.props.vessel)}.</Badge></small>
                    </h4>
                    <ul>
                        <UncontrolledTooltip delay={{show:500, hide:250}} target="SetVesselRange" >Sets a custom time range</UncontrolledTooltip>
                        <li onClick={e => {
                                e.preventDefault();
                                ReactGA.event({
                                    category: 'Setting',
                                    action: 'Set time range from overview',
                                    label: this.props.vessel.get('pkId')
                                })
                                this.props.dispatch(showRange(!this.props.showTheRange, this.props.vessel));
                        }}>
                            <small id="SetVesselRange">
                                <a href="#">{moment(this.props.from).format('DD MMM YYYY HH:mm')} </a>
                                <span> to </span>
                                <a href="#"> {moment(this.props.to).format('DD MMM YYYY HH:mm')}</a>
                            </small>
                        </li>
                        <li>
    
                            <UncontrolledTooltip delay={{show:500, hide:250}} target="FuelConsumptionReport" >Shows a detailed fuel consumption report for the selected time period.</UncontrolledTooltip>
                            <Button
                                id="FuelConsumptionReport"
                                outline color="secondary"
                                className="lnk">
                                <Link to={makeURL(this.props.path, this.props.vessel, "fuelConsumption")}>Fuel consumption</Link>
                            </Button>
    
                            <UncontrolledTooltip delay={{show:500, hide:250}} target="ListOfWarnings" >Shows the list of warnings</UncontrolledTooltip>
                            <Button id="ListOfWarnings" outline color="secondary" className="lnk" >
                                <Link to={makeURL(this.props.path, this.props.vessel, "logs")}>Warnings log</Link>
                            </Button>
    
                            <ButtonDropdown id="RawData" isOpen={this.state.dropdownOpen} toggle={this.toggle} color="secondary">
                                <DropdownToggle caret>
                                    {this.props.loadingReport ? (<Spinner className="ref" color="steelblue" name='chasing-dots' />)
                                    : <i className="fa fa-download" aria-hidden="true"></i>} Download
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={e => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                if (!this.props.loadingReport) {
                                                                    ReactGA.event({
                                                                        category: 'Download',
                                                                        action: 'Voyage report, xlsx',
                                                                        label: this.props.vessel.get('pkId')
                                                                    });
                                                                    this.props.dispatch(saveReportXls(this.props.vessel, this.props.from, this.props.to, ", ", "csv"));
                                                                }
                                                                else {
                                                                    ReactGA.event({
                                                                        category: 'Download failed',
                                                                        action: 'Voyage report, xlsx',
                                                                        label: this.props.vessel.get('pkId')
                                                                    });
                                                                }
                                                        }}>Voyage Report (XLSX)</DropdownItem>
                                    <DropdownItem onClick={e => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                ReactGA.event({
                                                                    category: 'Download',
                                                                    action: 'Raw measurements file, csv',
                                                                    label: this.props.vessel.get('pkId')
                                                                });
                                                                this.props.dispatch(saveFile(this.props.vessel, ", ", "csv"))
                                                        }}>Raw Data (CSV)</DropdownItem>
                                    <DropdownItem onClick={e => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                ReactGA.event({
                                                                    category: 'Download',
                                                                    action: 'Raw measurements file, tsv',
                                                                    label: this.props.vessel.get('pkId')
                                                                });
                                                                this.props.dispatch(saveFile(this.props.vessel, "\t", "tsv"))
                                                        }}>Raw Data (TSV)</DropdownItem>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </li>
                    </ul>
                </div>
            </div>
        );
    };
}


export default connect((state, { vessel }) => ({voyages, saveReport, vessels, routing}) =>  {

    return ({ voyages: voyages.voyages,
              to: vessel.get('to') || vessels.to,
              path: routing.location.pathname,
              from: vessel.get('from') || vessels.from,
              loading: vessel.get('loadingHistory'),
              showTheRange: vessel.get('showRange'),
              colour: vessel.get('colour'),
              span: vessel.get('span') || vessels.span,
              loadingReport: saveReport.loading
             });
})(VesselDetails);
