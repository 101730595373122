import React from "react";
import { connect } from 'react-redux';
import { Line } from "react-chartjs-2";
import moment from 'moment';
import _ from 'lodash';
import humanizeDuration from 'humanize-duration';
import {
    Row,
    Col,
    Card,
    CardBody,
    Table,
    Container
} from "reactstrap";
import { round } from '../../../config/sparkLineChartOptions.js';


const getEndTime = (voyage) => {
  return moment(voyage.get('endTime') || new Date());
}

const getStartTime = (voyage) => {
  return moment(voyage.get('startTime') || new Date());
}

const getDuration = (voyage) => {
  const diff = getEndTime(voyage).unix() - getStartTime(voyage).unix();
  return humanizeDuration(diff * 1000);
}

const getFuelType = (id, fuelTypes) => {
  if (!fuelTypes[id]) return null;
  return fuelTypes[id].get('name');
}

const consumptionRow = (consumption, fuelTypes) => (
  <tr key={consumption.fuelTypeId} >
    <td>{getFuelType(consumption.fuelTypeId, fuelTypes)}</td>
    <td>{round(consumption.totalFuel / 1000, 1)}</td>
    <td>{round(consumption.totalCO2 / 1000, 1)}</td>
    <td>{round(consumption.totalSOx / 1000, 1)}</td>
  </tr>
);

const consumptionTable = (consumption, fuelTypes) => (
    <Container fluid>
        <Card>
            <Table id="ConsumptionTables" responsive striped>
                <thead>
                    <tr>
                        <th>Fuel type</th>
                        <th>Consumption [MT]</th>
                        <th>Total CO&#8322; [MT]</th>
                        <th>Total SOx [MT]</th>
                    </tr>
                </thead>
                <tbody>
                    {consumption.map(c => consumptionRow(c, fuelTypes))}
                </tbody>
                <tfoot>
                    <tr>
                        <th>Total:</th>
                        <th>{round(consumption.map(c => c.totalFuel).reduce((a, b) => a + b, 0) / 1000, 1)}</th>
                        <th>{round(consumption.map(c => c.totalCO2).reduce((a, b) => a + b, 0) / 1000, 1)}</th>
                        <th>{round(consumption.map(c => c.totalSOx).reduce((a, b) => a + b, 0) / 1000, 1)}</th>
                    </tr>
                </tfoot>
            </Table>
        </Card>
    </Container>);

const eventRow = (event, key) => (
    <tr key={event.EventType + key} >
        <td>{event.EventType}</td>
        <td>{moment(event.EventTime).format('DD MMM YYYY HH:mm')}</td>
    </tr>
);

const eventTable = (events) => (
  <Card>
    <Table id="EventTables" responsive striped>
      <thead>
        <tr>
          <th>Event Type</th>
          <th>Time</th>
        </tr>
      </thead>
      <tbody>
        {events.sort((e1, e2) => {
          if (e1.EventTime > e2.EventTime) return 1;
          else if (e1.EventTime < e2.EventTime) return -1;
          else return 0;
        }).map(eventRow)}
      </tbody>
    </Table>
  </Card>);

const VoyageReport = ({ voyage, fuelTypes }) => (
  <div className="page">
    <Row>
      <Col>
        <Card>
          <CardBody className="bunkerItems" >
            <h2>Voyage: {voyage.get('departurePort')} - {voyage.get('arrivalPort')}</h2>

            <Row>
              <Col sm="12" lg="4">
                <ul>
                  <li><strong>Voyage no: </strong> <span>{voyage.get('voyageNo')}</span></li>
                  <li><strong>Voyage type: </strong> <span>{(voyage.get('voyageType') || "").replace(/([A-Z])/g, ' $1')}</span></li>
                  <li><strong>Departure port: </strong> <span>{voyage.get('departurePort')}</span></li>
                  <li><strong>Arrival port: </strong> <span>{voyage.get('arrivalPort')}</span></li>
                  <li><strong>Duration: </strong> <span>{getDuration(voyage)}</span></li>
                  <li><strong>Time at sea: </strong> <span>{humanizeDuration((voyage.get('timeAtSea') || 0) * 1000)}</span></li>
                </ul>
              </Col>
              <Col sm="12" lg="4">
                <ul>
                  <li><strong>Cargo carried: </strong> <span>{round(voyage.get('cargoCarried') / 1000, 1)} MT</span></li>
                  <li><strong>Passengers: </strong> <span>{voyage.get('passengers')}</span></li>
                  <li><strong>Distance sailed: </strong> <span>{round(voyage.get('distanceSailed') / 1852, 1)} nmi</span></li>
                  <li><strong>Fuel: </strong> <span>{round((voyage.get('totalFuelAtSea') + voyage.get('totalFuelAtArrivalPort')) / 1000, 1)} MT</span></li>
                  <li><strong>CO&#8322;: </strong> <span>{round((voyage.get('totalCO2AtSea') + voyage.get('totalCO2AtArrivalPort')) / 1000, 1)} MT</span></li>
                  <li><strong>SOx: </strong> <span>{round((voyage.get('totalSOxAtSea') + voyage.get('totalSOxAtSea')) / 1000, 1)} MT</span></li>
                </ul>
              </Col>
              <Col sm="12" lg="4">
                <ul>
                  <li><strong>Transport work (Cargo): </strong> <span>{round((voyage.get('distanceSailed') * voyage.get('cargoCarried')) / (1000 * 1852), 1)} MT*nmi</span></li>
                  <li><strong>Transport work (Passengers): </strong> <span>{round((voyage.get('distanceSailed') * voyage.get('passengers')) / (1852), 1)} MT*nmi</span></li>
                  <li><strong>Consumption pr. distance: </strong> <span>{round((voyage.get('totalFuelAtSea')) * (1852 / 1000) / voyage.get('distanceSailed'), 1)} MT/nmi</span></li>
                  <li><strong>CO&#8322; pr. distance: </strong> <span>{round((voyage.get('totalCO2AtSea')) * (1852 / 1000) / voyage.get('distanceSailed'), 1)} MT/nmi</span></li>
                  <li><strong>Fuel price: </strong> <span>{round(voyage.get('price'), 2) || '---'} US$</span></li>
                </ul>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
    <Row>
      <Col xs="12" xl="6">
        <Card>
          <CardBody>
            <Row>
              <Col sm="12">
                <h4>Consumption at sea</h4>
              </Col>
            </Row>
            {consumptionTable(voyage.get('consumptionAtSea') || [], fuelTypes)}
          </CardBody>
        </Card>
      </Col>
      <Col xs="12" xl="6">
        <Card>
          <CardBody>
            <Row>
              <Col sm="12">
                <h4>Consumption at arrival port</h4>
              </Col>
            </Row>
            {consumptionTable(voyage.get('consumptionAtArrivalPort') || [], fuelTypes)}
          </CardBody>
        </Card>
      </Col>
    </Row>
    <Row>
      <Col xs="12" xl="6">
        <Card>
          <CardBody>
            <Row>
              <Col sm="12">
                <h4>Events</h4>
              </Col>
            </Row>
            {eventTable(voyage.get('events') || [])}
          </CardBody>
        </Card>
      </Col>
    </Row>
  </div>)

export default connect(() => ({ voyages, fuelTypes }) => ({
  voyage: voyages.voyage,
  fuelTypes: fuelTypes.fuelTypes
}))(VoyageReport);
