import { round } from '../config/sparkLineChartOptions.js';

export const mainOptions = {
  responsive: true,
  tooltips: {
    intersect: false,
    mode: 'index',
    callbacks: {
      label: ({yLabel}) => {
        return round(yLabel) + ' kg/h';
      }
    }
  },
  elements: {
    line: {
      fill: false
    }
  },
  scales: {
    // xAxes: [
    //   {
    //     display: true,
    //     gridLines: {
    //       display: false
    //     },
    //     labels: {
    //       show: true
    //     }
    //   }
    // ],
    yAxes: [
      {
        type: 'linear',
        display: true,
        position: 'left',
        id: 'Main',
        gridLines: {
          display: true
        },
        labels: {
          show: true
        },
        scaleLabel: {
          display: true,
          labelString: 'Consumption [kg/hour]'
        }
      }
    ]
  }
}

export const consumerOptions = {
  responsive: true,
  tooltips: {
    intersect: false,
    mode: 'index',
    callbacks: {
      label: (tooltipItems, data) => {
        var dataset = data.datasets[tooltipItems.datasetIndex].label;
        var suffix = '';
        if (dataset === 'RPM') suffix = ' RPM'
        else if (dataset === 'Pitch') suffix = ' %'
        else if (dataset === 'Consumption') suffix = ' kg/h';
        return round(tooltipItems.yLabel) + suffix;
      }
    }
  },
  elements: {
    line: {
      fill: false
    }
  },
  scales: {
    // xAxes: [
    //   {
    //     display: true,
    //     gridLines: {
    //       display: false
    //     },
    //     labels: {
    //       show: true
    //     }
    //   }
    // ],
    yAxes: [
      {
        type: 'linear',
        display: true,
        position: 'left',
        id: 'Main',
        gridLines: {
          display: true
        },
        labels: {
          show: true
        },
        scaleLabel: {
          display: true,
          labelString: 'Consumption [kg/hour]'
        }
      },
      {
        type: 'linear',
        display: true,
        position: 'right',
        id: 'Small',
        gridLines: {
          display: false
        },
        labels: {
          show: true
        },
        scaleLabel: {
          display: true,
          labelString: 'Pitch [%]/RPM'
        }
      }
    ]
  }
}


export const colours = [
  '#3366CC',
  '#FF9900',
  '#0099C6',
  '#3B3EAC',
  '#990099',
  '#DD4477',
  '#109618',
  '#66AA00',
  '#B82E2E',
  '#316395',
  '#994499',
  '#22AA99',
  '#AAAA11',
  '#6633CC',
  '#E67300',
  '#8B0707',
  '#329262',
  '#5574A6',
  '#3B3EAC',
  '#DC3912'
];
