import React from "react";
import { connect } from 'react-redux';
import _ from 'lodash';
import { clearAddErrorMessage } from '../../../actions/error.js';

import {
  Alert,
  Row,
  Col
} from "reactstrap";

const PageErrors = ({ messages, dispatch }) => (messages.length) ? (
  <Row className="errorMessages">
    <Col sm="12" md={{ size: 6, offset: 6 }}>
      {_.map(messages, (message, key) => (
        <Alert key={key}color={message.colour} toggle={() => dispatch(clearAddErrorMessage(key)) }>
          {message.txt}
        </Alert>))}
    </Col>
  </Row>
) : null;

export default connect(() => ({error}) => ({ messages: error.messages}))(PageErrors);
