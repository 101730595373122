import { Marker } from 'react-leaflet';
import L from 'leaflet';
import { Marker as LeafMarker } from 'leaflet';
import PropTypes from 'prop-types';

class POIMarker extends Marker {
  createLeafletElement(props) {
    const options = {
      iconSize: new L.Point(20, 20),
      html: '<i class="fa fa-' + (props.markerType || 'map-pin') + ' fa-sm" aria-hidden="true"></i>'
		};
    options.icon =  new L.DivIcon(options);
    return new LeafMarker(props.position, options);
  }

  updateLeafletElement(fromProps, toProps) {
    if (toProps.markerType !== fromProps.markerType) {
      const options = {
        iconSize: new L.Point(20, 20),
        html: '<i class="fa fa-' + (toProps.markerType || 'map-pin') + ' fa-sm" aria-hidden="true"></i>'
      };
      this.leafletElement.setIcon(new L.DivIcon(options));
    }

    if (toProps.position !== fromProps.position) {
      this.leafletElement.setLatLng(toProps.position);
    }
  }
}

POIMarker.propTypes = {
  position: PropTypes.arrayOf(PropTypes.number),
  markerType: PropTypes.string
}

export default POIMarker;
