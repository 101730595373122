import React from "react";
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import VesselDetails from '../Vessel/VesselDetails.js';
import LineGraph from './LineGraph.js';
import { colours } from '../../options.js';
import ConsumptionDetails from '../../Components/ConsumptionDetails/ConsumptionDetails.js';

import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Container,
    Table
} from "reactstrap";

const FuelConsumptionReport = ({ vessels, fuelConsumption, totals }) => (
    <Container fluid>
        <div className="page">
            {vessels.map((vessel, i) => (
                <VesselDetails  key={vessel.get('pkId')} colour={colours[i]} vessel={vessel} ></VesselDetails>))}
            <Row>
                <Col>
                    <Card>
                        <CardHeader>Consumer Totals
                            {(fuelConsumption.trend && fuelConsumption.trend.length) && (
                                 <span> {moment(_.first(fuelConsumption.trend).time).format('DD MMM YYYY HH:mm')} -- {moment(_.last(fuelConsumption.trend).time).format('DD MMM YYYY HH:mm')}</span>
                            )}
                        </CardHeader>
                        <CardBody>
                            <Table responsive striped>
                                <thead>
                                    <tr>
                                        <th>Consumer</th>
                                        <th>Fuel [MT]</th>
                                        <th>Diesel [MT]</th>
                                        <th>LFO [MT]</th>
                                        <th>HFO [MT]</th>
                                        <th>LPG [MT]</th>
                                        <th>LNG [MT]</th>
                                        <th>CO2 [MT]</th>
                                        <th>SOx [MT]</th>
                                        <th>NOx [MT]</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(fuelConsumption.consumption || []).map((c, i) => (
                                        <tr key={i}>
                                            <td>{c.consumer}</td>
                                            <td>{_.round(c.consumption, 1)}</td>
                                            <td>{_.round(c.diesel, 1)}</td>
                                            <td>{_.round(c.lfo, 1)}</td>
                                            <td>{_.round(c.hfo, 1)}</td>
                                            <td>{_.round(c.lpg, 1)}</td>
                                            <td>{_.round(c.lng, 1)}</td>
                                            <td>{_.round(c.co2, 1)}</td>
                                            <td>{_.round(c.sox, 1)}</td>
                                            <td>{_.round(c.nox, 1)}</td>
                                        </tr>
                                    ))}
                                    <tr className="total">
                                        <td>Total</td>
                                        <td>{String(_.round(totals.consumption, 1))}</td>
                                        <td>{String(_.round(totals.diesel, 1))}</td>
                                        <td>{String(_.round(totals.lfo, 1))}</td>
                                        <td>{String(_.round(totals.hfo, 1))}</td>
                                        <td>{String(_.round(totals.lpg, 1))}</td>
                                        <td>{String(_.round(totals.lng, 1))}</td>
                                        <td>{String(_.round(totals.co2, 1))}</td>
                                        <td>{String(_.round(totals.sox, 1))}</td>
                                        <td>{String(_.round(totals.nox, 1))}</td>
                                    </tr>

                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <CardHeader>Total Fuel Consumption [kg/hour]</CardHeader>
                        <CardBody>
                            <LineGraph></LineGraph>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ConsumptionDetails></ConsumptionDetails>
                </Col>
            </Row>
        </div></Container>);

export default connect(() => ({ vessels }) => ({ vessels: vessels.activeVessels,
                                                 fuelConsumption: vessels.fuelConsumption,
                                                 totals: (vessels.fuelConsumption.consumption || []).reduce((total, c) => {
                                                     (_.keys(c) || []).forEach(k => total[k] = (total[k] || 0) + c[k]);
                                                     return total; }, {})

}))(FuelConsumptionReport);
