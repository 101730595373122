import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';

import { startDeleteGroup, showWarning } from '../../../actions/vesselGroups.js';

const toggle = (dispatch) => {
  dispatch(showWarning(false));
}

const DeleteModal = ({open, group, dispatch}) => (
    <div>
        <Modal isOpen={open && !!group} toggle={() => toggle(dispatch)} >
            <ModalHeader toggle={() => toggle(dispatch)}>Delete the <strong>{name}</strong> group.</ModalHeader>
            <ModalBody>
                Are you sure you want to delete the <strong>{name}</strong> group?
            </ModalBody>
            <ModalFooter>
                <Button color="danger" onClick={() => {toggle(dispatch); dispatch(startDeleteGroup())}}>Delete <strong>{name}</strong></Button>{' '}
                <Button color="secondary" onClick={() => toggle(dispatch)}>Cancel</Button>
            </ModalFooter>
        </Modal>
    </div>
);

export default connect(() => ({ vesselGroups }) => {
    return ({
        open: vesselGroups.askToDelete,  group: vesselGroups.editGroup })
})(DeleteModal);
