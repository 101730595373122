// jshint esversion:6
const defaultState = {  items: [], page: 0, limit: 20, dashboardLimit: 10, count: 0 };

export default (state = defaultState, action = {}) => {
    switch (action.type) {
    case 'ACCOUNT_LOGOUT':
        return {...defaultState };
    case 'VESSELLOGS_LOADING':
        return {...defaultState, loading: true };
    case 'VESSELLOGS_LOADED':
        return { ...state, loading: false, items: action.items };
    case 'VESSELLOGS_COUNT':
        return { ...state, count: action.count };
    case 'VESSELLOGS_PAGE':
        return { ...state, page: action.page };
    default:
        return state;
    }
};
