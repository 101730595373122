import React from "react";
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { sortBunkerItems, loadPage, loadBunkerItems} from '../../../actions/allBunkerItems.js';
import largePagination from '../Vessel/LargePagination.js';
import { isNullOrUndefined } from "util";
import { PORT_KEY, SUPPLIER_KEY } from '../../../reducers/search.js';
import { Button, Table } from "reactstrap";
import { vesselsURL } from '../../../actions/navigation.js';
import { SORT_ASC, STARTTIME_KEY, MASSTOTAL_KEY, MASSTOTALBDN_KEY, AVGMASSFLOW_KEY, AERATION_KEY } from "../../../reducers/allBunkerItems.js";
import Spinner from 'react-spinkit';

const check = (func, err) => {
    return func || func == 0 ? func : err;
}

const renderVessel = (bunker, path, dispatch) => (
    <tr key={bunker.id} onClick={() => dispatch(vesselsURL(path, bunker.get('vessel'), 'bunker/' + bunker.get('pkId')))}>
    {/* <tr key={bunker.id} > */}
        <td>{check(bunker.get('port'), "---")}</td>
        <td>{check(bunker.get('supplier'), "---")}</td>
        <td>{check(bunker.get('fuelType').get('name'), "---")}</td>
        <td>{check(bunker.get('vessel').get('name'), "---")}</td>
        <td>{check(moment(bunker.get('startTime')).format('DD-MMM-YY HH:mm'), "---")}
            {check(moment(bunker.get('endTime')).format(' - HH:mm'), " -")}</td>
        <td>{check(_.round(bunker.get('massTotal') / 1000, 2), "---")}</td>
        <td>{check(_.round(bunker.get('massTotalBdn') / 1000, 2), "---")}</td>
        <td>{check(_.round((bunker.get('massTotal') - bunker.get('massTotalBdn')) / 1000, 2), "---")}</td>
        <td>{check(_.round(bunker.get('avgMassFlow') / (1000 / 3600), 2), "---")}</td>
        <td>{check(_.round(bunker.get('aeration'), 1), "---")}</td>
    </tr>
);

const setSort = (dispatch, sortKey) => {
    dispatch(sortBunkerItems(sortKey));
    dispatch(loadBunkerItems());
};

const header = (sortkey, sortOrder, key, text, dispatch, setSort) => (
    <Button
        color={(sortkey == key) ? 'primary' : 'link'}
        onClick={() => setSort(dispatch, key)}>
        {text} <i className={(sortkey == key) ? ((sortOrder == SORT_ASC) ? "fa fa-caret-down" : "fa fa-caret-up") : ''}></i>
    </Button>
);


export const BunkerTable = ({ dispatch, allBunkerItems, setSort, loadPage, path }) => (
    <div>
        <div className="card">
        {allBunkerItems.loading ? (
            <div className="overlay">
                <div className="centerLoader">
                    <p>Loading data</p>
                    <Spinner color="#005DA8" name='folding-cube' />
                </div>
            </div>)
             : null}
        <Table id="BunkersTables" responsive striped>
            <thead>
                <tr>
                    <th >
                        {header(allBunkerItems.sortKey, allBunkerItems.sortOrder, PORT_KEY, "Port", dispatch, setSort)}
                    </th>
                    <th>
                        {header(allBunkerItems.sortKey, allBunkerItems.sortOrder, SUPPLIER_KEY, "Supplier", dispatch, setSort)}
                    </th>
                    <th >
                        <button
                            className="btn btn-link disabled" >
                            Product
                                    </button>
                        {/* {header(allBunkerItems.sortkey, allBunkerItems.sortOrder, "fuelType.name", "Product", dispatch,setSort)} */}
                    </th>
                    <th >
                        <button
                            id="disabledLinkButton"
                            className="btn btn-link disabled" >
                            Vessel
                                    </button>
                        {/* {header(allBunkerItems.sortkey, allBunkerItems.sortOrder, "vessel.name", "Vessel", dispatch,setSort)} */}
                    </th>
                    <th >
                        {header(allBunkerItems.sortKey, allBunkerItems.sortOrder, STARTTIME_KEY, "Dates", dispatch, setSort)}
                    </th>
                    <th >
                        {header(allBunkerItems.sortKey, allBunkerItems.sortOrder, MASSTOTAL_KEY, "Total Mass [MT]", dispatch, setSort)}
                    </th>
                    <th >
                        {header(allBunkerItems.sortKey, allBunkerItems.sortOrder, MASSTOTALBDN_KEY, "BDN Mass [MT]", dispatch, setSort)}
                    </th>
                    <th >
                    <button
                            className="btn btn-link disabled" >
                            Diff [MT]
                                    </button>
                    </th>
                    <th >
                        {header(allBunkerItems.sortKey, allBunkerItems.sortOrder, AVGMASSFLOW_KEY, "Avg. massflow [MT/h]", dispatch, setSort)}
                    </th>
                    <th >
                        {header(allBunkerItems.sortKey, allBunkerItems.sortOrder, AERATION_KEY, "Aeration [%]", dispatch, setSort)}
                    </th>
                </tr>
            </thead>
            <tbody className="clickAble">
                {!isNullOrUndefined(allBunkerItems.bunkerItems) ? allBunkerItems.bunkerItems.map((bunker) => (renderVessel(bunker, path, dispatch))): null}
            </tbody>
        </Table>
        </div>
        {!isNullOrUndefined(allBunkerItems.bunkerItems) && allBunkerItems.bunkerItems.length == 0 && !allBunkerItems.loading ?
        (<div className="padleft">No bunkerings found.</div>) : null}
        <div className="row justify-content-center">
            {largePagination(dispatch, allBunkerItems.count, allBunkerItems.limit, allBunkerItems.page, loadPage)}
        </div>
    </div>
);

export default connect((state, dispatch) => (
    {
        allBunkerItems: state.allBunkerItems,
        dispatch: dispatch,
        setSort: setSort,
        loadPage: loadPage,
        path: state.routing.location.pathname
    }))(BunkerTable);