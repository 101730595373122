import React from "react";
import {Container, Row, Col, Card, CardBody, Button,
        ListGroup, ListGroupItem } from "reactstrap";
import { connect } from 'react-redux';

import { editUser, addUser } from '../../../actions/account.js';
import AddVessel from './AddVessel.js';
import ListVessels from './ListVessels.js';
import Profile from './Profile.js';
import AskToDelete from './AskToDelete.js';


const showUsers = (activeUser, users, dispatch) => (<ListGroup className="mb-3"> {(users || []).map(
    user => (<ListGroupItem key={user._getId()}  active={activeUser && activeUser._getId && (activeUser._getId() === user._getId())} action onClick={() => { dispatch(editUser(user)) }}>
      {user.get('username') || 'New User'}</ListGroupItem>))}</ListGroup>);

const Users = ({user, users, dispatch}) => (
    <div className="page">
        <AskToDelete></AskToDelete>
        <Container className="container-fluid">
            <div className="page">
                <Row>
                    <Col lg="4" md="5">
                        <Card>
                            <CardBody className="card-body">
                                <Col md="12">
                                    <Container>
                                        <h3>Users </h3>
                                        <p>Here you will find all the users and their account informations.</p>
                                        <ListGroup className="mb-3 scroll">
                                            {showUsers(user, users, dispatch)}
                                        </ListGroup>
                                        <Button block
                                            onClick={() => dispatch(addUser())}
                                            className="col-lg-7 col-md-12 col-sm-7 col-7 mb-3 secondary-btn px-4 f-right" >Add users</Button>
                                    </Container>
                                </Col>
                            </CardBody>
                        </Card>
                    </Col>
                    {(!user.temp) ? ( <Col lg="8" md="7"  className="app ">
                        <Card>
                            <CardBody className="card-body">
                                <Col lg="6" md="12" sm="12" className="f-left">
                                    <Container>
                                        <h3>Fleet </h3>
                                        <p>Here you will find the user's vessels.</p>
                                        <ListVessels></ListVessels>
                                        <AddVessel></AddVessel>
                                    </Container>
                                </Col>
                                <Col lg="6" md="12" sm="12" className="f-left">
                                    <Container>
                                        <h3>Profile </h3>
                                        <p>The user's profile information.</p>
                                        <Profile key={user._getId()}></Profile>
                                    </Container>
                                </Col>
                            </CardBody>
                        </Card>
                    </Col>) : null }
                </Row>
            </div>
        </Container>
    </div>
);

export default connect((state) => ({ users: state.accounts.users,
                                     user: state.accounts.editing,
                                     vessels: state.accounts.vessels,
                                     updated: state.accounts.updated,
                                     email: state.accounts.editing.get('email'),
                                     username: state.accounts.editing.get('username') }))(Users);
