import _ from 'lodash';

export default (state = {messages: []}, action = {}) => {
  switch (action.type) {
  case 'ADD_ERROR_MESSAGE':
    return {...state, messages: [...state.messages, action.message]};
  case 'CLEAR_ERROR_MESSAGE':
    return {...state, messages: _.without(state.messages, state.messages.splice(action.index, 1))};
  case 'CLEAR_ERROR_MESSAGES':
    return {...state, messages: {}};
  default:
    return state;
  }
};
