import { createStore, applyMiddleware, compose } from "redux";
import reduxThunk from "redux-thunk";
import { createBrowserHistory } from "history";

import { routerMiddleware } from "react-router-redux";
import reducers from "../reducers/index.js";
import {
  loadVessels,
  updateVessels,
  loadAllVessels,
  loading,
} from "../actions/vessels.js";
import { loadFleet, clearFleetCache, isFleetURL } from "../actions/fleet.js";
import { clearLogsCache } from "../actions/vesselLogs.js";
import { loadPage } from "../actions/search.js";

import { loadFuelPricesPage } from "../actions/fuelprices.js";
import { isloggedIn, loadAllUsers } from "../actions/account.js";
import { startLoadPOI } from "../actions/POI.js";

export const history = createBrowserHistory();
const middleware = routerMiddleware(history);

const loadData = (store) => (next) => (action) => {
  const na = next(action);
  if (action.type === "ACCOUNT_LOGIN") {
    store.dispatch(loading());
    store.dispatch(clearFleetCache());
    store.dispatch(clearLogsCache());
    store.dispatch(loadVessels());
    store.dispatch(startLoadPOI());
  }
  if (action.type === "VESSELS_LOADED") {
    store.dispatch(loadPage(0));
  }
  if (action.type === "ACCOUNT_LOGOUT") {
    document.body.classList.remove("sidebar-show");
    store.dispatch(loading());
    store.dispatch(clearFleetCache());
    store.dispatch(clearLogsCache());
  }

  return na;
};

export const store = createStore(
  reducers,
  compose(
    applyMiddleware(reduxThunk, middleware, loadData),
    window.devToolsExtension ? window.devToolsExtension() : (f) => f
  )
);

history.listen((location) => {
  if (location.pathname == "/bunkersearch") {
    window.scrollTo(0, 0);
  }

  if (location.pathname == "/login") {
    document.body.classList.add("sidebar-hidden");
  }

  if (!store.getState().accounts.user && location.pathname !== "/login") {
    history.push("/login");
  }

  if (location.pathname && location.pathname.indexOf("vessel") !== -1) {
    store.dispatch(updateVessels(location.pathname));
  }

  if (isFleetURL(location.pathname || "")) {
    store.dispatch(loadFleet(location.pathname));
  }

  if (location.pathname && location.pathname.indexOf("/fuelprices") !== -1) {
    store.dispatch(loadFuelPricesPage(0, location.pathname));
  }

  if (location.pathname && location.pathname.indexOf("/users") !== -1) {
    if (!store.getState().accounts.admin) history.push("/profile");

    store.dispatch(loadAllUsers());
    store.dispatch(loadAllVessels());
  }
});

store.dispatch(isloggedIn());
export default store;
