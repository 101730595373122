import React, {Component} from "react";
import { connect } from 'react-redux';
import { round } from '../../../config/sparkLineChartOptions.js';
import {
  Card,
  CardBody,
  CardFooter,
} from "reactstrap";

const Consumption = ({ fleet }) => (
  <Card>
    <CardBody className="card-body">
      <p>Average Fuel consumption <small>[kg/h]</small></p>
      <h2>{round(fleet.avgConsumption) }</h2>
    </CardBody>
  </Card>
)

export default connect((state) => ({ fleet: state.fleet.fleet }))(Consumption);
