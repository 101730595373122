// jshint esversion:6

const defaultState = {  fuelTypes: {},  fuelType: { get: () => null } };

export default (state = defaultState, action = {}) => {
    switch (action.type) {
    case 'ACCOUNT_LOGOUT':
        return {...defaultState };
  case 'FUELTYPES_LOADING':
    return {...defaultState, loading: true };
  case 'FUELTYPES_LOADED':
    return { ...state, loading: false, fuelTypes: action.fuelTypes };
  default:
    return state;
  }
};
