import React from "react";
import { connect } from 'react-redux';
import { loadPage } from '../../../actions/vesselLogs.js'
import Logs from '../../../views/Components/Logs/Logs.js'

import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Pagination,
    PaginationItem,
    PaginationLink
} from "reactstrap";


const pgItem = (i, page, dispatch) => (<PaginationItem key={i} active={i === page}>
    <PaginationLink onClick={() => { dispatch(loadPage(i)) }} >{i + 1}</PaginationLink>
</PaginationItem>);


const getPagination = (dispatch, count, limit, page) => {
  if (limit > count) return null;
  var pages = [];
  const l = Math.ceil(count / limit);

  if (page > 0) pages.push(<PaginationItem key="previous">
    <PaginationLink previous onClick={() => { dispatch(loadPage(page - 1)) }} />
  </PaginationItem>);

  for (var i = 0; i < l; i++) {
    pages.push(pgItem(i, page, dispatch));
  }

  if (page < l - 1) pages.push(<PaginationItem key="next">
    <PaginationLink next onClick={() => { dispatch(loadPage(page + 1)) }} />
  </PaginationItem>);

  return (<Pagination size="sm">{pages}</Pagination>);
};

const VesselLogs = ({count, limit, page,  dispatch}) => (
    <Container fluid>
        <div  className=" page">
            <Card>
                <CardHeader>Warnings log</CardHeader>
                <CardBody>
                    <Logs></Logs>
                </CardBody>
                <div className="row justify-content-md-center">
                    {getPagination(dispatch, count, limit, page)}
                </div>
            </Card>
        </div>
    </Container>);


export default connect(() => ({ logs }) => ({
  logs: logs.items,
  count: logs.count,
  limit: logs.limit,
  page: logs.page
}))(VesselLogs);
