// jshint esversion:6
const defaultState = { consumers: [], loading: false };

export default (state = defaultState, action = {}) => {
    switch (action.type) {
    case 'ACCOUNT_LOGOUT':
        return {...defaultState };
    case 'CONSUMERS_LOADING':
        return {...defaultState, loading: true };
    case 'CONSUMERS_LOADED':
        return { ...state, loading: false, consumers: action.consumers };
    default:
        return state;
    }
};
