import _ from 'lodash';

const isValidPoint = (point) => ((point.position[0] !== 0 && point.position[1] !== 0)
                                 && _.isNumber(point.position[0])
                                 && _.isNumber(point.position[1])
                                 && point.position[0] < 90
                                 && point.position[0] > -90
                                 && point.position[1] > -180
                                 && point.position[1] < 180);


export default isValidPoint;
