// jshint esversion:6
const defaultState = { bunkerItems: [], bunkerMeasurements: [], bunker: { get: () => null }, page: 0, limit: 10, count: 0 };

export default (state = defaultState, action = {}) => {
    switch (action.type) {
    case 'ACCOUNT_LOGOUT':
        return {...defaultState };
    case 'BUNKERITEMS_LOADING':
      return { ...defaultState, loading: true };
    case 'BUNKERITEMS_LOADED':
      return { ...state, loading: false, bunkerItems: action.bunkerItems };
    case 'BUNKER_LOADED':
      return { ...state, bunker: action.bunker, bunkerMeasurements: undefined };
    case 'BUNKERMEASUREMENTS_LOADED':
      return { ...state, bunkerMeasurements: action.bunkerMeasurements };
    case 'BUNKER_COUNT':
      return { ...state, count: action.count };
    case 'BUNKER_PAGE':
      return { ...state, page: action.page };

    default:
      return state;
  }
};
