import React from "react";
import ReactDOM from "react-dom";
import { Route, Switch, Redirect } from "react-router-dom";
import { Container } from "reactstrap";

import Header from "./views/Components/Header/Header.js";
import Sidebar from "./views/Components/Sidebar/Sidebar.js";

import Breadcrumb from "./views/Components/Breadcrumb/Breadcrumb.js";

import { ConnectedRouter } from "react-router-redux";
import { Provider } from "react-redux";
import { store, history } from "./stores/index.js";

// Styles
import "font-awesome/css/font-awesome.min.css";
import "simple-line-icons/css/simple-line-icons.css";
import "../scss/style.scss";

// Views
import Login from "./views/Pages/Login/Login.js";
import ForgotPassword from "./views/Pages/Login/ForgotPassword.js";
import Users from "./views/Pages/Users/Users.js";
import Profile from "./views/Pages/Profile/Profile.js";
import Page404 from "./views/Pages/Page404/Page404.js";
import Page500 from "./views/Pages/Page500/Page500.js";

import BunkerReport from "./views/Pages/BunkerReport/index.js";
import BunkerSearch from "./views/Pages/BunkerSearch/index.js";
import VoyageReport from "./views/Pages/VoyageReport/index.js";
import VesselLogs from "./views/Pages/VesselLogs/index.js";
import FuelConsumptionReport from "./views/Pages/FuelConsumptionReport/index.js";
import Vessel from "./views/Pages/Vessel/";
import Dashboard from "./views/Pages/Dashboard/";
import FuelPrices from "./views/Pages/FuelPrices/index.js";
import Organise from "./views/Pages/Organise/index.js";
import PageErrors from "./views/Components/PageErrors/PageErrors.js";
import RequestAccess from "./views/Pages/Register/RequestAccess.js";
import Done from "./views/Pages/Register/Done.js";

import "leaflet/dist/leaflet.css";

import moment from "moment-timezone";
moment.tz.setDefault("UTC");
moment.locale("en-gb");

import { defaults } from "react-chartjs-2";
defaults.global.animation = false;

import ReactGA from "react-ga";
ReactGA.initialize("UA-142860004-2");
history.listen((location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <div className="app">
        <Header />
        <div className="app-body">
          <Sidebar />
          <main className="main">
            <Breadcrumb></Breadcrumb>
            <PageErrors></PageErrors>
            <Switch>
              <Route exact path="/login" name="Login Page" component={Login} />
              <Route
                exact
                path="/forgot_password"
                name="Forgot password"
                component={ForgotPassword}
              />

              <Route exact path="/users" name="Users" component={Users} />
              <Route
                exact
                path="/profile"
                name="Profile Page"
                component={Profile}
              />
              <Route exact path="/404" name="Page 404" component={Page404} />
              <Route exact path="/500" name="Page 500" component={Page500} />

              <Route
                exact
                path="/group/:group/:id/vessels/:vessel/bunker/:id"
                name="BunkerReport"
                component={BunkerReport}
              />
              <Route
                exact
                path="/group/:group/:id/vessels/:vessel/voyage/:id"
                name="VoyageReport"
                component={VoyageReport}
              />
              <Route
                exact
                path="*vessels/:vessel/bunker/:id"
                name="BunkerReport"
                component={BunkerReport}
              />
              <Route
                exact
                path="*vessels/:vessel/voyage/:id"
                name="VoyageReport"
                component={VoyageReport}
              />
              <Route
                exact
                path="*vessels/:vessel/fuelConsumption"
                name="FuelConsumptionReport"
                component={FuelConsumptionReport}
              />

              <Route
                exact
                path="*vessels/:vessel/logs"
                name="FuelConsumptionReport"
                component={VesselLogs}
              />
              <Route
                exact
                path="/vessels"
                name="Vessel"
                component={Dashboard}
              />
              <Route
                exact
                path="/vessels/"
                name="Vessel"
                component={Dashboard}
              />

              <Route
                exact
                path="/request_access/"
                name="Request access"
                component={RequestAccess}
              />
              <Route
                exact
                path="/access_requested/"
                name="Access requested"
                component={Done}
              />

              <Route
                path="/bunkersearch/"
                name="Bunker search"
                component={BunkerSearch}
              />

              <Route
                path="/group/*/vessels/*"
                name="Vessel"
                component={Vessel}
              />
              <Route path="/vessels/*" name="Vessel" component={Vessel} />
              <Route path="/organise/" name="Vessels" component={Organise} />
              <Route
                path="/fuelprices/"
                name="FuelPrices"
                component={FuelPrices}
              />
              <Route path="/" name="Home" component={Dashboard} />
            </Switch>
          </main>
        </div>
      </div>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("app")
);
