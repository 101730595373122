// jshint esversion:6

const defaultState = {
    loading: false,
    reportGenerationError: 0
};

export default (state = defaultState, action = {}) => {
    switch (action.type) {
        case 'ACCOUNT_LOGOUT':
            return {
                ...defaultState
            };
        case 'SAVEREPORT_LOADING':
            return {
                ...state,
                loading: true
            };
        case 'SAVEREPORT_LOADED':
            return {
                ...state,
                loading: false      
            };
        default:
            return state;
    }
};