// jshint esversion:6

import Parse from '../parse';
import _ from 'lodash';
import { VESSEL_KEY, PRODUCT_KEY } from '../reducers/search.js';
import { SORT_ASC } from '../reducers/allBunkerItems.js';

const BunkerItem = Parse.Object.extend("BunkerItem");
const FuelType = Parse.Object.extend("FuelType");

export const searching = () => {
    return { type: 'SEARCH_LOADING' };
};

export const searched = (searchResult) => {
    return { type: 'SEARCH_LOADED', searchResult: searchResult };
};

export const setPage = (page) => {
    return { type: 'SEARCH_PAGE', page: page };
};

export const setCount = (count) => {
    return { type: 'SEARCH_COUNT', count: count };
};

export const setSearchKeywords = (keyword, key) => {
    return { type: 'SEARCH_KEYWORDS', keyword: keyword, key: key };
};

export const sortBunkerItems = (sortKey) => {
    return { type: 'SEARCH_SORT', sortKey: sortKey };
};

export const searchHandler = (keyword, key) => (dispatch) => {
    dispatch(setSearchKeywords(keyword, key));
    dispatch(loadPage(0));
}

export const loadPage = (page) => (dispatch) => {
    dispatch(setPage(page));
    dispatch(searchBunkerItems());
};

const getVesselId = (activeVessels, element) => {
    var vesselId = [];
    activeVessels.forEach(vessel => {
        const name = vessel.get('name').toLowerCase();
        if (name.includes(element.toLowerCase())) {
            const id = vessel.get('pkId');
            vesselId.push(id);
        }
    });
    return vesselId;
};

const getFuelTypeId = (activeVessels, element) => {
    const query = new Parse.Query(FuelType);
    query.containedIn('vessel', activeVessels);
    query.matches('name', element, 'i');
    return query;
};

export const searchBunkerItems = () => (dispatch, getState) => {
    dispatch(searching());
    const { search } = getState();
    const { vessels } = getState();
    const activeVessels = vessels.vessels;
    if (!activeVessels) return null;
    const query = new Parse.Query(BunkerItem);
    query.containedIn("vessel", activeVessels);
    query.notEqualTo("deleted", true);
    for (const key in search.searchkeys) {
        if (search.searchkeys.hasOwnProperty(key)) {
            const element = search.searchkeys[key];
            if (element) {
                if (key == VESSEL_KEY) {
                    query.containedIn('vesselId', getVesselId(activeVessels, element));
                }
                else if (key == PRODUCT_KEY) {
                    query.matchesQuery('fuelType', getFuelTypeId(activeVessels, element));
                }
                else {
                    query.matches(key, element, 'i');
                }
            }
        }
    }

    (search.sortOrder === SORT_ASC) ? query.ascending(search.sortKey) : query.descending(search.sortKey);

    query.count().then(count => { dispatch(setCount(count)); });
    query.limit(search.limit);
    query.skip(search.limit * search.page);
    query.find()
        .then(basicBunkerItems => {
            Parse.Object.fetchAllIfNeeded(basicBunkerItems)
                .then(bunkerItems => {
                    Promise.all(bunkerItems.map(bunkerItem => Parse.Object.fetchAllIfNeeded(bunkerItem.get('fuelType'))))
                        .then(() => {
                            dispatch(searched(bunkerItems));
                        });
                });
        });
};