// jshint esversion:6

import Parse from '../parse';
import { loadMeasurements } from './measurements';
import _ from 'lodash';

const POI = Parse.Object.extend("POI");

export const loading = () => {
  return {
    type: 'POI_LOADING'
  };
};

export const loaded = (points) => {
  return {
    type: 'POI_LOADED',
    points
  };
};


export const remove = () => {
  return {
    type: 'POI_REMOVE'
  };
};

export const edit = (point) => {
  return {
    type: 'POI_EDIT',
    point
  };
};

export const save = (point) => {
  return {
    type: 'POI_SAVE',
    point
  };
};

export const update = (key, value) => {
  return {
    type: 'POI_UPDATE',
    key,
    value
  };
};

export const toggleForm = (showForm) => {
  return {
    type: 'POI_SHOWFORM',
    showForm
  };
};

export const startSave = (point) => (dispatch, getState) => {
  var { accounts } = getState();
  var company = accounts.user.get("company");
  point.save({company}).then(point => dispatch(save(point)));
};

export const startRemove = (point) => (dispatch) => {
  point.destroy().then(() => dispatch(remove()));
};


export const startLoadPOI = () => (dispatch, getState) => {
  var { accounts } = getState();
  var company = accounts.user.get("company");
  var query = new Parse.Query(POI);
  query.equalTo('company', company);
  query.find()
    .then(points => Parse.Object.fetchAllIfNeeded(points))
    .then(points => dispatch(loaded(points)));
};

export const editPOI = (poi) => (dispatch) => {
  poi = poi || new POI();
  dispatch(edit(poi));
};
