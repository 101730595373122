import React from "react";
import { connect } from 'react-redux';
import moment from 'moment';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip} from 'reactstrap';
import SetDateRange from '../SetDateRange/SetDateRange.js'
import ReactGA from 'react-ga';

const SetDate = ({setDateDay, setDateWeek, setDateMonth, showTheRange, showRange, setRange, to, from, dispatch}) => {
  return (
      <p className="datePicker text-right spans">
          <SetDateRange
              startDate={from}
              endDate={to}
              showRange={showRange}
              showTheRange={showTheRange}
              vessels={[{}]}
              setRange={setRange}
              setDateDay={setDateDay}
              setDateWeek={setDateWeek}
              setDateMonth={setDateMonth}
          ></SetDateRange>


          <UncontrolledTooltip
              delay={{show:500, hide:250}}
              target="SetToRange">Set interval</UncontrolledTooltip>
          <small  onClick={e => {
                  e.preventDefault();
                  ReactGA.event({
                      category: 'Setting',
                      action:'Set global time range'
                  })
                  dispatch(showRange(!showTheRange));
          }}>
              <a href="#" id="SetToRange">
                  {moment(from).format('DD MMM YYYY HH:mm')} </a>
              <span> to </span>
              <a href="#"> {moment(to).format('DD MMM YYYY HH:mm')}</a>
          </small>

      </p>
  );
};

export default connect((state, dispatch) => ({ dispatch}))(SetDate);
