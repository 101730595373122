import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import formatcoords from "formatcoords";
import { connect } from 'react-redux';
import {toggleVoyageDetails, loadVoyageDetails } from '../../../actions/voyages.js';
import modeMap from './Mode.js';
import {setRange as setRangeV} from '../../../actions/vessels.js';
import {setRange as setRangeF} from '../../../actions/fleet.js';
import ReactGA from 'react-ga';

import {
  Button,
  Col,
  Row,
  Container
} from "reactstrap";

function handleFromTime(timestamp, vessel, path, dispatch){
  ReactGA.event({
    category: 'Setting',
    action: 'Set time from mapPopup, Set from'
  });
  path.includes('vessel') ? dispatch(setRangeV(timestamp, null, vessel)):dispatch(setRangeF(timestamp, null, vessel));
}

function handleToTime(timestamp, vessel, path, dispatch){
  ReactGA.event({
    category: 'Setting',
    action: 'Set time from mapPopup, Set to'
  });
  path.includes('vessel') ? dispatch(setRangeV(null, timestamp, vessel)):dispatch(setRangeF(null, timestamp, vessel));
}

const MapPopUp = ({points, vessel, dispatch, showVoyageDetails, path}) =>  (
  <dl>
    <dt><h4><span className="ref" style={{backgroundColor: vessel.get('colour')}}> </span> {vessel.get('name')}</h4></dt>
    <dd>
    {(points.length > 1) && (
    <Container>
      <Row>
        <Col>
        {moment(_.first(points).timestamp).format('DD MMMM YYYY HH:mm')}
        </Col>
        <Col xs="1">
        </Col>
        <Col>
          {moment(_.last(points).timestamp).format('DD MMMM YYYY HH:mm')}
        </Col>
      </Row>
      <Row>
        <Col>
        <a className="button" href="#" onClick={()=>handleFromTime(_.first(points).timestamp, vessel, path, dispatch)}>Set from
        </a>
        <span> | </span>
        <a className="button" href="#" onClick={()=>handleToTime(_.first(points).timestamp, vessel, path, dispatch)}>Set to
        </a>
        </Col>
        <Col xs="1">
        </Col>
        <Col>
          <a className="button" href="#" onClick={()=>handleFromTime(_.last(points).timestamp, vessel, path, dispatch)}>Set from
          </a>
          <span> | </span>
          <a className="button" href="#" onClick={()=>handleToTime(_.last(points).timestamp, vessel, path, dispatch)}>Set to
          </a>
        </Col>
      </Row>
      </Container>
      )}
      {(points.length <= 1) && (
      <Container>
      <Row>
        <Col>
        {moment(_.first(points).timestamp).format('DD MMMM YYYY HH:mm')}
        </Col>
        <Col xs="1">
        </Col>
        <Col>
        </Col>
      </Row>
      <Row>
        <Col>
        <a className="button" href="#" onClick={()=>handleFromTime(_.first(points).timestamp, vessel, path, dispatch)}>Set from
        </a>
        <span> | </span>
        <a className="button" href="#" onClick={()=>handleToTime(_.first(points).timestamp, vessel, path, dispatch)}>Set to
        </a>
        </Col>
        <Col xs="1">
        </Col>
        <Col>
        </Col>
      </Row>
      </Container>
      )}
    </dd>

    <dt>Mode:</dt>
    <dd style={{color: modeMap[_.first(points).mode].colour}}>
      {modeMap[_.first(points).mode].name}
    </dd>
    <dt>Coordinates:</dt>
    <dd>
      {formatcoords(_.first(points).position).format('FFf')}
      {(points.length > 1) && (  <span> to  <br />
        {formatcoords(_.last(points).position).format('FFf')}</span>)}
    </dd>
    <dt>Speed / Heading:</dt>
    <dd>
      {_.round(_.mean(points.map(p => p.speed)))} kn / {_.round(_.mean(points.map(p => p.heading)))}°
    </dd>
    <dt>Momentary consumption:</dt>
    <dd>
      {_.round(_.mean(points.map(p => p.momentaryConsumption)))} kg/h
    </dd>
    <Button onClick={() => {
        if (!showVoyageDetails) dispatch(
          loadVoyageDetails(vessel, points));
        dispatch(toggleVoyageDetails(!showVoyageDetails));
    }} outline color="primary">{showVoyageDetails ? "Hide" : "Show"} details</Button>
  </dl>
);


export default connect((state) => ({ 
  showVoyageDetails: state.voyages.showDetails,
  path: state.routing.location.pathname
 }))(MapPopUp);
