import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import { makeURL, goback } from '../../../actions/navigation.js';

const Breadcrumbs = ({vessels, isGroup, pathname, dispatch}) => (( (pathname || '').indexOf('vessels') > -1 &&  Boolean(vessels.length) && (pathname || "").split('/').length > 3) && (vessels.length === 1) ? (
    <Breadcrumb tag="nav">
        <BreadcrumbItem key={pathname}>
            {vessels.map(vessel =>
                (<Link key={vessel.get("pkId")}
                     onClick={() => dispatch(goback())}
                     to={makeURL(pathname, vessel)}>
                     {"< Go back"}
                    {/* {"<"} {vessel.get("name")} */}
                </Link>))}
        </BreadcrumbItem>
    </Breadcrumb>) : null);

export default connect(() => ({ vessels, routing, nav }) => ({
    vessels: vessels.activeVessels,
    isGroup: nav.isGroup,
    pathname: routing.location.pathname }))(Breadcrumbs);
