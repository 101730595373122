import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BunkerTable } from '../Dashboard/BunkerTable.js'
import { searchHandler, searchBunkerItems, sortBunkerItems, loadPage } from '../../../actions/search.js';
import {PORT_KEY, SUPPLIER_KEY, PRODUCT_KEY, VESSEL_KEY} from '../../../reducers/search.js';
import { Row, Col, Card, Container, InputGroup, InputGroupAddon, Input } from "reactstrap";
import Spinner from 'react-spinkit';

const SearchHandler = (dispatch, keyword, key) => {
    dispatch(searchHandler(keyword, key));
}

const setSort = (dispatch, sortKey) => {
    dispatch(sortBunkerItems(sortKey));
    dispatch(searchBunkerItems());
};

class BunkerSearch extends Component {
    render() {
        return BunkerSearchContent({dispatch: this.props.dispatch, search: this.props.search, searchKeys: this.props.search.searchkeys});
    }
}

const BunkerSearchContent = ({dispatch, search, searchKeys}) => {
    return (
        <Container fluid>
            <div className="dashboard page">
                <Row>
                    <Col xs="12">
                        <Card>
                            <Row>
                                <Col xs="12" sm="3">
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">Port</InputGroupAddon>
                                        <Input value={searchKeys[PORT_KEY]} placeholder="any" onChange={(e) => SearchHandler(dispatch, e.target.value, PORT_KEY)} />
                                    </InputGroup>
                                </Col>
                                <Col xs="12" sm="3">
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">Supplier</InputGroupAddon>
                                        <Input value={searchKeys[SUPPLIER_KEY]} placeholder="any" onChange={(e) => SearchHandler(dispatch, e.target.value, SUPPLIER_KEY)} />
                                    </InputGroup>
                                </Col>
                                <Col xs="12" sm="3">
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">Product</InputGroupAddon>
                                        <Input value={searchKeys[PRODUCT_KEY]} placeholder="any" onChange={(e) => SearchHandler(dispatch, e.target.value, PRODUCT_KEY)} />
                                    </InputGroup>
                                </Col>
                                <Col xs="12" sm="3">
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">Vessel</InputGroupAddon>
                                        <Input value={searchKeys[VESSEL_KEY]} placeholder="any" onChange={(e) => SearchHandler(dispatch, e.target.value, VESSEL_KEY)} />
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        <Card>
                            <BunkerTable dispatch={dispatch} allBunkerItems={search} setSort={setSort} loadPage={loadPage} /> 
                        </Card>
                    </Col>
                </Row>
            </div>
        </Container>
    );
}

export default connect((state, dispatch) => ({
        search: state.search,
        dispatch: dispatch.dispatch
    }))(BunkerSearch);