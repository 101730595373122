// jshint esversion:6

import Parse from '../parse';
import _ from 'lodash';

const BunkerItem = Parse.Object.extend("BunkerItem");
const allBunkerCache = {};
const allBunkerCountCache = {};

export const loading = () => {
  return {
    type: 'ALLBUNKERITEMS_LOADING'
  };
};

export const loaded = (bunkerItems) => {
  return {
    type: 'ALLBUNKERITEMS_LOADED',
    bunkerItems: bunkerItems
  };
};

export const setPage = (page) => {
  return {
    type: 'ALLBUNKERITEMS_PAGE',
    page: page
  };
};

export const sortBunkerItems = (sortKey) => {
  return {
    type: 'ALLBUNKERITEMS_SORT',
    sortKey: sortKey
  };
};

export const searchKeyword = (keyword) => {
  return {
    type: 'ALLBUNKERITEMS_SEARCH',
    keyword: keyword
  }
}

export const loadedBunkerCount = (count) => {
  return {
    type: 'ALLBUNKERITEMS_COUNT',
    count: count
  }
}

export const loadBunkerCount = () => (dispatch, getState) => {
  const { vessels } = getState();
  const activeVessels = vessels.vessels;

  const { allBunkerItems } = getState();

  const query = new Parse.Query(BunkerItem);
  query.containedIn('vessel', activeVessels);
  query.notEqualTo("deleted", true);
  query.count().then(count => {
    dispatch(loadedBunkerCount(count));
  });

};

export const setSearchKeyword = (keyword) => (dispatch) => {
  dispatch(searchKeyword(keyword));
};

export const loadPage = (page) => (dispatch) => {
  dispatch(setPage(page));
  dispatch(loadBunkerItems());
};

const makeBunkerQuery = (vessels, allBunkerItems) => {
  const query = new Parse.Query(BunkerItem);
  //query.fullText('supplier', 'Statoil');
  query.containedIn('vessel', vessels);
  (allBunkerItems.sortOrder === "asc") ? query.ascending(allBunkerItems.sortKey) : query.descending(allBunkerItems.sortKey);
  query.notEqualTo("deleted", true);
  query.limit(allBunkerItems.limit);
  query.skip(allBunkerItems.limit * allBunkerItems.page);
  return query;
};

const makeSearchQuery = (dispatch, key, vessels, allBunkerItems) => {
  const query = new Parse.Query(BunkerItem);
  query.containedIn('vessel', vessels);
  query.contains(key, allBunkerItems.keyword);
  (allBunkerItems.sortOrder === "asc") ? query.ascending(allBunkerItems.sortKey) : query.descending(allBunkerItems.sortKey);
  query.notEqualTo("deleted", true);
  query.count().then(count => {
    dispatch(loadedBunkerCount(count));
  });
  query.limit(allBunkerItems.limit);
  query.skip(allBunkerItems.limit * allBunkerItems.page)
  return query;
}

const makeCommonSearchQuery = (dispatch, vessels, allBunkerItems) => {
  const portquery = new Parse.Query(BunkerItem);
  portquery.containedIn('vessel', vessels);
  portquery.contains('port', allBunkerItems.keyword);
  (allBunkerItems.sortOrder === "asc") ? portquery.ascending(allBunkerItems.sortKey) : portquery.descending(allBunkerItems.sortKey);
  portquery.notEqualTo("deleted", true);

  const supplierquery = new Parse.Query(BunkerItem);
  supplierquery.containedIn('vessel', vessels);
  supplierquery.contains('supplier', allBunkerItems.keyword);
  (allBunkerItems.sortOrder === "asc") ? supplierquery.ascending(allBunkerItems.sortKey) : supplierquery.descending(allBunkerItems.sortKey);
  supplierquery.notEqualTo("deleted", true);
  

  const mainQuery = new Parse.Query.or(portquery, supplierquery);
  mainQuery.count().then(count => {
    dispatch(loadedBunkerCount(count));
  });
  mainQuery.limit(allBunkerItems.limit);
  mainQuery.skip(allBunkerItems.limit * allBunkerItems.page)
  return mainQuery;
}

export const loadBunkerItems = (path) => (dispatch, getState) => {
  dispatch(loading());
  const { vessels } = getState();
  const activeVessels = vessels.vessels;
  if (!activeVessels) return null;

  const { allBunkerItems } = getState();
  const key = _.join(activeVessels.map(v => v.id), "_") + allBunkerItems.limit + (allBunkerItems.limit * allBunkerItems.page);
  // if (allBunkerCache[key]){
  //   dispatch(loaded(allBunkerCache[key]));
  // } else {
  if (allBunkerItems.keyword.length > 1) {
    makeCommonSearchQuery(dispatch, activeVessels, allBunkerItems).find()
    .then(basicBunkerItems => {
      Parse.Object.fetchAllIfNeeded(basicBunkerItems).then(bunkerItems => {
        Promise.all(bunkerItems.map(bunkerItem => Parse.Object.fetchAllIfNeeded(bunkerItem.get('fuelType')))).then(() => {
          allBunkerCache[key] = bunkerItems;
          dispatch(loaded(bunkerItems));
        });
      });
    });
  } else {
    makeBunkerQuery(activeVessels, allBunkerItems).find()
    .then(basicBunkerItems => {
      Parse.Object.fetchAllIfNeeded(basicBunkerItems).then(bunkerItems => {
        Promise.all(bunkerItems.map(bunkerItem => Parse.Object.fetchAllIfNeeded(bunkerItem.get('fuelType')))).then(() => {
          allBunkerCache[key] = bunkerItems;
          dispatch(loaded(bunkerItems));
        });
      });
    });
  }
  // }
};