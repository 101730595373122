// jshint esversion:6
import Parse from "../parse";
import _ from "lodash";
import getCurrentUser from "../getCurrentUser/index.js";
const defualtState = {
  user: getCurrentUser(),
  authenticating: false,
  destination: "/",
  saving: false,
  updated: false,
  admin: false,
  askToDelete: false,
  editing: { get: () => null, temp: true },
  vessels: [],
};

const reset = {
  saving: false,
  updated: false,
  askToDelete: false,
  editing: { get: () => null, temp: true },
  vessels: [],
};

const sortUsers = (users) =>
  _.sortBy(users, (user) => (user.get("username") || "").toLowerCase());
const sortVessels = (vessels) =>
  _.sortBy(vessels, (vessel) => vessel.get("name").toLowerCase());

export default (state = defualtState, action = {}) => {
  switch (action.type) {
    case "ACCOUNT_LOGIN":
      return { ...defualtState, authenticating: false, user: getCurrentUser() };
    case "ACCOUNT_LOGOUT":
      return { ...defualtState, authenticating: false, user: null };
    case "ACCOUNT_AUTHENTICATING":
      return { ...state, authenticating: true };
    case "ACCOUNT_ERROR":
      return { ...defualtState, authenticating: false, error: action.e };
    case "ACCOUNT_LOGGING_IN_ERROR":
      return { ...state, authenticating: false, loggingInError: action.e };
    case "ACCOUNT_SAVING":
      return { ...state, saving: true };
    case "ACCOUNT_SAVED":
      return { ...state, saving: false, updated: false };
    case "ACCOUNT_UPDATED":
      return { ...state, updated: true };
    case "ACCOUNT_ADMIN":
      return { ...state, admin: action.userIsAdmin };
    case "ACCOUNTS_LOADED":
      return { ...state, users: sortUsers(action.users) };
    case "ACCOUNT_EDITING":
      return { ...state, editing: action.user };
    case "ASK_TO_DELETE_ACCOUNT":
      return { ...state, askToDelete: action.askToDelete };
    case "ACCOUNT_DESTINATION":
      return { ...state, destination: action.destination };
    case "ACCOUNT_VESSELS":
      return { ...state, vessels: sortVessels(action.vessels) };
    case "ADD_ACCOUNT":
      var user = new Parse.User();
      return {
        ...state,
        ...reset,
        users: sortUsers([...state.users, user]),
        editing: user,
      };
    case "REMOVE_ACCOUNT":
      return {
        ...state,
        ...reset,
        users: sortUsers(state.users.filter((user) => user !== action.user)),
      };
    case "ADD_ACCOUNT_VESSEL":
      return {
        ...state,
        vessels: sortVessels(_.uniq([...state.vessels, action.vessel])),
      };
    case "REMOVE_ACCOUNT_VESSEL":
      return {
        ...state,
        vessels: state.vessels.filter((element) => element !== action.vessel),
      };
    default:
      return state;
  }
};
