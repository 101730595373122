import React from "react";
import { connect } from 'react-redux';
import {
    loadFuelPricesPage,
    editFuelPrice,
    vesselFilter,
    categoryFilter,
    updateFilter
} from '../../../actions/fuelprices.js';

import FuelPriceForm from './Form.js';
import moment from 'moment';
import _ from 'lodash';
import {
    Row,
    Col,
    Card,
    Table,
    CardHeader,
    CardBody,
    Pagination,
    PaginationItem,
    PaginationLink,
    Input,
    Container
} from "reactstrap";


const pgItem = (i, page, dispatch) => (<PaginationItem key={i} active={i === page}>
    <PaginationLink onClick={() => { dispatch(loadFuelPricesPage(i)) }} >{i + 1}</PaginationLink>
</PaginationItem>);


const getPagination = (dispatch, count, limit, page) => {
    if (limit > count) return null;
    var pages = [];
    const l = Math.ceil(count / limit);

    if (page > 0) pages.push(<PaginationItem key="previous">
        <PaginationLink previous onClick={() => { dispatch(loadFuelPricesPage(page - 1)) }} />
    </PaginationItem>);

    for (var i = 0; i < l; i++) {
        pages.push(pgItem(i, page, dispatch));
    }

    if (page < l - 1) pages.push(<PaginationItem key="next">
        <PaginationLink next onClick={() => { dispatch(loadFuelPricesPage(page + 1)) }} />
    </PaginationItem>);

    return (<Pagination size="sm">{pages}</Pagination>);
};

const FuelPrices = ({count, limit, page, pricePoints, vessels, fuelCategories, dispatch}) => (
    <Container fluid>
        <div  className=" page">
            <Card>
                <CardHeader>
                    <Row>
                        <Col>Fuel prices </Col>
                        <Col><FuelPriceForm></FuelPriceForm></Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Table className="clickAble" responsive striped>
                        <thead>
                            <tr>
                                <th>
                                    <Input type="select" name="vessel"
                                        onChange={e => {
                                                const vessel = vessels[e.target.options.selectedIndex - 1];
                                                if (vessel) dispatch(vesselFilter(vessel));
                                                else dispatch(vesselFilter(false));
                                                dispatch(updateFilter());
                                        }} >
                                        <option>Vessel</option>
                                        {vessels.map(vessel => (<option key={vessel.id}>{vessel.get("name")}</option>))}
                                    </Input>
                                </th>
                                <th>
                                    <Input type="select" name="category"
                                        onChange={e => {
                                                if (e.target.options.selectedIndex) dispatch(categoryFilter(e.target.value));
                                                else dispatch(categoryFilter(false));
                                                dispatch(updateFilter());
                                        }} >
                                        <option>Category</option>
                                        {fuelCategories.map(category => (<option key={category}>{category}</option>))}
                                    </Input>
                                </th>
                                <th>Start date</th>
                                <th className="text-right">Price [US$/MT]</th>
                            </tr>
                        </thead>
                        <tbody>
                            {pricePoints.map(pricePoint => (
                                <tr key={pricePoint.id} onClick={() => dispatch(editFuelPrice(pricePoint))}>
                                    <td>{pricePoint.get("vessel") ? pricePoint.get("vessel").get("name") : 'Vessel'}</td>
                                    <td>{pricePoint.get("category")}</td>
                                    <td>{moment(pricePoint.get("from")).format('DD MMM YYYY HH:mm')}</td>
                                    <td className="text-right">{_.round(pricePoint.get("price"), 2)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </CardBody>
                <div className="row justify-content-md-center">
                    {getPagination(dispatch, count, limit, page)}
                </div>
            </Card>
        </div>
    </Container>);

export default connect(() => ({ fuelprices, vessels }) => ({
    pricePoints: fuelprices.pricePoints.sort((a, b) => moment.utc(b.get("from")).diff(moment.utc(a.get("from")))),
    count: fuelprices.count,
    limit: fuelprices.limit,
    page: fuelprices.page,
    showForm: fuelprices.fuelprices,
    fuelCategories: fuelprices.fuelCategories,
    vessels: vessels.vessels
}))(FuelPrices);
