import React, {Component} from "react";
import Parse from "parse";
import { connect } from 'react-redux';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { toggleForm, editFuelPrice, update, startRemove, startSave } from '../../../actions/fuelprices.js';
import moment from 'moment';

const FuelPriceForm = ({pricePoint, showForm, vessels, fuelCategories,  dispatch}) => (
  <div>
    <Modal isOpen={showForm} toggle={() => dispatch(toggleForm(false))}>
      <ModalHeader toggle={() => dispatch(toggleForm(false))}>Fuel price information: </ModalHeader>
      <ModalBody >
        <Form className="FuelPrice" onSubmit={e => { e.preventDefault(); dispatch(startSave(pricePoint)) }}>
          <FormGroup>
            <Label for="FuelPriceVessel">Vessel</Label>
            <Input type="select" name="vessel" id="FuelPriceVessel" value={pricePoint.get('vessel') && pricePoint.get('vessel').get('name')}
              onChange={e => {
                  const vessel = vessels[e.target.options.selectedIndex - 1];
                  if (vessel) {
                    dispatch(update('vessel', vessel));
                    dispatch(update('vesselId', vessel.get('pkId')));
                  }
              }} >
              <option>Select a vessel</option>
              {vessels.map(vessel => (<option key={vessel.id}>{vessel.get("name")}</option>))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="FuelPriceCategory">Fuel type</Label>
            <Input type="select" name="category" id="FuelPriceCategory" value={pricePoint.get('category')}
              onChange={e => {
                  dispatch(update('category', e.target.value));
              }}>
              <option>Select a fuel type</option>
              {fuelCategories.map(category => (<option key={category}>{category}</option>))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="FuelPricePrice">Fuel price [US$/MT]</Label>
            <Input type="number" name="price" id="FuelPricePrice" min="0.01" step="0.01" value={pricePoint.get('price')} onChange={e => {
                dispatch(update('price', Number(e.target.value)));
            }}/>
          </FormGroup>
          <FormGroup>
            <Label for="FuelPriceFromDate">Start day</Label>
            <Input type="date" name="from" id="FuelPriceFromDate" value={moment(pricePoint.get('from')).format('YYYY-MM-DD')} onChange={e => {
                const from = moment(pricePoint.get('from'));
                const date = moment(e.target.value, "YYYY-MM-DD");
                from.set('date', date.get('date'))
                    .set('month', date.get('month'))
                    .set('year', date.get('year'));
                dispatch(update('from', from.toDate()));
            }}/>
          </FormGroup>
          <FormGroup>
            <Label for="FuelPriceFromTime">Start time</Label>
            <Input type="time" name="from" id="FuelPriceFromTime" value={moment(pricePoint.get('from')).format('HH:mm')} onChange={e => {
                const time = moment(e.target.value, "HH:mm");
                const from = moment(pricePoint.get('from'));
                from.set('hour', time.get('hour'))
                    .set('minute', time.get('minute'));
                dispatch(update('from', from.toDate()));
            }}/>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={e => { e.preventDefault(); dispatch(startRemove(pricePoint)) }}>Delete</Button>
        <Button color="secondary" onClick={e => { e.preventDefault(); dispatch(toggleForm(false)) }}>Cancel</Button>
        {pricePoint.get('vessel') && (<Button  color="primary" onClick={e => { e.preventDefault(); dispatch(startSave(pricePoint)) }} >Save</Button>)}
      </ModalFooter>

    </Modal>
    <div className="text-right">
      <a className="addFuelType" onClick={() => dispatch(editFuelPrice())}>Add <strong> + </strong></a>
    </div>
  </div>

);

export default connect(() => ({ fuelprices, vessels }) => ({ showForm: fuelprices.showForm,
                                                             pricePoint: fuelprices.pricePoint,
                                                             fuelCategories: fuelprices.fuelCategories,
                                                             vessels: vessels.vessels}))(FuelPriceForm);
