import React from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, NavLink } from 'reactstrap';
import Spinner from 'react-spinkit';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import {
    AppSidebar,
    AppSidebarNav
} from '@coreui/react';

import { toggleVessel, toggleShowAllSidebar } from '../../../actions/navigation.js';

const isOpen = (routeName, location) => {
    return (location.pathname.indexOf(routeName) !== -1);
}

const dashboard = () => (
<ul className="nav" vertical="true">
<Link className={"nav-link vessel"} to="/">
    Dashboard
</Link>
</ul>);

const bunkerSearch = () => (
<ul className="nav" vertical="true">
    <Link className={"nav-link vessel"} to="/bunkersearch">
        {/* <a className="nav-link" href="/bunkersearch"> */}
        {/* <i className="fa fa-search"></i> */}
        Bunkerings
        {/* </a> */}
    </Link>
</ul>
);

const navDropDown = (groupedVessels, dispatch, groupName) => {
    return groupedVessels.length ? (
    <UncontrolledDropdown>
        <DropdownToggle
            color="primary"
            caret={true}
            className="groups">{groupName || 'Groups'}</DropdownToggle>
        <DropdownMenu
            className="groups">
            <DropdownItem header>
                Select group
            </DropdownItem>
            {groupedVessels.map(level1 => (
                <DropdownItem
                    onClick={() => dispatch(toggleShowAllSidebar(false))}
                    className="groupItems"
                    key={level1.group._getId()}>
                    <Link
                        to={'/group/' + level1.group.get('name') + '/'}>
                        <i className="fa fa-object-group"></i>
                        {level1.group.get('name')}
                    </Link>
                </DropdownItem>
            ))}
        </DropdownMenu>
    </UncontrolledDropdown>) : null;
}

const groupHeader = (groupedVessels, dispatch, location, groupName) => (
<div key={groupName}>
<span className="navbar-text">
    {groupName}
</span>
    {groupedSideBar(groupedVessels, dispatch, location, groupName)}
</div>
);

const groupedSideBar = (groupedVessels, dispatch, location, groupName) => {
    return groupedVessels.filter(level1 => (level1.group.get('name') === groupName)).map(level1 => level1.items.map(level2 => ((
        <ul
            className="nav"
            vertical="true"
            key={level2.item._getId()}>
            {Boolean(level2.vessels.length) &&
             (<li className={isOpen(level1.group.get('name') + '/' + level2.item.get('name'), location)? "open nav-dropdown":"nav-dropdown" }>
                 <Link
                     className="nav-link nav-dropdown-toggle"
                     to={'/group/' + level1.group.get('name') + '/' + level2.item.get('name') + "/" }>
                     {level2.item.get('name')} ({level2.vessels.length})
                 </Link>

                 <ul className="nav-dropdown-items open-group">
                     {level2.vessels.map( vessel => (
                         <li key={vessel._getId()} className="nav-item">
                             <Link
                                 key={vessel._getId()+groupName}
                                 className={"nav-link vessel"}
                                 to={
                                     '/group/'
                                     + level1.group.get('name')
                                     + '/' + level2.item.get('name')
                                     + '/vessels/' + vessel.get('pkId')} >
                                 <i
                                     className={isOpen(vessel.get('pkId'), location) ? 'fa fa-check-square' : 'fa fa-square'}
                                     aria-hidden="true"
                                     onClick={
                                         e => {
                                             e.stopPropagation();
                                             e.preventDefault();
                                             dispatch(toggleVessel(vessel.get('pkId')));
                                         }
                                     }></i>
                                 {vessel.get('name')}
                             </Link>
                         </li>
                     ))}
                 </ul>
             </li>)}
        </ul>))))

}

const allVessels = (vessels, dispatch, showAll, location) => {
    return (<ul
                className="nav"
                vertical="true">
        <li className={showAll? "open nav-dropdown" : "nav-dropdown allVesselsButton" }>
            <NavLink
                onClick={() => dispatch(toggleShowAllSidebar(!showAll))}
                className="nav-dropdown-toggle"
                to="/">
                All vessels ({vessels.length})
            </NavLink>

            <ul className="nav-dropdown-items open-group allVessels">
                {vessels.map( vessel => (
                    <li key={vessel._getId()} className="nav-item">
                        <Link
                            key={vessel._getId()}
                            className={"nav-link vessel"}
                            to={'/vessels/' + vessel.get('pkId')} >
                            <i
                                className={isOpen(vessel.get('pkId'), location) ? 'fa fa-check-square' : 'fa fa-square'}
                                aria-hidden="true"
                                onClick={
                                    e => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        dispatch(toggleVessel(vessel.get('pkId')));
                                    }
                                }></i>
                            {vessel.get('name')}
                        </Link>
                    </li>
                ))}
            </ul>
        </li>
    </ul>);
}



const Sidebar = ({location, showAll, groupName, groupedVessels, vessels, user, dispatch, loading}) => user && (
    <AppSidebar fixed display="xl">
    {!loading ?
        <AppSidebarNav>
            {dashboard()}
            {bunkerSearch()}
            {/* <span className="navbar-text">
            <i className="fa fa-ship"></i>
            Vessels
            </span> */}
            {allVessels(vessels, dispatch, showAll, location)}
            {groupedVessels.map((groups) => groupHeader(groupedVessels, dispatch, location, groups.group.get('name')))}
            {/* </UncontrolledDropdown> */}
        </AppSidebarNav>
        : 
        <AppSidebarNav>
        {dashboard()}
        {bunkerSearch()}
        <div className="vCenter">
            <div className="centerLoader">
                <p>
                    Loading the fleet data
                </p>
                <Spinner color="#FFFFFF" name='folding-cube' />
            </div>
        </div>
        </AppSidebarNav>
    }</AppSidebar>
);

export default connect(() => ({ routing, accounts, vesselGroups, vessels, nav }) =>
    ({
        showAll: nav.showAll,
        vessels: vessels.vessels,
        loading: vesselGroups.loading,
        groupedVessels: vesselGroups.groupedVessels,
        location: routing.location || {},
        groupName: nav.isGroup ?_.first(nav.group) : 'Groups' ,
        user: accounts.user
    }))(Sidebar);
