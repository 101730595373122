// jshint esversion:6

import Parse from '../parse';
import _ from 'lodash';
import {
  isNullOrUndefined
} from 'util';

const BunkerItem = Parse.Object.extend("BunkerItem");
const BunkerMeasurement = Parse.Object.extend("BunkerMeasurement");
const bunkerCache = {};
const bunkerCountCache = {};

export const loading = () => {
  return {
    type: 'BUNKERITEMS_LOADING'
  };
};

export const loaded = (bunkerItems) => {
  return {
    type: 'BUNKERITEMS_LOADED',
    bunkerItems
  };
};

export const loadedBunker = (bunker) => {
  return {
    type: 'BUNKER_LOADED',
    bunker
  };
};

export const loadedBunkerMeasurements = (bunkerMeasurements) => {
  return {
    type: 'BUNKERMEASUREMENTS_LOADED',
    bunkerMeasurements
  };
};

export const loadedBunkerCount = (count) => {
  return {
    type: 'BUNKER_COUNT',
    count
  };
};

export const setPage = (page) => {
  return {
    type: 'BUNKER_PAGE',
    page
  };
};

export const loadPage = (page) => (dispatch) => {
  dispatch(setPage(page));
  dispatch(loadBunkerItems());
};

export const loadBunkerCount = () => (dispatch, getState) => {
  const {
    vessels
  } = getState();
  const activeVessels = vessels.activeVessels;

  const key = _.join(activeVessels.map(v => v.id), "_");
  if (bunkerCountCache[key]) dispatch(loadedBunkerCount(bunkerCountCache[key]));
  else {
    const query = new Parse.Query(BunkerItem);
    query.containedIn('vessel', activeVessels);
    query.notEqualTo("deleted", true);
    query.count().then(count => {
      bunkerCountCache[key] = count;
      dispatch(loadedBunkerCount(count));
    });
  }
};

const makeBunkerQuery = (vessels, bunkerItems, sort = "startTime", direction = "desc") => {
  const query = new Parse.Query(BunkerItem);
  query.containedIn('vessel', vessels);
  (direction === "asc") ? query.ascending(sort): query.descending(sort);
  query.notEqualTo("deleted", true);
  query.limit(bunkerItems.limit);
  query.skip(bunkerItems.limit * bunkerItems.page);
  return query;
};

export const loadBunkerItems = (path) => (dispatch, getState) => {
  const {
    vessels
  } = getState();
  const activeVessels = vessels.activeVessels;
  if (!activeVessels) return null;

  const {
    bunkerItems
  } = getState();
  var query;
  if (!isNullOrUndefined(path) && path.includes('/bunker/')) {
      const bunkerId = path.split("/")[4];
      query = new Parse.Query(BunkerItem);
      query.containedIn('vessel', activeVessels);
      query.equalTo('pkId', bunkerId);
  } else {
    query = makeBunkerQuery(activeVessels, bunkerItems);
  }
  query.find()
    .then(basicBunkerItems => {
      Parse.Object.fetchAllIfNeeded(basicBunkerItems).then(bunkerItems => {
        Promise.all(bunkerItems.map(bunkerItem => Parse.Object.fetchAllIfNeeded(bunkerItem.get('fuelType')))).then(() => {
          // bunkerCache[key] = bunkerItems;
          dispatch(loaded(bunkerItems));
          dispatch(loadBunker(path));
        });
      });
    });
};

export const loadBunker = (path) => (dispatch, getState) => {
  const {
    routing,
    bunkerItems
  } = getState();
  const fullPath = path || routing.location.pathname || '';
  const active = bunkerItems.bunkerItems.find(b => fullPath.indexOf(b.get('pkId')) !== -1);
  if (active) {
    dispatch(loadedBunker(active));
    var query = new Parse.Query(BunkerMeasurement);
    query.equalTo('bunkerItemId', active.get('pkId'));
    query.equalTo('vesselId', active.get('vesselId'));
    query.find()
      .then(bunkerMeasurements => dispatch(loadedBunkerMeasurements(bunkerMeasurements)));
  };
};