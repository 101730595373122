// jshint esversion:6

import nav from "./_nav";
import _ from "lodash";

const defaultState = {
  showAll: false,
  group: ["all"],
  isGroup: false,
};

const isGroup = (path = "") => {
  return path === "/" || path === "" || path.indexOf("group") === 1;
};

const groupPathToGroupArray = (path = "") => {
  const pathLessGroup = path.replace("/group", "");
  if (pathLessGroup === "/" || pathLessGroup === "") return [];
  return _.compact(pathLessGroup.split("/"));
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case "@@router/LOCATION_CHANGE":
      return {
        ...state,
        isGroup: isGroup(action.payload.pathname),
        path: action.payload.pathname,
        group: groupPathToGroupArray(action.payload.pathname),
        showAll:
          state.showAll ||
          !isGroup(action.payload.pathname) ||
          action.payload.pathname === "/" ||
          action.payload.pathname === "",
      };
    case "SIDEBAR_SHOWALL":
      return { ...state, showAll: action.show };
    default:
      return state;
  }
};
