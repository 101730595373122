import React, {Component} from "react";
import {Form, Container, Row, Col, Card, CardBody, CardFooter, Button, Input, InputGroup, InputGroupAddon} from "reactstrap";
import { connect } from 'react-redux';

import { saveProfile, updateProfile } from '../../../actions/account';

const Profile = ({ user, email, username, updated, dispatch }) => (
  <div className="app flex-row align-items-center">
    <Container>
      <Row className="justify-content-center">
        <Col md="6">
          <Card className="mx-4">
            <CardBody className="card-body p-4">
              <h1>{user.get('username')}</h1>
              <p className="text-muted">Update your profile</p>
              <Form onSubmit={e => {
                  e.preventDefault();
                  const passwords = [...e.target.getElementsByClassName("password")];
                  const eq = passwords.length === 2 && passwords[0].value === passwords[1].value;
                  if (passwords[0].value.length  === 0 || (eq && passwords[0].value)) dispatch(saveProfile());
              }}>
                <InputGroup className="mb-3">
                  <InputGroupAddon addonType="prepend">
                    <i className="icon-user input-group-text"></i>
                  </InputGroupAddon>
                  <Input type="text" onChange={e => dispatch(updateProfile('username', e.target.value))} placeholder="Username" value={username} />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroupAddon addonType="prepend">@</InputGroupAddon>
                  <Input type="text" onChange={e => dispatch(updateProfile('email', e.target.value))}  placeholder="Email" value={email} />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroupAddon addonType="prepend">
                    <i className="icon-lock input-group-text"></i>
                  </InputGroupAddon>
                  <Input className="password" type="password" placeholder="Password" onChange={e => dispatch(updateProfile('password', e.target.value))} />
                </InputGroup>
                <InputGroup className="mb-4">
                  <InputGroupAddon addonType="prepend">
                    <i className="icon-lock input-group-text"></i>
                  </InputGroupAddon>
                  <Input className="password" type="password" placeholder="Repeat password" onChange={e => dispatch(updateProfile('password', e.target.value))} />
                </InputGroup>
                <Button color={ updated ? "success" : "secondary" } block>Save</Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  </div>
);

export default connect((state) => ({ user: state.accounts.user,
                                     saving: state.accounts.saving,
                                     updated: state.accounts.updated,
                                     email: state.accounts.user.get('email'),
                                     username: state.accounts.user.get('username') }))(Profile);
