
import React, {Component} from "react";
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { getArrLast } from './utility.js';
import { round, NM, horizontalBar } from '../../../config/sparkLineChartOptions.js';
import { getLast} from '../../../measurements/FirstLast'
import _ from 'lodash';
import { HorizontalBar } from "react-chartjs-2";

import {
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
} from "reactstrap";

const getData = (vessels, consumersMeasurements, type) => {
  if (consumersMeasurements.TotalFuel){
    return vessels.reduce((att, vessel) => {
      const m = consumersMeasurements.TotalFuel.find( measurement => measurement.get('vessel').id === vessel.id
                                                                && measurement.get('consumer')
                                                                && measurement.get('consumer').get('type') === type);
      att[vessel.get('name')] = (m) ? getLast(m.get('values')) : 0;
      return att;
    }, {})
  }
};

const FuelConsumption = ({ fleet, data}) => (
  <Card>
    <CardBody className="card-body">
      <p>Fleet fuel consumption [MT]<br />
        Total: { round(fleet.totalConsumption) }</p>
      <HorizontalBar data={data} options={horizontalBar}  />
    </CardBody>
  </Card>
);

export default connect((state, dispatch) => {
  const vessels = state.fleet.fleet.vessels || [];
  const labels = (vessels || []).map( f => f.vesselName);

  const data = {
    labels,
    datasets: [
      {
        label: 'Main Engine',
        backgroundColor: '#005DA8',
        borderColor: '#005DA8',
        borderWidth: 1,
        data: vessels.map(v => v.mainEngineConsumption)
      },
      {
        label: 'Auxiliary',
        backgroundColor: '#E94D19',
        borderColor: '#005DA8',
        borderWidth: 1,
        data: vessels.map(v => v.auxConsumption)
      }
    ]
  }
  return ({ fleet: state.fleet.fleet,
            dispatch,
            data});

})(FuelConsumption);
