import React from "react";
import { connect } from 'react-redux';
import { Line } from "react-chartjs-2";
import moment from 'moment';
import _ from 'lodash';
import humanizeDuration from 'humanize-duration';
import { push } from 'react-router-redux';

import {
    ListGroup,
    ListGroupItem,
    Badge,
    Row,
    Col
} from "reactstrap";

const getNotification = (log) => {
    const data = log.get('data');
    const ago = moment(log.get('timestamp')).format('Do MMMM YYYY');
    if (log.get('type') === 'Datagap') {
        const time = humanizeDuration(data.duration);
        const gaps = data.numGaps > 1 ? 'gaps' : 'gap';
        const was = data.numGaps > 1 ? 'were' : 'was';
        const gap = data.numGaps > 1 ? data.numGaps : 'A';
        return ( <span><strong className="date">{ago}</strong> <span>{`${gap} data ${gaps} of ${time} ${was} detected`}</span></span>);
    } else if (log.get('type') === 'BunkerMissing') {
        const meter = data.bunkerMeter || data.flowMeterName;
        return (<span><strong className="date">{ago}</strong> <span>{`${meter} reported an unrecorded change in mass of ${_.round(data.totalMass / 1000)} MT`}</span></span>);
    }

    return log.get('type');
}

const Logs = ({logs, limit, link, page, vessels, dispatch}) => (
    <ListGroup className="errorLogPage">
        <Row>
            {vessels && logs.map((log, i) => (
                <Col key={i} xs="12" sm="6" >
                    <ListGroupItem  key={i + (page * limit) + 1}>
                        {link && (<Badge onClick={ () => dispatch(push('./vessels/' + log.get('vesselId') )) } color="light">
                            <span className="ref" style={{backgroundColor: vessels[log.get('vesselId')].get("colour")}}></span>
                            {vessels[log.get('vesselId')].get("name") + ": "}</Badge>)}
                        <span className="logs" onClick={() => link ? dispatch(push('./vessels/' + log.get('vesselId') + '/logs')) : null}>
                            {getNotification(log)}
                        </span>
                    </ListGroupItem>
                </Col>))}
        </Row>
    </ListGroup>);

export default connect(() => ({ logs, vessels}) => ({
    vessels: (vessels.vessels.length) ? vessels.vessels.reduce( (all, v) => {
        all[v.get('pkId')] = v
        return all;
    }, {}) : [],
    logs: logs.items,
    count: logs.count,
    limit: logs.limit,
    page: logs.page
}))(Logs);
