// jshint esversion:6

const defaultState = {  voyages: [], page: 0, limit: 10, showDetails: false, voyageDetails: {}, voyageConsumerDetails: -1,  voyage: { get: () => null }};

export default (state = defaultState, action = {}) => {
    switch (action.type) {
    case 'ACCOUNT_LOGOUT':
        return {...defaultState };
    case 'VOYAGES_LOADING':
        return {...defaultState, loading: true };
    case 'VOYAGES_LOADED':
        return { ...state, loading: false, voyages: action.voyages };
    case 'VOYAGE_LOADED':
        return { ...state, voyage: action.voyage };
    case 'VOYAGE_COUNT':
        return { ...state, count: action.count };
    case 'VOYAGE_PAGE':
        return { ...state, page: action.page };
    case 'VOYAGE_SHOWVOYAGEDETAILS':
        return { ...state, showDetails: action.show };
    case 'VOYAGE_DETAILS':
        return { ...state, voyageDetails: action.details, voyageConsumerDetails: -1 };
    case 'VOYAGE_CONSUMER_DETAILS':
        return { ...state, voyageConsumerDetails: action.id };
    default:
        return state;
    }
};
