// jshint esversion:6

import Parse from '../parse';
import _ from 'lodash';

const FuelPrice = Parse.Object.extend("FuelPrice");


const fuelpricesCache = {};
const fuelpricesCountCache = {};

export const loading = () => {
  return {
    type: 'FUELPRICES_LOADING'
  };
};

export const loaded = (pricePoints) => {
  return {
    type: 'FUELPRICES_LOADED',
    pricePoints
  };
};

export const loadedFuelPricesCount = (count) => {
  return {
    type: 'FUELPRICES_COUNT',
    count
  };
};

export const setPage = (page) => {
  return {
    type: 'FUELPRICES_PAGE',
    page
  };
};

export const toggleForm = (showForm) => {
  return {
    type: 'FUELPRICES_SHOWFORM',
    showForm
  };
};

export const remove = () => {
  return {
    type: 'FUELPRICES_REMOVE'
  };
};

export const edit = (pricePoint) => {
  return {
    type: 'FUELPRICES_EDIT',
    pricePoint
  };
};

export const save = (pricePoint) => {
  return {
    type: 'FUELPRICES_SAVE',
    pricePoint
  };
};

export const update = (key, value) => {
  return {
    type: 'FUELPRICES_UPDATE',
    key,
    value
  };
};

export const vesselFilter = (vessel) => {
  return {
    type: 'FUELPRICES_FILTER_VESSEL',
    vessel
  };
};

export const categoryFilter = (category) => {
  return {
    type: 'FUELPRICES_FILTER_CATEGORY',
    category
  };
};

export const startSave = (pricePoint) => (dispatch) => {

  if (pricePoint.get('vesselId')
      && pricePoint.get('vessel')) {
    const pp = pricePoint.clone();
    pp.save({vessel: pricePoint.get('vessel').toPointer()}).then(() => dispatch(save(pricePoint)));
  }
};

export const startRemove = (pricePoint) => (dispatch) => {
  pricePoint.destroy().then(() => dispatch(remove()));
};

export const editFuelPrice = (pricePoint) => (dispatch) => {
  pricePoint = pricePoint || new FuelPrice();
  dispatch(edit(pricePoint));
};

export const updateFilter = () => (dispatch) => {
  dispatch(loadFuelPricesCount());
  dispatch(loadFuelPrices());
}

export const loadFuelPricesPage = (page, path) => (dispatch) => {
  dispatch(setPage(page));
  dispatch(loadFuelPrices(path));
};

export const loadFuelPricesCount = () => (dispatch, getState) => {
  const { vessels, fuelprices } = getState();
  const loadedVessels = vessels.vessels;

  const key = _.join(loadedVessels.map(v => v.id), "_")
            + (fuelprices.categoryFilter)
            + (fuelprices.vesselFilter ? fuelprices.vesselFilter.get('pkId') : "");
  if (fuelpricesCountCache[key]) dispatch(loadedFuelPricesCount(fuelpricesCountCache[key]));
  else {
    const query = new Parse.Query(FuelPrice);
    query.containedIn('vesselId', loadedVessels.map(v => v.get('pkId')));

    if (fuelprices.vesselFilter) query.equalTo('vessel', fuelprices.vesselFilter);
    if (fuelprices.categoryFilter) query.equalTo('category', fuelprices.categoryFilter);

    query.count().then(count => {
      fuelpricesCountCache[key] = count;
      dispatch(loadedFuelPricesCount(count));
    });
  }
};

const makeFuelPricesQuery = (vessels, fuelprices) => {
  const query = new Parse.Query(FuelPrice);
  query.containedIn('vesselId', vessels.map(v => v.get('pkId')));
  query.descending("from");
  query.limit(fuelprices.limit);

  if (fuelprices.vesselFilter) query.equalTo('vessel', fuelprices.vesselFilter);
  if (fuelprices.categoryFilter) query.equalTo('category', fuelprices.categoryFilter);

  query.skip(fuelprices.limit * fuelprices.page);
  return query;
};

export const loadFuelPrices = (path) => (dispatch, getState) => {
  const { vessels, routing, fuelprices } = getState();
  const fullPath = path || routing.location.pathname || '';
  const loadedVessels = vessels.vessels;
  if (fullPath.indexOf('fuelprices') === -1 || !loadedVessels) return null;
  const key = _.join(loadedVessels.map(v => v.id), "_") + fuelprices.limit + (fuelprices.limit * fuelprices.page)
            + (fuelprices.categoryFilter)
            + (fuelprices.vesselFilter ? fuelprices.vesselFilter.get('pkId') : "");

  if (fuelpricesCache[key]) {
    dispatch(loaded(fuelpricesCache[key]));
  } else {
    makeFuelPricesQuery(loadedVessels, fuelprices)
      .find()
      .then(basicFuelPricesItems => {
        Parse.Object.fetchAllIfNeeded(basicFuelPricesItems).then(fuelprices => {
          fuelpricesCache[key] = fuelprices;
          dispatch(loaded(fuelprices));
        });
      });
  }
};
