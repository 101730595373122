import React from "react";
import { connect } from 'react-redux';
import {Bar, Line} from "react-chartjs-2";
import moment from 'moment';

import humanizeDuration from 'humanize-duration';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import ReactGA from 'react-ga';

import {
    Row,
    Col,
    Card,
    Badge,
    CardHeader,
    CardBody,
    CardTitle,
    CardFooter,
    Container,
    ButtonGroup,
    Button,
    CardDeck,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap";

import FuelConsumptionLineCard from './FuelConsumptionLineCard.js';
import FuelConsumptionBarCard from './FuelConsumptionBarCard.js';
import TotalDistanceLine from './TotalDistanceLine.js';
import Performance from './Performance.js';

import BunkeringsCard from './BunkeringsCard.js';
import VoyagesCard from './VoyagesCard.js';
import MapCard from '../../Components/Map/MapCard.js';
import VesselDetails from './VesselDetails.js';
import SetAllVesselDays from './SetAllVesselDays.js';
import VoyageDetails from '../../Components/VoyageDetails/VoyageDetails.js';
import { setTab } from '../../../actions/vessels.js';

const Vessel = ({ vessels, bounds, tab, dispatch }) =>
    (vessels.length ? (
        <Container fluid>
            <Row key="deck" className="VesselPage page">
                <Col sm="12" md="6">
                    <MapCard key={_.join(vessels.map(v => v.get('pkId')), "-")}
                        history={vessels.map(vessel => ({vessel, history: vessel.get('history') || [] }))}
                        bounds={bounds}></MapCard>
                </Col>
                <Col sm="12" md="6">
                    <VoyageDetails></VoyageDetails>
                    <Nav tabs>
                        <NavItem>
                            <NavLink className={tab === 1 ? "active" : ""} onClick={() => {
                                ReactGA.event({
                                    category:'Navigation',
                                    action: 'Select Overview tab'
                                });
                                dispatch(setTab(1))
                                }} >
                                Overview
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={tab === 2 ? "active" : ""} onClick={() => {
                                ReactGA.event({
                                    category:'Navigation',
                                    action: 'Select Consumption tab'
                                });
                                dispatch(setTab(2));
                                }} >
                                Consumption
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={tab === 3 ? "active" : ""} onClick={() => {
                                ReactGA.event({
                                    category:'Navigation',
                                    action: 'Select Performance tab'
                                });
                                dispatch(setTab(3));
                                }} >
                                Performance
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={tab === 4 ? "active" : ""} onClick={() => {
                                ReactGA.event({
                                    category:'Navigation',
                                    action: 'Select Voyages tab'
                                });
                                dispatch(setTab(4));
                                }} >
                                Voyages
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={tab === 5 ? "active" : ""} onClick={() => {
                                ReactGA.event({
                                    category:'Navigation',
                                    action: 'Select Bunkers tab'
                                });
                                dispatch(setTab(5));
                                }} >
                                Bunkers
                            </NavLink>
                        </NavItem>
                    </Nav>

                    <TabContent activeTab={tab}>
                        <TabPane tabId={1}>
                            <Row>
                                <Col xs="12" className="text-right">
                                    <SetAllVesselDays></SetAllVesselDays>
                                </Col>
                            </Row>
                            {vessels.map((vessel, i) => (
                                <VesselDetails  key={vessel.get('pkId')} vessel={vessel} ></VesselDetails>
                            ))}
                        </TabPane>
                        <TabPane tabId={2}>
                            <Row>
                                <Col sm="12">

                                    <CardHeader>
                                        Fuel consumption [kg/hour]
                                    </CardHeader>
                                    <CardBody className="card-body">
                                        <FuelConsumptionLineCard />
                                    </CardBody>

                                </Col>
                                <Col sm="12">
                                    <CardHeader>
                                        Fuel consumption [MT/day]
                                    </CardHeader>
                                    <CardBody className="card-body">
                                        <FuelConsumptionBarCard />
                                    </CardBody>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId={3}>
                            <Row>
                                <Col sm="12">
                                    <CardHeader>
                                        Performance [kg/nmi]
                                    </CardHeader>
                                    <CardBody className="card-body">
                                        <Performance />
                                    </CardBody>
                                </Col>
                                <Col sm="12">

                                    <CardHeader>
                                        Speed [kn]
                                    </CardHeader>
                                    <CardBody className="card-body">
                                        <TotalDistanceLine />
                                    </CardBody>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId={4}>
                            <VoyagesCard />
                        </TabPane>
                        <TabPane tabId={5}>
                            <BunkeringsCard />
                        </TabPane>
                    </TabContent>
                </Col>
            </Row>
        </Container>) : null)

export default connect(() => ({ vessels }) => {
    return ({ vessels: vessels.activeVessels,
              tab : vessels.tab,
              bounds: vessels.bounds });
})(Vessel);
